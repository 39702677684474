
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Order, OrderStatus } from '@/types';
import OrdersStatusesItem from './OrdersStatusesItem.vue';

@Component({
  components: {
    OrdersStatusesItem,
  },
})
export default class OrdersStatuses extends Vue {
  @Prop({ type: Object, required: true }) readonly order!: Order;
  @Prop({ type: Array, required: false }) readonly statuses!: OrderStatus[];
  @Prop({ type: Array, required: false }) readonly vouchers!: Array<string>;
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;
  @Prop({ type: Function, required: true }) readonly onChangeStatus!: (
    isChecked: boolean,
    status: OrderStatus,
  ) => void;
}
