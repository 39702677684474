<template>
  <BOverlay :show="isLoading" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <BDropdown size="sm" right data-test="posts-actions" variant="primary">
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <BDropdownItem :to="{ name: 'posts.add' }">
            Προσθήκη άρθρου
          </BDropdownItem>

          <BDropdownDivider />

          <BDropdownItemButton class="delete-btn" @click="handleDelete">
            Διαγραφή άρθρου
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            data-test="form"
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
          >
            <BCardBody>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Τίτλος *">
                  <BFormInput
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Εισάγετε τον τίτλο του άρθρου"
                    data-test="title"
                    autofocus
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε τον τίτλο του άρθρου
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="slug"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Slug *">
                  <BInputGroup :prepend="`${eshopURL}/blog/`">
                    <BFormInput
                      id="slug"
                      v-model="form.slug"
                      type="text"
                      placeholder="Εισάγετε το slug του άρθρου"
                      data-test="slug"
                    />
                    <BInputGroupAppend>
                      <BButton
                        variant="primary"
                        target="_blank"
                        :href="`${eshopURL}/blog/${model.slug}`"
                      >
                        <BIcon icon="chevron-right" />
                      </BButton>
                    </BInputGroupAppend>
                  </BInputGroup>

                  <BFormText
                    text-variant="danger"
                    data-test="slug-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το slug του άρθρου
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                title="content"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Περιεχόμενο *">
                  <ckeditor
                    :editor="editor"
                    v-model="form.content"
                    data-test="content"
                    :config="editorConfig"
                    tag-name="textarea"
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε περιεχόμενο για το άρθρο
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider title="active" rules="required">
                <BFormGroup label="Ενεργό *">
                  <BFormCheckbox v-model="form.active" switch />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="author_id">
                <BFormGroup label="Συγγραφέας">
                  <BFormSelect
                    :options="authors"
                    :disabled="!hasAvailableAuthors"
                    text-field="name"
                    value-field="id"
                    v-model="form.author_id"
                  >
                    <template v-slot:first>
                      <BFormSelectOption :value="null" disabled>
                        -- Επιλέξτε συγγραφέα --
                      </BFormSelectOption>
                    </template>
                  </BFormSelect>

                  <BFormText v-if="!hasAvailableAuthors">
                    Δημιουργήστε πρώτα τουλάχιστον έναν συγγραφέα
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="postCategories">
                <BFormGroup label="Κατηγορίες Άρθρου" class="mb-0">
                  <BFormTags
                    v-model="form.postCategories"
                    add-on-change
                    no-outer-focus
                  >
                    <template
                      v-slot="{
                        tags,
                        inputAttrs,
                        inputHandlers,
                        disabled,
                        removeTag,
                      }"
                    >
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-2"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <BFormTag
                            @remove="removeTag(tag)"
                            :title="tag"
                            :disabled="disabled"
                            variant="info"
                          >
                            {{ tag }}
                          </BFormTag>
                        </li>
                      </ul>
                      <BFormSelect
                        multiple
                        v-model="form.postCategories"
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        :disabled="disabled || !hasAvailablePostCategories"
                        :options="availablePostCategories"
                      >
                        <template v-slot:first>
                          <BFormSelectOption value="" disabled>
                            -- Επιλέξτε κατηγορίες --
                          </BFormSelectOption>
                        </template>
                      </BFormSelect>
                    </template>
                  </BFormTags>

                  <BFormText v-if="!hasPostCategories">
                    Δημιουργήστε πρώτα τουλάχιστον μία κατηγορία
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';
import slug from 'slug';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/PostsEditLayout';
import PostsEditMethodsMixin from './mixins/PostsEditMethodsMixin';

export default {
  components: {
    BaseButtonSubmit,
    Layout,
  },

  mixins: [EditorMixin, PostsEditMethodsMixin],

  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
    model: {},
    form: {
      title: '',
      slug: '',
      active: false,
      author_id: null,
      postCategories: [],
    },
    authors: [],
    postCategories: [],
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Άρθρα',
        to: { name: 'posts' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
  }),

  watch: {
    'form.title': {
      handler(value) {
        this.form = {
          ...this.form,
          slug: slug(value),
        };
      },
    },
  },

  filters: {
    trimmedTitle(title = '') {
      return title.length > 50 ? `${title.substring(0, 50)}...` : title;
    },
  },

  computed: {
    availablePostCategories() {
      return this.postCategories
        .filter(option => !this.form.postCategories.includes(option))
        .map(option => option.title);
    },

    hasAvailablePostCategories() {
      return this.availablePostCategories.length > 0;
    },

    hasPostCategories() {
      return this.postCategories.length > 0;
    },

    hasAvailableAuthors() {
      return this.authors.length > 0;
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const response = await Promise.all([
        request.get(`/posts/${this.$route.params.id}`),
        request.get('/authors', {
          params: {
            paginated: false,
          },
        }),
        request.get('/post-categories', {
          params: {
            paginated: false,
          },
        }),
      ]);

      this.model = response[0].data.post;
      this.authors = response[1].data.authors;
      this.postCategories = response[2].data.postCategories;

      this.initForm();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      console.log(err);
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το άρθρο',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    initForm() {
      this.form = {
        ...pick(this.model, ['title', 'slug', 'active', 'author_id']),
        postCategories: this.getFormPostCategories(),
        content: this.model.content || '',
      };
    },
    async handleForm() {
      try {
        this.isSaving = true;

        const data = {
          ...this.form,
          postCategories: this.form.postCategories.map(
            category =>
              this.postCategories.find(cat => cat.title === category)?.id,
          ),
        };
        await request.put(`/posts/${this.$route.params.id}`, data);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του άρθρου ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του άρθρου δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.initForm();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    getFormPostCategories() {
      return (this.model.post_categories || []).map(category => category.title);
    },
  },
};
</script>
