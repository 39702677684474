<template>
  <BModal
    id="modal-edit-photo"
    title="Επεξεργασία φωτογραφίας"
    centered
    hide-footer
    no-stacking
  >
    <template #default>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm @submit.prevent="handleSubmit(handleEdit)" novalidate>
          <ValidationProvider
            v-slot="{ errors, failedRules }"
            name="slug"
            :rules="{ required: true, regex: /^[a-z0-9]+(?:-[a-z0-9]+)*$/ }"
          >
            <BFormGroup label="Slug *">
              <BInputGroup :append="suffix">
                <BFormInput
                  id="title"
                  v-model="newSlug"
                  type="text"
                  placeholder="Εισάγετε slug"
                  autofocus
                />
              </BInputGroup>

              <BFormText
                text-variant="danger"
                v-if="failedRules.required || failedRules.regex"
              >
                Εισάγετε έγκυρο slug
              </BFormText>

              <BFormText text-variant="danger" v-else-if="errors">
                {{ errors[0] }}
              </BFormText>
            </BFormGroup>
          </ValidationProvider>
          <div class="d-flex justify-content-end align-items-center">
            <BButton
              variant="primary"
              type="submit"
              class="mr-2"
              :disabled="isSaving"
            >
              Αποθήκευση
              <BSpinner v-if="isSaving" small />
            </BButton>
            <BButton :disabled="isSaving" @click="handleReset"
              >Επαναφορά</BButton
            >
          </div>
        </BForm>
      </ValidationObserver>
    </template>
  </BModal>
</template>

<script>
import { isEmpty, last, split } from 'lodash';

export default {
  props: {
    photo: {
      type: Object,
      required: true,
    },

    isSaving: {
      type: Boolean,
      required: true,
    },

    editErrors: {
      type: Array,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      slug: '',
      newSlug: '',
      suffix: '',
    };
  },

  watch: {
    photo: {
      handler(newVal) {
        const slug = last(split(newVal.unique_id, '/'));
        this.suffix = last(split(slug, '-'));

        this.slug = split(slug, `-${this.suffix}`)[0];

        this.newSlug = this.slug;
      },
      immediate: true,
    },

    editErrors: {
      handler(newVal) {
        if (!isEmpty(newVal)) {
          this.$refs.form.setErrors(newVal);
        }
      },
    },
  },

  methods: {
    handleEdit() {
      const newPhoto = {
        ...this.photo,
        unique_id: `${this.newSlug}-${this.suffix}`,
      };

      this.$emit('on-edit', newPhoto);
    },

    handleReset() {
      this.newSlug = this.slug;
    },
  },
};
</script>
