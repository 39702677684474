import Vue from 'vue';
import Qs from 'qs';
import VueRouter from 'vue-router';
import store from '@/store';

import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Home from '@/views/Dashboard/views/Home.vue';
import Products from '@/views/Dashboard/views/Products/index.vue';
import ProductsAll from '@/views/Dashboard/views/Products/ProductsAll.vue';
import ProductsActive from '@/views/Dashboard/views/Products/ProductsActive.vue';
import ProductsInactive from '@/views/Dashboard/views/Products/ProductsInactive.vue';
import ProductsDeleted from '@/views/Dashboard/views/Products/ProductsDeleted.vue';
import ProductsEdit from '@/views/Dashboard/views/Products/ProductsEdit/index.vue';
import ProductsEditGeneral from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditGeneral.vue';
import ProductsEditPhotos from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditPhotos.vue';
import ProductsEditFeeds from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditFeeds.vue';
import ProductsEditPrices from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditPrices.vue';
import ProductsEditSEO from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditSEO.vue';
import ProductsEditReviews from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditReviews.vue';
import ProductsEditBarcodes from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditBarcodes.vue';
import ProductsEditCategories from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditCategories.vue';
import ProductsEditFilters from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditFilters.vue';
import ProductsEditEnglish from '@/views/Dashboard/views/Products/ProductsEdit/ProductsEditEnglish.vue';
import ProductsAdd from '@/views/Dashboard/views/Products/ProductsAdd.vue';
import FilterGroups from '@/views/Dashboard/views/FilterGroups/index.vue';
import FilterGroupsAll from '@/views/Dashboard/views/FilterGroups/FilterGroupsAll.vue';
import FilterGroupsActive from '@/views/Dashboard/views/FilterGroups/FilterGroupsActive.vue';
import FilterGroupsInactive from '@/views/Dashboard/views/FilterGroups/FilterGroupsInactive.vue';
import FilterGroupsAdd from '@/views/Dashboard/views/FilterGroups/FilterGroupsAdd.vue';
import FilterGroupsEdit from '@/views/Dashboard/views/FilterGroups/FilterGroupsEdit';
import FilterGroupsEditGeneral from '@/views/Dashboard/views/FilterGroups/FilterGroupsEdit/FilterGroupsEditGeneral';
import FilterGroupsFilters from '@/views/Dashboard/views/FilterGroups/Filters';
import FilterGroupFiltersAdd from '@/views/Dashboard/views/FilterGroups/Filters/FilterGroupFiltersAdd.vue';
import FilterGroupFiltersEdit from '@/views/Dashboard/views/FilterGroups/Filters/FilterEdit/FilterGroupFiltersEdit.vue';
import FilterGroupFiltersAll from '@/views/Dashboard/views/FilterGroups/Filters/FilterGroupFiltersAll.vue';
import FilterGroupFiltersActive from '@/views/Dashboard/views/FilterGroups/Filters/FilterGroupFiltersActive.vue';
import FilterGroupFiltersInactive from '@/views/Dashboard/views/FilterGroups/Filters/FilterGroupFiltersInactive.vue';
import Banners from '@/views/Dashboard/views/Content/Banners';
import BannersList from '@/views/Dashboard/views/Content/Banners/views/List';
import BannersAdd from '@/views/Dashboard/views/Content/Banners/BannersAdd';
import BannersEdit from '@/views/Dashboard/views/Content/Banners/BannersEdit';
import Promos from '@/views/Dashboard/views/Promos/index.vue';
import PromosAll from '@/views/Dashboard/views/Promos/PromosAll.vue';
import PromosActive from '@/views/Dashboard/views/Promos/PromosActive.vue';
import PromosInactive from '@/views/Dashboard/views/Promos/PromosInactive.vue';
import PromosAdd from '@/views/Dashboard/views/Promos/PromosAdd.vue';
import PromosEdit from '@/views/Dashboard/views/Promos/PromosEdit/PromosEdit.vue';
import Brands from '@/views/Dashboard/views/Brands/index.vue';
import BrandsAll from '@/views/Dashboard/views/Brands/BrandsAll.vue';
import BrandsActive from '@/views/Dashboard/views/Brands/BrandsActive.vue';
import BrandsInactive from '@/views/Dashboard/views/Brands/BrandsInactive.vue';
import BrandsEdit from '@/views/Dashboard/views/Brands/BrandsEdit';
import BrandsEditGeneral from '@/views/Dashboard/views/Brands/BrandsEdit/BrandsEditGeneral';
import BrandsEditSEO from '@/views/Dashboard/views/Brands/BrandsEdit/BrandsEditSEO.vue';
import BrandsEditPhotos from '@/views/Dashboard/views/Brands/BrandsEdit/BrandsEditPhotos.vue';
import BrandsEditEnglish from '@/views/Dashboard/views/Brands/BrandsEdit/BrandsEditEnglish.vue';
import BrandsAdd from '@/views/Dashboard/views/Brands/BrandsAdd.vue';
import Categories from '@/views/Dashboard/views/Categories/index.vue';
import CategoriesAll from '@/views/Dashboard/views/Categories/CategoriesAll.vue';
import CategoriesEdit from '@/views/Dashboard/views/Categories/CategoriesEdit';
import CategoriesEditGeneral from '@/views/Dashboard/views/Categories/CategoriesEdit/CategoriesEditGeneral.vue';
import CategoriesEditSEO from '@/views/Dashboard/views/Categories/CategoriesEdit/CategoriesEditSEO.vue';
import CategoriesEditPhotos from '@/views/Dashboard/views/Categories/CategoriesEdit/CategoriesEditPhotos.vue';
import CategoriesEditEnglish from '@/views/Dashboard/views/Categories/CategoriesEdit/CategoriesEditEnglish.vue';
import CategoriesEditOrder from '@/views/Dashboard/views/Categories/CategoriesEdit/CategoriesEditOrder.vue';
import CategoriesAdd from '@/views/Dashboard/views/Categories/CategoriesAdd.vue';
import Orders from '@/views/Dashboard/views/Orders/index.vue';
import OrdersAll from '@/views/Dashboard/views/Orders/OrdersAll';
import OrdersPending from '@/views/Dashboard/views/Orders/OrdersPending';
import OrdersSent from '@/views/Dashboard/views/Orders/OrdersSent';
import OrdersCancelled from '@/views/Dashboard/views/Orders/OrdersCancelled';
import OrdersWithdrew from '@/views/Dashboard/views/Orders/OrdersWithdrew';
import OrdersUnfinished from '@/views/Dashboard/views/Orders/OrdersUnfinished';
import OrdersAdd from '@/views/Dashboard/views/Orders/OrdersAdd';
import OrdersEdit from '@/views/Dashboard/views/Orders/OrdersEdit/index.vue';
import OrdersEditGeneral from '@/views/Dashboard/views/Orders/OrdersEdit/OrdersEditGeneral.vue';
import OrdersEditNotes from '@/views/Dashboard/views/Orders/OrdersEdit/OrdersEditNotes.vue';
import Customers from '@/views/Dashboard/views/Customers/index.vue';
import CustomersAll from '@/views/Dashboard/views/Customers/CustomersAll';
import CustomersGuests from '@/views/Dashboard/views/Customers/CustomersGuests';
import CustomersRegistered from '@/views/Dashboard/views/Customers/CustomersRegistered';
import CustomersAdd from '@/views/Dashboard/views/Customers/CustomersAdd';
import CustomersEdit from '@/views/Dashboard/views/Customers/CustomersEdit/index';
import CustomersEditGeneral from '@/views/Dashboard/views/Customers/CustomersEdit/CustomersEditGeneral';
import CustomersEditAddresses from '@/views/Dashboard/views/Customers/CustomersEdit/CustomersEditAddresses';
import CustomersEditOrders from '@/views/Dashboard/views/Customers/CustomersEdit/CustomersEditOrders';
import CustomersEditProducts from '@/views/Dashboard/views/Customers/CustomersEdit/CustomersEditProducts';
import CustomersEditFavourites from '@/views/Dashboard/views/Customers/CustomersEdit/CustomersEditFavourites';
import Stats from '@/views/Dashboard/views/Stats/index.vue';
import StatsOrders from '@/views/Dashboard/views/Stats/StatsOrders';
import StatsProducts from '@/views/Dashboard/views/Stats/StatsProducts';
import StatsBrands from '@/views/Dashboard/views/Stats/StatsBrands';
import Marketing from '@/views/Dashboard/views/Marketing.vue';
import Coupons from '@/views/Dashboard/views/Coupons/index.vue';
import CouponsList from '@/views/Dashboard/views/Coupons/CouponsList.vue';
import CouponsAdd from '@/views/Dashboard/views/Coupons/CouponsAdd';
import CouponsEdit from '@/views/Dashboard/views/Coupons/CouponsEdit';
import Newsletter from '@/views/Dashboard/views/Newsletter/index.vue';
import NewsletterAdd from '@/views/Dashboard/views/Newsletter/NewsletterAdd';
import NewsletterEdit from '@/views/Dashboard/views/Newsletter/NewsletterEdit';
import NewsletterList from '@/views/Dashboard/views/Newsletter/NewsletterList.vue';
import Posts from '@/views/Dashboard/views/Posts/index.vue';
import PostsList from '@/views/Dashboard/views/Posts/PostsList.vue';
import PostsAdd from '@/views/Dashboard/views/Posts/PostsAdd';
import PostsEdit from '@/views/Dashboard/views/Posts/PostsEdit/index.vue';
import PostsEditGeneral from '@/views/Dashboard/views/Posts/PostsEdit/PostsEditGeneral';
import PostsEditPhotos from '@/views/Dashboard/views/Posts/PostsEdit/PostsEditPhotos';
import PostsEditSEO from '@/views/Dashboard/views/Posts/PostsEdit/PostsEditSEO';
import PostsEditComments from '@/views/Dashboard/views/Posts/PostsEdit/PostsEditComments';
import PostsEditEnglish from '@/views/Dashboard/views/Posts/PostsEdit/PostsEditEnglish.vue';
import PostCategories from '@/views/Dashboard/views/PostCategories/index.vue';
import PostCategoriesList from '@/views/Dashboard/views/PostCategories/PostCategoriesList.vue';
import PostCategoriesAdd from '@/views/Dashboard/views/PostCategories/PostCategoriesAdd';
import PostCategoriesEdit from '@/views/Dashboard/views/PostCategories/PostCategoriesEdit/index.vue';
import PostCategoriesEditGeneral from '@/views/Dashboard/views/PostCategories/PostCategoriesEdit/PostCategoriesEditGeneral';
import PostCategoriesEditEnglish from '@/views/Dashboard/views/PostCategories/PostCategoriesEdit/PostCategoriesEditEnglish';
import PostCategoriesEditSEO from '@/views/Dashboard/views/PostCategories/PostCategoriesEdit/PostCategoriesEditSEO';
import Authors from '@/views/Dashboard/views/Authors/index.vue';
import AuthorsList from '@/views/Dashboard/views/Authors/AuthorsList.vue';
import AuthorsAdd from '@/views/Dashboard/views/Authors/AuthorsAdd';
import AuthorsEdit from '@/views/Dashboard/views/Authors/AuthorsEdit/index';
import AuthorsEditGeneral from '@/views/Dashboard/views/Authors/AuthorsEdit/AuthorsEditGeneral';
import AuthorsEditSEO from '@/views/Dashboard/views/Authors/AuthorsEdit/AuthorsEditSEO';
import Settings from '@/views/Dashboard/views/Settings';
import Pages from '@/views/Dashboard/views/Content/Pages';
import PagesList from '@/views/Dashboard/views/Content/Pages/PagesList';
import PagesEdit from '@/views/Dashboard/views/Content/Pages/PagesEdit';
import Content from '@/views/Dashboard/views/Content';
import Questions from '@/views/Dashboard/views/Content/Questions';
import QuestionsList from '@/views/Dashboard/views/Content/Questions/QuestionsList';
import QuestionsAdd from '@/views/Dashboard/views/Content/Questions/QuestionsAdd';
import QuestionsEdit from '@/views/Dashboard/views/Content/Questions/QuestionsEdit';
import Error from '@/views/Dashboard/views/Error';
import Organization from '@/views/Dashboard/views/Organization';
import OrganizationServices from '@/views/Dashboard/views/Organization/OrganizationServices';
import OrganizationGeneral from '@/views/Dashboard/views/Organization/OrganizationGeneral';
import OrganizationUsersAdd from '@/views/Dashboard/views/Organization/OrganizationUsers/OrganizationUsersAdd';
import OrganizationUsersEdit from '@/views/Dashboard/views/Organization/OrganizationUsers/OrganizationUsersEdit';
import OrganizationUsersList from '@/views/Dashboard/views/Organization/OrganizationUsers/OrganizationUsersList';
import CheckoutMethods from '@/views/Dashboard/views/Settings/CheckoutMethods';
import CheckoutMethodsList from '@/views/Dashboard/views/Settings/CheckoutMethods/CheckoutMethodsList.vue';
import CheckoutMethodsAdd from '@/views/Dashboard/views/Settings/CheckoutMethods/CheckoutMethodsAdd';
import CheckoutMethodsEdit from '@/views/Dashboard/views/Settings/CheckoutMethods/CheckoutMethodsEdit';
import SendMethods from '@/views/Dashboard/views/Settings/SendMethods';
import SendMethodsList from '@/views/Dashboard/views/Settings/SendMethods/SendMethodsList.vue';
import SendMethodsAdd from '@/views/Dashboard/views/Settings/SendMethods/SendMethodsAdd';
import SendMethodsEdit from '@/views/Dashboard/views/Settings/SendMethods/SendMethodsEdit';

Vue.use(VueRouter);

const Dashboard = () => import('@/views/Dashboard');

const routes = [
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: '/error',
        name: 'error',
        component: Error,
      },
      {
        path: '/products',
        name: 'products',
        component: Products,
        redirect: { name: 'products.all' },
        children: [
          {
            path: 'all',
            name: 'products.all',
            component: ProductsAll,
          },
          {
            path: 'active',
            name: 'products.active',
            component: ProductsActive,
          },
          {
            path: 'inactive',
            name: 'products.inactive',
            component: ProductsInactive,
          },
          {
            path: 'deleted',
            name: 'products.deleted',
            component: ProductsDeleted,
          },
          {
            path: ':id/edit',
            name: 'products.edit',
            component: ProductsEdit,
            redirect: { name: 'products.edit.general' },
            children: [
              {
                path: 'general',
                name: 'products.edit.general',
                component: ProductsEditGeneral,
              },
              {
                path: 'photos',
                name: 'products.edit.photos',
                component: ProductsEditPhotos,
              },
              {
                path: 'feeds',
                name: 'products.edit.feeds',
                component: ProductsEditFeeds,
              },
              {
                path: 'prices',
                name: 'products.edit.prices',
                component: ProductsEditPrices,
              },
              {
                path: 'seo',
                name: 'products.edit.seo',
                component: ProductsEditSEO,
              },
              {
                path: 'barcodes',
                name: 'products.edit.barcodes',
                component: ProductsEditBarcodes,
              },
              {
                path: 'reviews',
                name: 'products.edit.reviews',
                component: ProductsEditReviews,
              },
              {
                path: 'categories',
                name: 'products.edit.categories',
                component: ProductsEditCategories,
              },
              {
                path: 'filters',
                name: 'products.edit.filters',
                component: ProductsEditFilters,
              },
              {
                path: 'english',
                name: 'products.edit.english',
                component: ProductsEditEnglish,
              },
            ],
          },
          {
            path: 'add',
            name: 'products.add',
            component: ProductsAdd,
          },
        ],
      },
      {
        path: '/filterGroups',
        name: 'filterGroups',
        component: FilterGroups,
        redirect: { name: 'filterGroups.all' },
        children: [
          {
            path: ':id/filters',
            name: 'filterGroups.filters',
            component: FilterGroupsFilters,
            redirect: { name: 'filterGroup.filters.all' },
            children: [
              {
                path: 'add',
                name: 'filterGroup.filters.add',
                component: FilterGroupFiltersAdd,
              },
              {
                path: ':filter/edit',
                name: 'filterGroup.filters.edit',
                component: FilterGroupFiltersEdit,
              },
              {
                path: 'all',
                name: 'filterGroup.filters.all',
                component: FilterGroupFiltersAll,
              },
              {
                path: 'active',
                name: 'filterGroup.filters.active',
                component: FilterGroupFiltersActive,
              },
              {
                path: 'inactive',
                name: 'filterGroup.filters.inactive',
                component: FilterGroupFiltersInactive,
              },
            ],
          },
          {
            path: 'add',
            name: 'filterGroups.add',
            component: FilterGroupsAdd,
          },
          {
            path: ':id/edit',
            name: 'filterGroups.edit',
            component: FilterGroupsEdit,
            redirect: { name: 'filterGroups.edit.general' },
            children: [
              {
                path: '',
                name: 'filterGroups.edit.general',
                component: FilterGroupsEditGeneral,
              },
            ],
          },
          {
            path: 'all',
            name: 'filterGroups.all',
            component: FilterGroupsAll,
          },
          {
            path: 'active',
            name: 'filterGroups.active',
            component: FilterGroupsActive,
          },
          {
            path: 'inactive',
            name: 'filterGroups.inactive',
            component: FilterGroupsInactive,
          },
        ],
      },
      {
        path: '/brands',
        name: 'brands',
        component: Brands,
        redirect: { name: 'brands.all' },
        children: [
          {
            path: 'all',
            name: 'brands.all',
            component: BrandsAll,
          },
          {
            path: 'active',
            name: 'brands.active',
            component: BrandsActive,
          },
          {
            path: 'inactive',
            name: 'brands.inactive',
            component: BrandsInactive,
          },
          {
            path: ':id/edit',
            name: 'brands.edit',
            component: BrandsEdit,
            redirect: { name: 'brands.edit.general' },
            children: [
              {
                path: '',
                name: 'brands.edit.general',
                component: BrandsEditGeneral,
              },
              {
                path: 'seo',
                name: 'brands.edit.seo',
                component: BrandsEditSEO,
              },
              {
                path: 'photos',
                name: 'brands.edit.photos',
                component: BrandsEditPhotos,
              },
              {
                path: 'english',
                name: 'brands.edit.english',
                component: BrandsEditEnglish,
              },
            ],
          },
          {
            path: 'add',
            name: 'brands.add',
            component: BrandsAdd,
          },
        ],
      },
      {
        path: '/promos',
        name: 'promos',
        component: Promos,
        redirect: { name: 'promos.all' },
        children: [
          {
            path: 'all',
            name: 'promos.all',
            component: PromosAll,
          },
          {
            path: 'active',
            name: 'promos.active',
            component: PromosActive,
          },
          {
            path: 'inactive',
            name: 'promos.inactive',
            component: PromosInactive,
          },
          {
            path: 'add',
            name: 'promos.add',
            component: PromosAdd,
          },
          {
            path: ':id/edit',
            name: 'promos.edit',
            component: PromosEdit,
          },
        ],
      },
      {
        path: '/categories',
        name: 'categories',
        component: Categories,
        redirect: { name: 'categories.all' },
        children: [
          {
            path: 'all',
            name: 'categories.all',
            component: CategoriesAll,
          },
          {
            path: ':id/edit',
            name: 'categories.edit',
            component: CategoriesEdit,
            redirect: { name: 'categories.edit.general' },
            children: [
              {
                path: '',
                name: 'categories.edit.general',
                component: CategoriesEditGeneral,
              },
              {
                path: 'seo',
                name: 'categories.edit.seo',
                component: CategoriesEditSEO,
              },
              {
                path: 'photos',
                name: 'categories.edit.photos',
                component: CategoriesEditPhotos,
              },
              {
                path: 'order',
                name: 'categories.edit.order',
                component: CategoriesEditOrder,
              },
              {
                path: 'english',
                name: 'categories.edit.english',
                component: CategoriesEditEnglish,
              },
            ],
          },
          {
            path: 'add',
            name: 'categories.add',
            component: CategoriesAdd,
          },
        ],
      },
      {
        path: '/orders',
        name: 'orders',
        component: Orders,
        redirect: { name: 'orders.pending' },
        children: [
          {
            path: 'all',
            name: 'orders.all',
            component: OrdersAll,
          },
          {
            path: 'pending',
            name: 'orders.pending',
            component: OrdersPending,
          },
          {
            path: 'sent',
            name: 'orders.sent',
            component: OrdersSent,
          },
          {
            path: 'cancelled',
            name: 'orders.cancelled',
            component: OrdersCancelled,
          },
          {
            path: 'withdrew',
            name: 'orders.withdrew',
            component: OrdersWithdrew,
          },
          {
            path: 'unfinished',
            name: 'orders.unfinished',
            component: OrdersUnfinished,
          },
          {
            path: 'add',
            name: 'orders.add',
            component: OrdersAdd,
          },
          {
            path: ':id/edit',
            name: 'orders.edit',
            component: OrdersEdit,
            redirect: { name: 'orders.edit.general' },
            children: [
              {
                path: '',
                name: 'orders.edit.general',
                component: OrdersEditGeneral,
              },
              {
                path: 'notes',
                name: 'orders.edit.notes',
                component: OrdersEditNotes,
              },
            ],
          },
        ],
      },
      {
        path: '/customers',
        name: 'customers',
        component: Customers,
        redirect: { name: 'customers.all' },
        children: [
          {
            path: 'add',
            name: 'customers.add',
            component: CustomersAdd,
          },
          {
            path: ':id/customers',
            name: 'customers.edit',
            component: CustomersEdit,
            redirect: { name: 'customers.edit.general' },
            children: [
              {
                path: 'general',
                name: 'customers.edit.general',
                component: CustomersEditGeneral,
              },
              {
                path: 'addresses',
                name: 'customers.edit.addresses',
                component: CustomersEditAddresses,
              },
              {
                path: 'orders',
                name: 'customers.edit.orders',
                component: CustomersEditOrders,
              },
              {
                path: 'products',
                name: 'customers.edit.products',
                component: CustomersEditProducts,
              },
              {
                path: 'favourites',
                name: 'customers.edit.favourites',
                component: CustomersEditFavourites,
              },
            ],
          },
          {
            path: 'all',
            name: 'customers.all',
            component: CustomersAll,
          },
          {
            path: 'registered',
            name: 'customers.registered',
            component: CustomersRegistered,
          },
          {
            path: 'guests',
            name: 'customers.guests',
            component: CustomersGuests,
          },
        ],
      },
      {
        path: '/stats',
        name: 'stats',
        component: Stats,
        redirect: { name: 'stats.orders' },
        children: [
          {
            path: 'orders',
            name: 'stats.orders',
            component: StatsOrders,
          },
          {
            path: 'products',
            name: 'stats.products',
            component: StatsProducts,
          },
          {
            path: 'brands',
            name: 'stats.brands',
            component: StatsBrands,
          },
        ],
      },
      {
        path: '/posts',
        name: 'posts',
        component: Posts,
        redirect: { name: 'posts.list' },
        children: [
          {
            path: 'add',
            name: 'posts.add',
            component: PostsAdd,
          },
          {
            path: ':id/edit',
            name: 'posts.edit',
            component: PostsEdit,
            redirect: { name: 'posts.edit.general' },
            children: [
              {
                path: '',
                name: 'posts.edit.general',
                component: PostsEditGeneral,
              },
              {
                path: 'photos',
                name: 'posts.edit.photos',
                component: PostsEditPhotos,
              },
              {
                path: 'seo',
                name: 'posts.edit.seo',
                component: PostsEditSEO,
              },
              {
                path: 'comments',
                name: 'posts.edit.comments',
                component: PostsEditComments,
              },
              {
                path: 'english',
                name: 'posts.edit.english',
                component: PostsEditEnglish,
              },
            ],
          },
          {
            path: '',
            name: 'posts.list',
            component: PostsList,
          },
        ],
      },
      {
        path: '/post-categories',
        name: 'postCategories',
        component: PostCategories,
        redirect: { name: 'postCategories.list' },
        children: [
          {
            path: 'add',
            name: 'postCategories.add',
            component: PostCategoriesAdd,
          },
          {
            path: '',
            name: 'postCategories.list',
            component: PostCategoriesList,
          },
          {
            path: ':id/edit',
            name: 'postCategories.edit',
            component: PostCategoriesEdit,
            redirect: { name: 'postCategories.edit.general' },
            children: [
              {
                path: '',
                name: 'postCategories.edit.general',
                component: PostCategoriesEditGeneral,
              },
              {
                path: 'seo',
                name: 'postCategories.edit.seo',
                component: PostCategoriesEditSEO,
              },
              {
                path: 'english',
                name: 'postCategories.edit.english',
                component: PostCategoriesEditEnglish,
              },
            ],
          },
        ],
      },
      {
        path: '/authors',
        name: 'authors',
        component: Authors,
        redirect: { name: 'authors.list' },
        children: [
          {
            path: 'add',
            name: 'authors.add',
            component: AuthorsAdd,
          },
          {
            path: '',
            name: 'authors.list',
            component: AuthorsList,
          },
          {
            path: ':id/edit',
            name: 'authors.edit',
            component: AuthorsEdit,
            redirect: { name: 'authors.edit.general' },
            children: [
              {
                path: '',
                name: 'authors.edit.general',
                component: AuthorsEditGeneral,
              },
              {
                path: 'seo',
                name: 'authors.edit.seo',
                component: AuthorsEditSEO,
              },
            ],
          },
        ],
      },
      {
        path: '/marketing',
        name: 'marketing',
        component: Marketing,
      },
      {
        path: '/coupons',
        name: 'coupons',
        component: Coupons,
        redirect: { name: 'coupons.list' },
        children: [
          {
            path: 'add',
            name: 'coupons.add',
            component: CouponsAdd,
          },
          {
            path: ':id/edit',
            name: 'coupons.edit',
            component: CouponsEdit,
          },
          {
            path: '',
            name: 'coupons.list',
            component: CouponsList,
          },
        ],
      },
      {
        path: '/newsletters',
        name: 'newsletters',
        component: Newsletter,
        redirect: { name: 'newsletters.list' },
        children: [
          {
            path: 'add',
            name: 'newsletters.add',
            component: NewsletterAdd,
          },
          {
            path: ':id/edit',
            name: 'newsletters.edit',
            component: NewsletterEdit,
          },
          {
            path: '',
            name: 'newsletters.list',
            component: NewsletterList,
          },
        ],
      },
      {
        path: '/organization',
        name: 'organization',
        component: Organization,
        redirect: { name: 'organization.general' },
        children: [
          {
            path: 'users/add',
            name: 'organization.users.add',
            component: OrganizationUsersAdd,
          },
          {
            path: 'users/:id/edit',
            name: 'organization.users.edit',
            component: OrganizationUsersEdit,
          },
          {
            path: 'users',
            name: 'organization.users.list',
            component: OrganizationUsersList,
          },
          {
            path: 'services',
            name: 'organization.services',
            component: OrganizationServices,
          },
          {
            path: 'general',
            name: 'organization.general',
            component: OrganizationGeneral,
          },
        ],
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        redirect: { name: 'settings.checkout-methods' },
        children: [
          {
            path: 'checkout-methods',
            name: 'settings.checkout-methods',
            component: CheckoutMethods,
            redirect: { name: 'settings.checkout-methods.list' },
            children: [
              {
                path: 'add',
                name: 'settings.checkout-methods.add',
                component: CheckoutMethodsAdd,
              },
              {
                path: ':id/edit',
                name: 'settings.checkout-methods.edit',
                component: CheckoutMethodsEdit,
              },
              {
                path: '',
                name: 'settings.checkout-methods.list',
                component: CheckoutMethodsList,
              },
            ],
          },
          {
            path: 'send-methods',
            name: 'settings.send-methods',
            component: SendMethods,
            redirect: { name: 'settings.send-methods.list' },
            children: [
              {
                path: 'add',
                name: 'settings.send-methods.add',
                component: SendMethodsAdd,
              },
              {
                path: ':id/edit',
                name: 'settings.send-methods.edit',
                component: SendMethodsEdit,
              },
              {
                path: '',
                name: 'settings.send-methods.list',
                component: SendMethodsList,
              },
            ],
          },
        ],
      },
      {
        path: '/content',
        name: 'content',
        component: Content,
        redirect: { name: 'content.pages' },
        children: [
          {
            path: 'pages',
            name: 'content.pages',
            component: Pages,
            redirect: { name: 'content.pages.list' },
            children: [
              {
                path: ':slug/edit',
                name: 'content.pages.edit',
                component: PagesEdit,
              },
              {
                path: '',
                name: 'content.pages.list',
                component: PagesList,
              },
            ],
          },
          {
            path: 'questions',
            name: 'content.questions',
            component: Questions,
            redirect: { name: 'content.questions.list' },
            children: [
              {
                path: 'add',
                name: 'content.questions.add',
                component: QuestionsAdd,
              },
              {
                path: ':id/edit',
                name: 'content.questions.edit',
                component: QuestionsEdit,
              },
              {
                path: '',
                name: 'content.questions.list',
                component: QuestionsList,
              },
            ],
          },
          {
            path: 'banners',
            name: 'content.banners',
            component: Banners,
            redirect: { name: 'content.banners.list' },
            children: [
              {
                path: 'add',
                name: 'content.banners.add',
                component: BannersAdd,
              },
              {
                path: '',
                name: 'content.banners.list',
                component: BannersList,
              },
              {
                path: ':id/edit',
                name: 'content.banners.edit',
                component: BannersEdit,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
  },
];

const router = new VueRouter({
  mode: 'history',
  parseQuery(query) {
    return Qs.parse(query);
  },
  stringifyQuery(query) {
    const result = Qs.stringify(query, { encode: false });
    return result ? `?${result}` : '';
  },
  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth',
      });
    }

    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isTestEnv = process.env.NODE_ENV === 'test';
  const publicPages = ['/login', '/forgot-password', '/reset-password'];
  const authRequired = !publicPages.includes(to.path);

  if (!isTestEnv) {
    const getAuthenticated = store.getters['session/getAuthenticated'];

    if (publicPages.includes(to.path) && getAuthenticated) {
      next('/');
      return;
    }

    if (authRequired && !getAuthenticated) {
      next({ path: '/login', query: { redirect: to.fullPath } });
      return;
    }
  }

  next();
});

export default router;
