<template>
  <Layout :breadcrumb-items="breadcrumbItems">
    <template #actions>
      <BDropdown
        size="sm"
        right
        data-test="posts-categories-actions"
        variant="primary"
      >
        <template v-slot:button-content class="btn-primary">
          Ενέργειες
          <BIcon icon="chevron-down" class="font-size-sm ml-1" />
        </template>

        <BDropdownItem :to="{ name: 'organization.users.add' }">
          Προσθήκη χρήστη
        </BDropdownItem>
      </BDropdown>
    </template>

    <BCard no-body>
      <ListActionsBar
        :disabled="!hasSelectedItems"
        has-activate-option
        has-deactivate-option
        @clear-search="handleClearSearch"
        @mass-activate="handleMassActivate"
        @mass-deactivate="handleMassDeactivate"
        @mass-delete="handleMassDelete"
        @on-search="handleSearch"
      />

      <BCardBody>
        <ListPagination
          v-if="hasPagination"
          :total="total"
          :current-page="currentPage"
          :per-page="perPage"
          @select-size="handleSizeSelection"
          @select-page="handlePageSelection"
          class="mb-3"
        />

        <BTable
          hover
          bordered
          striped
          no-sort-reset
          sort-icon-left
          responsive="md"
          id="table"
          ref="table"
          :items="usersProvider"
          :current-page="currentPage"
          :fields="fields"
          :filter="searchTerm"
          :busy="isLoading"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          show-empty
          data-test="users-list"
        >
          <template v-slot:head(checkbox)>
            <BFormCheckbox
              v-model="allSelected"
              :indeterminate="indeterminate"
              class="text-left"
              @change="toggleAll"
            />
          </template>

          <template v-slot:cell(checkbox)="data">
            <BFormCheckboxGroup
              :id="data.item.id"
              :disabled="user.id === data.item.id"
              v-model="selectedIds"
              :options="[data.item.id]"
              class="text-left listed-checkbox"
            />
          </template>

          <template v-slot:cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(name)="data">
            <RouterLink
              :to="{
                name: 'organization.users.edit',
                params: { id: data.item.id },
              }"
              class="text-decoration-none d-flex align-items-center"
            >
              <Gravatar
                :size="20"
                :email="data.item.email"
                :alt="data.item.alt || data.item.email"
                default-img="mm"
                class="rounded-circle mr-2"
              />
              {{ data.item.name }}
            </RouterLink>
          </template>

          <template v-slot:cell(active)="data">
            <BFormCheckbox
              :disabled="user.id === data.item.id"
              :checked="data.item.active"
              switch
              @change="handleToggleActive(data.item)"
            />
          </template>

          <template v-slot:cell(actions)="data">
            <ListThreeDotsMenu
              :item="data.item"
              :can-delete="user.id !== data.item.id"
              route-name="organization.users.edit"
              @on-delete="handleDelete"
            />
          </template>

          <template v-slot:table-busy>
            <div class="text-center my-4">
              <div class="mb-4">
                <BSpinner class="align-middle" />
              </div>
              <h5>Λήψη δεδομένων...</h5>
            </div>
          </template>

          <template v-slot:empty>
            <h5 class="mb-0 py-2 text-center">Δεν βρέθηκαν χρήστες</h5>
          </template>

          <template v-slot:emptyfiltered>
            <div class="mb-0 py-2 text-center">
              Δεν βρέθηκαν αποτελέσματα αναζήτησης για "{{ searchTerm }}"
            </div>
          </template>
        </BTable>

        <ListPagination
          v-if="hasPagination"
          :total="total"
          :current-page="currentPage"
          :per-page="perPage"
          @select-size="handleSizeSelection"
          @select-page="handlePageSelection"
          class="mt-3"
        />
      </BCardBody>
    </BCard>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex';
import qs from 'qs';
import Gravatar from 'vue-gravatar';
import request from '@/utils/request';
import PaginationMixin from '@/mixins/PaginationMixin';
import ListPagination from '@/components/ListPagination';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu';
import ListActionsBar from '@/components/ListActionsBar';
import Layout from '../components/OrganizationLayout';

export default {
  mixins: [PaginationMixin],

  components: {
    Layout,
    ListThreeDotsMenu,
    ListPagination,
    Gravatar,
    ListActionsBar,
  },

  data: () => ({
    sortBy: 'name',
    sortDesc: false,
    fields: [
      { label: '', key: 'checkbox', class: 'text-right' },
      '#',
      { label: 'Όνομα', key: 'name', sortable: true },
      {
        key: 'email',
      },
      { label: 'Ενεργός', key: 'active' },
      { label: '', key: 'actions', class: 'text-right' },
    ],
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Οργανισμός',
        to: { name: 'organization' },
      },
      {
        text: 'Χρήστες',
        active: true,
      },
    ],
  }),

  computed: {
    ...mapGetters({
      user: 'session/getUser',
    }),
  },

  methods: {
    async usersProvider(ctx) {
      try {
        this.isLoading = true;

        const { data } = await request.get('/users', {
          params: {
            page: ctx.currentPage,
            size: ctx.perPage,
            sortBy: ctx.sortBy,
            sortDesc: ctx.sortDesc,
            filter: ctx.filter,
          },
        });

        this.currentPage = data.users.current_page;
        this.total = data.users.total;
        this.items = data.users.data;
        this.isLoaded = true;

        return this.items;
      } catch (err) {
        this.$router.push({ name: 'error' });
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεv μπόρεσαν να φορτωθούν οι χρήστες',
          icon: 'error',
          timer: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },

    handleDelete(item) {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το χρήστη: ${item.name};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/users/${item.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Ο χρήστης ${item.name} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
            });

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Ο χρήστης ${item.name} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
            });
          }
        }
      });
    },

    handleMassDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε ${
          this.selectedIds.length === 1
            ? 'τον επιλεγμένο χρήστη;'
            : `τους επιλεγμένους ${this.selectedIds.length} χρήστες;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete('/users', {
              params: {
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            });

            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Ο χρήστης διαγράφηκε επιτυχώς'
                  : `${this.selectedIds.length} χρήστες διαγράφηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
            });

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Ο χρήστης δεν διεγράφη'
                  : 'Οι χρήστες δεν διεγράφησαν'
              }`,
              icon: 'error',
              timer: 3000,
            });
          }
        }
      });
    },

    handleMassActivate() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να ενεργοποιήσετε ${
          this.selectedIds.length === 1
            ? 'τον επιλεγμένο χρήστη;'
            : `τους επιλεγμένους ${this.selectedIds.length} χρήστες;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.patch(
              '/users',
              {},
              {
                params: {
                  active: true,
                  ids: this.selectedIds,
                },
                paramsSerializer: params => {
                  return qs.stringify(params, {
                    arrayFormat: 'comma',
                    encode: false,
                  });
                },
              },
            ),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'Ο χρήστης ενεργοποιήθηκε επιτυχώς'
                    : `${this.selectedIds.length} χρήστες ενεργοποιήθηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
              });

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Ο χρήστης δεν ενεργοποιήθηκε'
                  : 'Οι χρήστες δεν ενεργοποιήθηκαν'
              }`,
              icon: 'error',
              timer: 3000,
            });
          }
        }
      });
    },

    handleMassDeactivate() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να απενεργοποιήσετε ${
          this.selectedIds.length === 1
            ? 'τον επιλεγμένο χρήστη;'
            : `τους επιλεγμένους ${this.selectedIds.length} χρήστες;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.patch(
              '/users',
              {},
              {
                params: {
                  active: false,
                  ids: this.selectedIds,
                },
                paramsSerializer: params => {
                  return qs.stringify(params, {
                    arrayFormat: 'comma',
                    encode: false,
                  });
                },
              },
            ),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'Ο χρήστης απενεργοποιήθηκε επιτυχώς'
                    : `${this.selectedIds.length} χρήστες απενεργοποιήθηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
              });

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Ο χρήστης δεν απενεργοποιήθηκε'
                  : 'Οι χρήστες δεν απενεργοποιήθηκαν'
              }`,
              icon: 'error',
              timer: 3000,
            });
          }
        }
      });
    },

    async handleToggleActive(item) {
      try {
        await request.patch(`/users/${item.id}/active`);

        this.$swal({
          title: 'Επιτυχία',
          text: `Ο χρήστης ${
            item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
          }!`,
          icon: 'success',
          timer: 3000,
        });

        this.$emit('get-counters');
        this.$nextTick(() => {
          this.$refs.table.refresh();
        });
      } catch (error) {
        this.$swal({
          title: 'Σφάλμα',
          text: `Αδυναμία ${
            item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
          } χρήστη`,
          icon: 'error',
          timer: 3000,
        });
      }
    },
  },
};
</script>
