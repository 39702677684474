<template>
  <BOverlay :show="!isOrganizationActive" rounded="sm" no-fade>
    <RouterView
      :counters="counters"
      :isCountersLoaded="isCountersLoaded"
      @get-counters="handleCounters"
    />

    <template #overlay>
      <div class="text-center">
        <h3 id="cancel-label">CommerceX premium feature</h3>
      </div>
    </template>
  </BOverlay>
</template>

<script>
import request from '@/utils/request';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    counters: {
      all: 0,
      active: 0,
      inactive: 0,
    },
    isCountersLoaded: false,
  }),

  async created() {
    await this.handleCounters();
  },

  computed: {
    ...mapGetters({
      organization: 'organization/getOrganization',
    }),

    isOrganizationActive() {
      if (this.organization.services.marketing) {
        return this.organization.services.marketing.services.pages.active;
      }
      return true;
    },
  },

  methods: {
    async handleCounters() {
      try {
        const { data } = await request.get('/promos/counters');

        this.counters = data.counters;
        this.isCountersLoaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
