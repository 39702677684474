import { get } from 'lodash';
import axios from 'axios';
import Qs from 'qs';

import getters from '../store/session/getters';
import store from '../store';
import router from '../router';

/* eslint-disable no-undef */
const request = axios.create({
  baseURL: 'https://api.pharme.gr/admin',
  withCredentials: true,
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' }),
});

const isHandlerEnabled = (config = {}) => get(config, 'handlerEnabled', true);

request.interceptors.request.use(
  config => {
    const token = getters.getToken(store.state.session);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/json';
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
    }
    return config;
  },
  error => Promise.reject(error),
);

request.interceptors.response.use(
  response => {
    const newToken = get(response, 'headers.authorization');
    if (newToken) {
      store.commit('session/updateToken', {
        token: newToken.replace('Bearer ', ''),
      }); // Ensure your mutation is correctly named and used
    }
    return response;
  },
  error => {
    if (isHandlerEnabled(error.config)) {
      const status = get(error, 'response.status', 500);
      const currentRouteName = router.currentRoute.name;

      if (
        status === 401 &&
        !['login', 'forgotPassword', 'resetPassword'].includes(currentRouteName)
      ) {
        console.log('Handling 401 error, should log out here.');
        store.dispatch('session/logoutUser');
        router.push({ name: 'login' });
      } else if (status === 500) {
        router.push('/error');
      }
    }
    throw error;
  },
);

export default request;
