<template>
  <BCard
    class="photo"
    body-class="d-flex flex-column justify-content-center"
    footer-class="p-0"
  >
    <div class="photo__container text-center" v-if="photo">
      <BImg
        v-if="photo.path && !isImageBroken"
        :src="photoURL"
        class="photo__img"
        @error="handleBrokenImage"
      />
      <BImg v-else src="@/assets/img/logo_horizontal.svg" class="photo__img" />
      <span
        v-if="photo.order === 0"
        title="Αρχική Φωτογραφία"
        v-b-tooltip.hover
        class="p-2 position-absolute d-flex justify-content-center align-items-center bg-primary photo__star"
      >
        <BIcon icon="star-fill" variant="white" />
      </span>
      <BIcon icon="grip-vertical" class="position-absolute photo__grip" />
    </div>
    <div v-else-if="src">
      <BImg :src="src" class="photo__img" />
    </div>
    <template #footer>
      <BButtonGroup class="w-100">
        <BButton
          variant="link"
          size="sm"
          v-b-tooltip.hover
          title="Προβολή"
          @click="handlePreview"
        >
          <BIcon icon="eye-fill" scale=".9" />
        </BButton>
        <BButton
          variant="link"
          size="sm"
          v-b-tooltip.hover
          title="Επεξεργασία"
          @click="handleEdit"
        >
          <BIcon icon="pencil-fill" scale=".8" />
        </BButton>
        <BButton
          variant="link"
          size="sm"
          v-b-tooltip.hover
          title="Διαγραφή"
          @click="handleDelete"
        >
          <BIcon icon="trash-fill" scale=".8" />
        </BButton>
      </BButtonGroup>
    </template>
  </BCard>
</template>

<script>
export default {
  props: {
    photo: {
      type: Object,
      required: false,
    },
    src: {
      type: String,
    },
  },

  data() {
    return {
      DOImgURL: process.env.VUE_APP_CDN_URL,
      isImageBroken: false,
      path: `${process.env.VUE_APP_CDN_URL}?path=${encodeURIComponent(
        this.photo.path,
      )}`,
    };
  },

  computed: {
    photoURL() {
      return `${this.DOImgURL}?path=${this.photo.path}`;
    },
  },

  methods: {
    handlePreview() {
      this.$emit('on-preview', this.photo);
    },

    handleEdit() {
      this.$emit('on-edit', this.photo);
    },

    handleDelete() {
      this.$emit('on-delete', this.photo);
    },

    handleBrokenImage() {
      this.isImageBroken = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.photo {
  height: 300px;

  &__container {
    min-height: 0;
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &__star {
    border-bottom-right-radius: $border-radius-xs;
    border-top-left-radius: $border-radius-xs;
    left: 0;
    top: 0;
  }

  &__grip {
    color: $gray-300;
    font-size: 1.625rem;
    left: 2px;
    top: 120px;
  }

  &:hover {
    cursor: grab;

    .photo__grip {
      color: $gray-700;
    }
  }
}
</style>
