
import { Component, Vue, Prop } from 'vue-property-decorator';
import request from '@/utils/request';

@Component
export default class OrdersEditNotesModal extends Vue {
  @Prop({ type: String, required: true }) readonly orderId!: string;
  form = {
    comment: '',
  };
  isSaving = false;

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.handleReset();
    });
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.post(`/orders/${this.orderId}/notes`, this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Η σημείωση της παραγγελίας αποθηκεύτηκε',
        icon: 'success',
        timer: 3000,
      });

      this.handleReset();

      this.$bvModal.hide('modal-order-note');
      this.$emit('on-submit');
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Η σημείωση της παραγγελίας δεν αποθηκεύτηκε',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleReset() {
    this.form = {
      comment: '',
    };

    (this.$refs.form as HTMLFormElement) &&
      (this.$refs.form as HTMLFormElement).reset();
  }

  created() {
    this.handleReset();
  }
}
