
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { isEmpty, debounce, uniqBy } from 'lodash';
import request from '@/utils/request';
import { Loading, Category } from '@/types';
// TODO: will change this component when we have the new api
@Component
export default class CategoriesSearch extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly clearable!: boolean;
  @Prop({ type: String, required: false, default: 'titles' })
  readonly label!: string;
  @Prop({
    type: String,
    required: false,
    default: 'Επιλέξτε κατηγορία',
  })
  readonly placeholder!: string;
  @Prop({ type: Boolean, required: false, default: false })
  readonly required!: boolean;
  @Prop({ type: Boolean, required: false, default: true })
  readonly searchable!: boolean;
  @Prop({ type: Object, required: false, default: null })
  readonly value!: Category;

  selected: Category | null = null;
  options: Category[] = [];

  @Watch('value', { immediate: true })
  handleValueChange(newVal: Category | null) {
    this.selected = newVal;
  }

  handleSearch = debounce(this.onSearch, 500);

  async onSearch(search: string, loading: Loading) {
    if (search) {
      try {
        loading(true);

        // TODO: will change this endpoint when we have the new api
        const { data } = await request.get('/categories', {
          params: {
            page: 1,
            size: 300,
            filter: search,
          },
        });

        this.options = uniqBy(data.categories.data as Category[], 'id');
      } catch (err) {
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεν μπόρεσαν να βρεθούν κατηγορίες',
          icon: 'error',
          timer: 3000,
        });
      } finally {
        loading(false);
      }
    }
  }

  handleInput(value: string) {
    this.options = [];

    if (!isEmpty(value)) {
      this.$emit('select-category', value);
    }
  }

  handleReset() {
    this.selected = null;
    this.options = [];
  }
}
