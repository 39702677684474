import request from '@/utils/request';

const actions = {
  async getOrganization({ commit }) {
    const { data } = await request.get('/organization');

    await commit('setOrganization', data.data);
  },
};

export default actions;
