export default {
  menuItems: {
    banners: 'Banners',
    home: 'Home',
    products: 'Products',
    brands: 'Brands',
    categories: 'Categories',
    orders: 'Orders',
    customers: 'Customers',
    marketing: 'Marketing',
    coupons: 'Coupons',
    promos: 'Marketing Pages',
    newsletters: 'Newsletter',
    blog: 'Blog',
    posts: 'Posts',
    authors: 'Authors',
    stats: 'Statistics',
    postCategories: 'Post Categories',
    content: 'Content',
    contentPages: 'Static Pages',
    faq: 'FAQ',
    settings: 'Settings',
    checkoutMethods: 'Checkout Methods',
    sendMethods: 'Send Methods',
  },
};
