<template>
  <BOverlay :show="isLoading" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex border-top">
      <div class="tab-header">
        Νέο Άρθρο
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm
          @submit.prevent="handleSubmit(handleForm)"
          novalidate
          data-test="form"
        >
          <BCardBody>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Τίτλος *">
                <BFormInput
                  id="title"
                  v-model="form.title"
                  type="text"
                  placeholder="Εισάγετε τον τίτλο του άρθρου"
                  data-test="title"
                  autofocus
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε τον τίτλο του άρθρου
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              name="slug"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup size="md" label="Slug *">
                <BInputGroup :prepend="`${eshopURL}/blog/`">
                  <BFormInput
                    id="slug"
                    v-model="form.slug"
                    type="text"
                    placeholder="Εισάγετε το slug του άρθρου"
                    data-test="slug"
                  />
                </BInputGroup>

                <BFormText
                  text-variant="danger"
                  data-test="slug-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε το slug του άρθρου
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              title="content"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Περιεχόμενο *">
                <ckeditor
                  :editor="editor"
                  v-model="form.content"
                  data-test="content"
                  :config="editorConfig"
                  tag-name="textarea"
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε περιεχόμενο για το άρθρο
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider name="author_id">
              <BFormGroup label="Συγγραφέας">
                <BFormSelect
                  :options="authors"
                  :disabled="!hasAvailableAuthors"
                  text-field="name"
                  value-field="id"
                  v-model="form.author_id"
                >
                  <template v-slot:first>
                    <BFormSelectOption :value="null" disabled>
                      -- Επιλέξτε συγγραφέα --
                    </BFormSelectOption>
                  </template>
                </BFormSelect>

                <BFormText v-if="!hasAvailableAuthors">
                  Δημιουργήστε πρώτα τουλάχιστον έναν συγγραφέα
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider name="postCategories">
              <BFormGroup label="Κατηγορίες Άρθρου" class="mb-0">
                <BFormTags
                  v-model="form.postCategories"
                  add-on-change
                  no-outer-focus
                >
                  <template
                    v-slot="{
                      tags,
                      inputAttrs,
                      inputHandlers,
                      disabled,
                      removeTag,
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-2"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <BFormTag
                          @remove="removeTag(tag)"
                          :title="tag"
                          :disabled="disabled"
                          variant="info"
                        >
                          {{ tag }}
                        </BFormTag>
                      </li>
                    </ul>
                    <BFormSelect
                      multiple
                      v-model="form.postCategories"
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || !hasAvailablePostCategories"
                      :options="availablePostCategories"
                    >
                      <template v-slot:first>
                        <BFormSelectOption value="" disabled>
                          -- Επιλέξτε κατηγορίες --
                        </BFormSelectOption>
                      </template>
                    </BFormSelect>
                  </template>
                </BFormTags>

                <BFormText v-if="!hasPostCategories">
                  Δημιουργήστε πρώτα τουλάχιστον μία κατηγορία
                </BFormText>
              </BFormGroup>
            </ValidationProvider>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </BOverlay>
</template>

<script>
import slug from 'slug';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import EditorMixin from '@/mixins/EditorMixin';

export default {
  components: {
    BaseButtonSubmit,
  },

  mixins: [EditorMixin],

  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
    isLoading: false,
    form: {
      title: '',
      slug: '',
      author_id: null,
      postCategories: [],
    },
    authors: [],
    postCategories: [],
    isSaving: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Άρθρα',
        to: { name: 'posts' },
      },
      {
        text: 'Προσθήκη',
        active: true,
      },
    ],
  }),

  computed: {
    availablePostCategories() {
      return this.postCategories
        .filter(option => !this.form.postCategories.includes(option))
        .map(option => option.title);
    },

    hasAvailablePostCategories() {
      return this.availablePostCategories.length > 0;
    },

    hasPostCategories() {
      return this.postCategories.length > 0;
    },

    hasAvailableAuthors() {
      return this.authors.length > 0;
    },
  },

  watch: {
    'form.title': {
      handler(value) {
        this.form = {
          ...this.form,
          slug: slug(value),
        };
      },
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const response = await Promise.all([
        request.get('/authors', {
          params: {
            paginated: false,
          },
        }),
        request.get('/post-categories', {
          params: {
            paginated: false,
          },
        }),
      ]);

      this.authors = response[0].data.authors;
      this.postCategories = response[1].data.postCategories;
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        const data = {
          ...this.form,
          postCategories: this.form.postCategories.map(
            category =>
              this.postCategories.find(cat => cat.title === category)?.id,
          ),
        };
        await request.post('/posts', data);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Το Άρθρο Προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'posts.list' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης άρθρου',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        title: '',
        slug: '',
        author_id: null,
        postCategories: [],
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
