
import { Component, Prop, Vue } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { CartItem, CheckoutMethod, CouponType } from '@/types';

@Component({
  mixins: [Vue2Filters.mixin],
})
export default class OrdersDetails extends Vue {
  @Prop({ type: Array, required: false }) readonly products!: CartItem[];
  @Prop({ type: String, required: false }) readonly checkoutMethodId!: string;
  @Prop({ type: Array, required: false })
  readonly checkoutMethods!: CheckoutMethod[];
  @Prop({ type: Number, required: false }) readonly checkout_charge!: number;
  @Prop({ type: Number, required: false }) readonly send_charge!: number;
  @Prop({ type: Number, required: false }) readonly coupon_value!: number;
  @Prop({ type: String, required: false }) readonly coupon_type!: string;
  @Prop({ type: Number, required: false }) readonly gift_cost!: number;

  get sellPriceTotal() {
    return this.products.reduce((acc: number, { count, item }: CartItem) => {
      return acc + item.sell_price * count;
    }, 0);
  }

  get totalTax() {
    return this.products.reduce((acc: number, { count, item }: CartItem) => {
      return acc + item.sell_price * (item.vat / 100) * count;
    }, 0);
  }

  get finalPriceTotal() {
    return this.products.reduce((acc: number, { count, item }: CartItem) => {
      return acc + item.final_price * count;
    }, 0);
  }

  get taxByVat() {
    // Group products by VAT
    const groupedByVat = this.products.reduce(
      (acc: any, { count, item }: CartItem) => {
        if (!acc[item.vat]) {
          acc[item.vat] = 0;
        }
        acc[item.vat] += item.sell_price * (item.vat / 100) * count;
        return acc;
      },
      {},
    );

    // Convert the object to an array of { vat, totalTax }
    return Object.entries(groupedByVat).map(([vat, totalTax]) => ({
      vat,
      totalTax,
    }));
  }

  get checkoutSendCharge() {
    return this.send_charge ?? 0;
  }

  get checkoutCharge() {
    return this.checkout_charge ?? 0;
  }

  get couponDiscount() {
    if (this.coupon_type === CouponType.Amount) {
      return this.coupon_value ?? 0;
    } else if (this.coupon_type === CouponType.Percentage) {
      return this.finalPriceTotal * (this.coupon_value / 100) ?? 0;
    } else {
      return 0;
    }
  }

  get withoutTax() {
    return this.finalPriceTotal - this.totalTax;
  }

  get giftCost() {
    return this.gift_cost ?? 0;
  }

  get orderTotal() {
    let orderTotalCost = this.finalPriceTotal;

    if (this.checkoutCharge) {
      orderTotalCost += this.checkoutCharge;
    }
    if (this.checkoutSendCharge) {
      orderTotalCost += this.checkoutSendCharge;
    }
    if (this.giftCost) {
      orderTotalCost += this.giftCost;
    }
    if (this.couponDiscount) {
      orderTotalCost -= this.couponDiscount;
    }

    return orderTotalCost;
  }
}
