
import { Component, Vue } from 'vue-property-decorator';
import ProductsMethodsMixin from '../mixins/ProductsMethodsMixin';

@Component({
  mixins: [ProductsMethodsMixin],
})
export default class ProductsMassUpdatesModal extends Vue {
  isSaving = false;
  updates = {
    discount: undefined,
    is_new: undefined,
    is_offer: undefined,
    is_popular: undefined,
    is_proposal: undefined,
  };
  options = [
    { value: undefined, text: '' },
    { value: false, text: 'Όχι' },
    { value: true, text: 'Ναι' },
  ];

  handleReset() {
    this.updates = {
      discount: undefined,
      is_new: undefined,
      is_offer: undefined,
      is_popular: undefined,
      is_proposal: undefined,
    };
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
