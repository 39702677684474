<template>
  <Layout
    :breadcrumbItems="breadcrumbItems"
    :counters="counters"
    :isCountersLoaded="isCountersLoaded"
  >
    <BCard no-body class="no-border-top-left">
      <ListActionsBar
        :disabled="!hasSelectedItems"
        @clear-search="handleClearSearch"
        @mass-delete="handleMassDelete"
        @on-search="handleSearch"
      />

      <BCardBody>
        <ListPagination
          v-if="hasPagination"
          :total="total"
          :current-page="currentPage"
          :per-page="perPage"
          @select-size="handleSizeSelection"
          @select-page="handlePageSelection"
          class="mb-3"
        />

        <BTable
          hover
          bordered
          striped
          no-sort-reset
          sort-icon-left
          responsive="md"
          id="table"
          ref="table"
          :items="customersProvider"
          :current-page="currentPage"
          :fields="fields"
          :filter="searchTerm"
          :busy="isLoading"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          show-empty
          data-test="customers-list"
        >
          <template v-slot:head(checkbox)>
            <BFormCheckbox
              v-model="allSelected"
              :indeterminate="indeterminate"
              class="text-left"
              @change="toggleAll"
            />
          </template>

          <template v-slot:cell(checkbox)="data">
            <BFormCheckboxGroup
              :id="data.item.id"
              v-model="selectedIds"
              :options="[data.item.id]"
              class="text-left listed-checkbox"
            />
          </template>

          <template v-slot:cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(fullName)="data">
            <RouterLink
              :to="{ name: 'customers.edit', params: { id: data.item.id } }"
              class="text-decoration-none d-flex align-items-center"
            >
              {{ data.item.fullName }}
            </RouterLink>
          </template>

          <template v-slot:cell(actions)="data">
            <ListThreeDotsMenu
              :item="data.item"
              route-name="customers.edit"
              @on-delete="handleDelete"
            />
          </template>

          <template v-slot:table-busy>
            <div class="text-center my-4">
              <div class="mb-4">
                <BSpinner class="align-middle" />
              </div>
              <h5>Λήψη δεδομένων...</h5>
            </div>
          </template>

          <template v-slot:empty>
            <h5 class="mb-0 py-2 text-center">Δεν βρέθηκαν πελάτες</h5>
          </template>

          <template v-slot:emptyfiltered>
            <div class="mb-0 py-2 text-center">
              Δεν βρέθηκαν αποτελέσματα αναζήτησης για "{{ searchTerm }}"
            </div>
          </template>
        </BTable>

        <ListPagination
          v-if="hasPagination"
          :total="total"
          :current-page="currentPage"
          :per-page="perPage"
          @select-size="handleSizeSelection"
          @select-page="handlePageSelection"
          class="mt-3"
        />
      </BCardBody>
    </BCard>
  </Layout>
</template>

<script>
import request from '@/utils/request';
import PaginationMixin from '@/mixins/PaginationMixin';
import ListPagination from '@/components/ListPagination';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu';
import ListActionsBar from '@/components/ListActionsBar';
import Layout from './components/CustomersLayout';
import CustomersMethodsMixin from './mixins/CustomersMethodsMixin';

export default {
  mixins: [PaginationMixin, CustomersMethodsMixin],

  components: {
    Layout,
    ListThreeDotsMenu,
    ListPagination,
    ListActionsBar,
  },

  props: {
    counters: Object,
    isCountersLoaded: Boolean,
  },

  data: () => ({
    sortBy: 'fullName',
    sortDesc: false,
    fields: [
      { label: '', key: 'checkbox', class: 'text-right' },
      '#',
      { label: 'Όνομα', key: 'fullName', sortable: true },
      {
        key: 'email',
      },
      { label: '', key: 'actions', class: 'text-right' },
    ],
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Πελάτες',
        active: true,
      },
    ],
  }),

  methods: {
    async customersProvider() {
      try {
        this.isLoading = true;

        const { data } = await request.get('/customers', {
          params: {
            page: this.currentPage,
            size: this.perPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            filter: this.filter,
            type: 'profile',
          },
        });

        this.currentPage = data.customers.current_page;
        this.total = data.customers.total;
        this.items = data.customers.data;
        this.isLoaded = true;

        return this.items;
      } catch (err) {
        this.$router.push({ name: 'error' });
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεv μπόρεσαν να φορτωθούν οι πελάτες',
          icon: 'error',
          timer: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
