<template>
  <section
    class="d-flex flex-column align-items-center justify-content-between flex-grow-1 min-vh-100"
  >
    <div
      class="container d-flex flex-column align-items-center justify-content-center flex-grow-1 h-100 py-3"
    >
      <img
        src="@/assets/img/logo_horizontal.svg"
        alt="Logo"
        class="mb-3 logo"
      />

      <div class="w-100 pt-3">
        <div class="form-wrapper mx-auto">
          <!-- Sign in view-->
          <div class="show" id="signin-view">
            <h1 class="h2 text-center">Επαναφορά Κωδικού</h1>

            <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
              <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
                <ValidationProvider
                  name="new_password"
                  rules="required|min:8"
                  v-slot="{ errors, failedRules }"
                >
                  <BFormGroup
                    size="md"
                    label="Νέος Κωδικός"
                    label-for="newPassword"
                  >
                    <BInputGroup size="md">
                      <BInputGroupPrepend>
                        <BInputGroupText>
                          <BIcon icon="lock" />
                        </BInputGroupText>
                      </BInputGroupPrepend>
                      <BFormInput
                        id="newPassword"
                        v-model="form.new_password"
                        type="password"
                        placeholder="Εισάγετε νέο κωδικό"
                        autocomplete="new-password"
                        autofocus
                      />
                    </BInputGroup>
                    <BFormText v-if="failedRules.min" text-variant="danger">
                      O κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες
                    </BFormText>
                    <BFormText v-else-if="errors[0]" text-variant="danger">
                      Παρακαλώ εισάγετε τoν κωδικό σας
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, failedRules }"
                  name="new_password_confirmation"
                  rules="required|min:8|confirmed:new_password"
                >
                  <BFormGroup
                    size="md"
                    label="Επιβεβαίωση Κωδικού*"
                    label-for="newPasswordConfirmation"
                  >
                    <BInputGroup size="md">
                      <BInputGroupPrepend>
                        <BInputGroupText>
                          <BIcon icon="lock" />
                        </BInputGroupText>
                      </BInputGroupPrepend>
                      <BFormInput
                        id="newPasswordConfirmation"
                        v-model="form.new_password_confirmation"
                        type="password"
                        placeholder="Επιβεβαιώστε νέο κωδικό"
                        autocomplete="new-password"
                      />
                    </BInputGroup>
                    <BFormText v-if="failedRules.min" text-variant="danger">
                      O κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες
                    </BFormText>
                    <BFormText
                      v-else-if="failedRules.confirmed"
                      text-variant="danger"
                    >
                      Οι κωδικοί δεν ταιριάζουν
                    </BFormText>
                    <BFormText v-else-if="errors[0]" text-variant="danger">
                      Παρακαλώ επαληθεύστε τoν κωδικό σας
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>

                <BaseButtonSubmit
                  variant="primary"
                  :block="true"
                  :isSaving="isSaving"
                  :isDisabled="invalid"
                  title="Επαναφορά Κωδικού"
                  :class="['mb-4', { 'pointer-events-none': isSaving }]"
                />
              </BForm>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <footer class="cs-footer">
      <div class="container font-size-sm mb-0 py-3">
        <span class="text-muted mr-1">
          © All rights reserved. Made with ♥ by </span
        ><a
          class="nav-link-style font-weight-normal"
          rel="noopener"
          href="https://commercex.gr"
          target="_blank"
        >
          CommerceX
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
  },
  data() {
    return {
      isSaving: false,
      form: {
        new_password: '',
        new_password_confirmation: '',
      },
    };
  },
  methods: {
    async handleForm() {
      if (!this.validateForm()) {
        return;
      }

      this.isSaving = true;
      const { email, token } = this.$route.query;

      try {
        await request.post('/process-reset-password', {
          password: this.form.new_password,
          password_confirmation: this.form.new_password_confirmation,
          email,
          token,
        });

        this.$swal({
          title: 'Επιτυχία',
          text: 'Ο νέος κωδικός πρόσβασης αποθηκεύτηκε επιτυχώς!',
          icon: 'success',
          timer: 3000,
          toast: true,
          position: 'bottom-end',
        });

        this.$router.push('/');
      } catch (error) {
        this.$swal({
          title: 'Σφάλμα',
          text: 'Η ανανέωση του κωδικού απέτυχε.',
          icon: 'error',
          timer: 3000,
          toast: true,
          position: 'bottom-end',
        });
      } finally {
        this.isSaving = false;
      }
    },
    validateForm() {
      return (
        this.form.new_password.length >= 8 &&
        this.form.new_password === this.form.new_password_confirmation
      );
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 150px;

  @include breakpoint(md) {
    width: 220px;
  }
}
</style>
