<template>
  <BOverlay :show="isLoading" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex justify-content-between align-items-center border-top">
      <div class="tab-header">
        Εγγεγραμμένος στο Newsletter
      </div>

      <BButton variant="primary" @click="handleDelete" size="sm">
        <BIcon icon="trash" class="mr-2" /> Διαγραφή
      </BButton>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
          <BCardBody>
            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider name="firstName">
                  <BFormGroup size="md" label="Όνομα">
                    <BFormInput
                      id="firstName"
                      v-model="form.firstName"
                      type="text"
                      placeholder="Εισάγετε όνομα"
                      data-test="firstName"
                      autofocus
                    />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider name="lastName">
                  <BFormGroup size="md" label="Επώνυμο">
                    <BFormInput
                      id="lastName"
                      v-model="form.lastName"
                      type="text"
                      placeholder="Εισάγετε επώνυμο"
                      data-test="lastName"
                    />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="12">
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors, failedRules }"
                >
                  <BFormGroup size="md" label="Email *">
                    <BFormInput
                      id="email"
                      v-model="form.email"
                      type="email"
                      placeholder="Εισάγετε email"
                      data-test="email"
                    />
                    <BFormText
                      text-variant="danger"
                      data-test="email-error"
                      v-if="failedRules.required"
                    >
                      Παρακαλώ εισάγετε το email του πελάτη
                    </BFormText>
                    <BFormText
                      text-variant="danger"
                      data-test="email-error"
                      v-else-if="failedRules.email"
                    >
                      Παρακαλώ εισάγετε ενα έγκυρο email
                    </BFormText>
                    <BFormText
                      text-variant="danger"
                      data-test="email-error"
                      v-else-if="errors[0]"
                    >
                      {{ errors[0] }}
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
  },

  data: () => ({
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Εγγεγραμμένοι στο Newsletter',
        to: { name: 'newsletters' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
  }),

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/newsletters/${this.$route.params.id}`,
      );

      this.model = data.newsletter;
      this.handleReset();
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί ο χρήστης',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/newsletters/${this.$route.params.id}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του χρήστη ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        console.log(err);
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του χρήστη δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = pick(this.model, ['firstName', 'lastName', 'email']);

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το χρήστη με email: ${this.model.email};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/newsletters/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Ο χρήστης διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            await this.$router.push({ name: 'newsletters.list' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Ο χρήστης δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },
  },
};
</script>
