<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <BDropdown size="sm" right data-test="brand-actions" variant="primary">
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>
          <BDropdownItem v-b-modal.modal-upload-photo size="sm">
            Προσθήκη φωτογραφίας
          </BDropdownItem>

          <BDropdownDivider v-if="canAddBrands" />
          <BDropdownItem v-if="canAddBrands" :to="{ name: 'brands.add' }">
            Προσθήκη μάρκας
          </BDropdownItem>

          <BDropdownDivider v-if="canDeleteBrands" />

          <BDropdownItemButton
            v-if="canDeleteBrands"
            class="delete-btn"
            @click="handleDelete"
          >
            Διαγραφή μάρκας
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <BCardBody>
          <div v-if="!hasPhotos || isLoading">
            <h5 class="mb-0 py-2 text-center">{{ text }}</h5>
          </div>

          <template v-else>
            <Draggable :value="newPhotos" class="row" @change="handleReorder">
              <BCol
                v-for="photo in newPhotos"
                :key="photo.id"
                cols="12"
                sm="6"
                md="4"
                xl="3"
                class="my-2"
              >
                <PhotoCard
                  :photo="photo"
                  @on-preview="handlePreview"
                  @on-edit="handleEdit"
                  @on-delete="handleDeletePhoto"
                />
              </BCol>
            </Draggable>
          </template>
        </BCardBody>
      </BCard>
      <PhotoUploadModal :is-saving="isSaving" @on-upload="handleUpload" />
      <PhotoPreviewModal v-if="selectedPhoto" :photo="selectedPhoto" />
      <PhotoEditModal
        v-if="selectedPhoto"
        :is-saving="isSaving"
        :photo="selectedPhoto"
        :edit-errors="editErrors"
        @on-edit="handleEditSubmit"
      />
    </Layout>
  </BOverlay>
</template>

<script>
/* eslint-disable */
import { cloneDeep, isEmpty } from 'lodash';
import Draggable from 'vuedraggable';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import PhotoCard from '@/components/PhotoCard';
import PhotoUploadModal from '@/components/PhotoUploadModal';
import PhotoPreviewModal from '@/components/PhotoPreviewModal';
import PhotoEditModal from '@/components/PhotoEditModal';
import Layout from './components/BrandsEditLayout';
import BrandsEditMethodsMixin from './mixins/BrandsEditMethodsMixin';

export default {
  components: {
    Draggable,
    BaseButtonSubmit,
    Layout,
    PhotoCard,
    PhotoUploadModal,
    PhotoPreviewModal,
    PhotoEditModal,
  },

  mixins: [BrandsEditMethodsMixin],
  data() {
    return {
      model: {},
      photos: [],
      newPhotos: [],
      selectedPhoto: null,
      isLoading: false,
      isSaving: false,
      editErrors: null,
      breadcrumbItems: [
        {
          text: 'Αρχική',
          to: { name: 'home' },
        },
        {
          text: 'Μάρκες',
          to: { name: 'brands' },
        },
        {
          text: 'Επεξεργασία',
          to: { name: 'brands.edit.general' },
        },
        {
          text: 'Φωτογραφίες',
          active: true,
        },
      ],
    };
  },

  watch: {
    photos: {
      handler(newVal) {
        this.newPhotos = cloneDeep(newVal);
      },
      immediate: true,
    },
  },

  computed: {
    hasPhotos() {
      return !isEmpty(this.photos);
    },

    text() {
      if (this.isLoading) {
        return 'Λήψη δεδομένων';
      }

      return 'Δεν βρέθηκαν φωτογραφίες';
    },
  },

  created() {
    this.getPhotos();
  },

  methods: {
    async getPhotos() {
      try {
        this.isLoading = true;
        const [photoData, brandData] = await Promise.all([
          request.get(`/brands/${this.$route.params.id}/photos`),
          request.get(`/brands/${this.$route.params.id}`),
        ]);

        this.photos = photoData.data.photos;
        this.model = brandData.data.brand;
      } catch (err) {
        this.$router.push({ name: 'error' });
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεv μπόρεσαν να φορτωθούν οι φωτογραφίες της μάρκας',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },

    handlePreview(photo) {
      this.selectedPhoto = photo;

      this.$nextTick(() => {
        this.$bvModal.show('modal-review-photo');
      });
    },

    handleEdit(photo) {
      this.selectedPhoto = photo;

      this.$nextTick(() => {
        this.$bvModal.show('modal-edit-photo');
      });
    },

    async handleEditSubmit(photo) {
      try {
        this.isSaving = true;

        const data = {
          unique_id: photo.unique_id,
        };

        await request.put(
          `/brands/${this.$route.params.id}/photos/${photo.id}`,
          data,
        );
        await this.getPhotos();

        this.$bvModal.hide('modal-edit-photo');

        this.$swal({
          toast: true,
          title: 'Επιτυχία',
          text: 'Η φωτογραφία ενημερώθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
          position: 'bottom-end',
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.editErrors = data.messages;
        }

        this.$swal({
          toast: true,
          title: 'Σφάλμα',
          text: 'Αδυναμία ενημέρωσης φωτογραφίας',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
          position: 'bottom-end',
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleDeletePhoto(photo) {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: 'Θέλετε να διαγράψετε αυτήν την φωτογραφία;',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            this.isLoading = true;

            await request.delete(
              `/brands/${this.$route.params.id}/photos/${photo.id}`,
            );
            await this.getPhotos();

            this.$swal({
              toast: true,
              title: 'Επιτυχία',
              text: `Η φωτογραφία διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
              position: 'bottom-end',
            });
          } catch (error) {
            this.$swal({
              toast: true,
              title: 'Σφάλμα',
              text: `Η φωτογραφία δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
              position: 'bottom-end',
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    async handleReorder({ moved: { oldIndex, newIndex } }) {
      try {
        this.isLoading = true;
        const movedItem = this.photos.find((item, index) => index === oldIndex);
        const remainingItems = this.photos.filter(
          (item, index) => index !== oldIndex,
        );

        const reorderedPhotos = [
          ...remainingItems.slice(0, newIndex),
          movedItem,
          ...remainingItems.slice(newIndex),
        ];

        this.newPhotos = reorderedPhotos;

        const reorderedPhotosIds = reorderedPhotos.map(photo => photo.id);

        const data = {
          photos: reorderedPhotosIds,
        };

        await request.put(
          `/brands/${this.$route.params.id}/photos/reorder`,
          data,
        );
        await this.getPhotos();

        this.$swal({
          toast: true,
          title: 'Επιτυχία',
          text: 'Η φωτογραφία αναδιατάχθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
          position: 'bottom-end',
        });
      } catch (err) {
        this.$swal({
          toast: true,
          title: 'Σφάλμα',
          text: 'Αδυναμία αναδιάταξης φωτογραφίας',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
          position: 'bottom-end',
        });
      } finally {
        this.isLoading = false;
      }
    },

    async handleUpload(photo) {
      try {
        this.isSaving = true;

        const data = new FormData();
        data.append('file', photo, photo.name);

        await request.post(`/brands/${this.$route.params.id}/photos`, data);
        await this.getPhotos();

        this.$bvModal.hide('modal-upload-photo');

        this.$swal({
          toast: true,
          title: 'Επιτυχία',
          text: 'Η φωτογραφία αποθηκεύτηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
          position: 'bottom-end',
        });
      } catch (err) {
        this.$swal({
          toast: true,
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης φωτογραφίας',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
          position: 'bottom-end',
        });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
