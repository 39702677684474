
import { Component, Vue, Watch } from 'vue-property-decorator';

import slug from 'slug';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import DynamicSelectMenuCategories from './components/DynamicSelectMenuCategories.vue';
import { Category } from '@/types';

@Component({
  mixins: [EditorMixin],
  components: {
    DynamicSelectMenuCategories,
  },
})
export default class CategoriesAdd extends Vue {
  eshopURL = process.env.VUE_APP_ESHOP_URL;
  isSaving = false;
  form: Partial<Category> = {
    title: '',
    content: '',
    slug: '',
  };
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Κατηγορίες',
      to: { name: 'categories' },
    },
    {
      text: 'Προσθήκη',
      active: true,
    },
  ];
  selectedCategory: Category | null = null;

  @Watch('form.title')
  handleTitle(newVal: string) {
    this.form = {
      ...this.form,
      slug: slug(newVal),
    };
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.post('/categories', this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Η κατηγορία προστέθηκε',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$emit('get-counters');

      await this.$router.push({ name: 'categories.all' });
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Αδυναμία προσθήκης κατηγορίας',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleCategorySelect(category: Category) {
    this.selectedCategory = category;
    this.form.parent_id = category.id;
  }

  handleReset() {
    this.form = {
      title: '',
      slug: '',
    };
    this.selectedCategory = null;
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
