<template>
  <BOverlay :show="isLoading" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <BDropdown
          size="sm"
          right
          data-test="product-actions"
          variant="primary"
        >
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <slot name="extra-actions" />

          <BDropdownItem v-if="canAddProducts" :to="{ name: 'products.add' }">
            Προσθήκη προϊόντος
          </BDropdownItem>

          <BDropdownDivider v-if="canAddProducts" />

          <BDropdownItemButton class="delete-btn" @click="handleDelete">
            Διαγραφή προϊόντος
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
            data-test="form"
          >
            <BCardBody>
              <div class="row">
                <div class="col">
                  <ValidationProvider name="skroutz_feed">
                    <BFormGroup class="mb-0" label="Skroutz Feed">
                      <BFormCheckbox v-model="form.skroutz_feed" switch />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
                <div class="col">
                  <ValidationProvider name="best_price_feed">
                    <BFormGroup class="mb-0" label="Best Price Feed">
                      <BFormCheckbox v-model="form.best_price_feed" switch />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
                <div class="col">
                  <ValidationProvider name="facebook_feed">
                    <BFormGroup class="mb-0" label="Facebook Feed">
                      <BFormCheckbox v-model="form.facebook_feed" switch />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
                <div class="col">
                  <ValidationProvider name="google_feed">
                    <BFormGroup class="mb-0" label="Google Feed">
                      <BFormCheckbox v-model="form.google_feed" switch />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
              </div>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';
import request from '@/utils/request';
import Layout from './components/ProductsEditLayout';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';

export default {
  mixins: [ProductsEditMethodsMixin],
  components: {
    Layout,
  },

  data() {
    return {
      eshopURL: process.env.VUE_APP_ESHOP_URL,
      brands: [],
      isLoading: false,
      isSaving: false,
      model: {},
      form: {
        skroutz_feed: 0,
        best_price_feed: 0,
        facebook_feed: 0,
        google_feed: 0,
      },
      breadcrumbItems: [
        {
          text: 'Αρχική',
          to: { name: 'home' },
        },
        {
          text: 'Προϊόντα',
          to: { name: 'products' },
        },
        {
          text: 'Επεξεργασία',
          to: { name: 'products.edit.general' },
        },
        {
          text: 'Feeds',
          active: true,
        },
      ],
    };
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/products/${this.$route.params.id}`);

      this.model = data.product;
      this.form = pick(this.model, [
        'skroutz_feed',
        'best_price_feed',
        'facebook_feed',
        'google_feed',
      ]);

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το προϊόν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(
          `/products/${this.$route.params.id}/feeds`,
          this.form,
        );

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του προϊόντος ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του προϊόντος δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = pick(this.model, [
        'skroutz_feed',
        'best_price_feed',
        'facebook_feed',
        'google_feed',
      ]);
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
