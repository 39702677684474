<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions v-if="canDeleteCategories || canAddProductCategories">
        <BDropdown
          size="sm"
          right
          data-test="product-actions"
          variant="primary"
        >
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <BDropdownItem
            v-if="canAddProductCategories"
            :to="{ name: 'categories.add' }"
          >
            Προσθήκη κατηγορίας
          </BDropdownItem>

          <BDropdownDivider v-if="canAddProductCategories" />

          <BDropdownItemButton
            v-if="canDeleteCategories"
            class="delete-btn"
            @click="handleDelete"
          >
            Διαγραφή κατηγορίας
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
            data-test="form"
          >
            <BCardBody>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Όνομα *">
                  <BFormInput
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Εισάγετε το όνομα της κατηγορίας"
                    data-test="title"
                    autofocus
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το όνομα της κατηγορίας
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="slug"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Slug *">
                  <BInputGroup :prepend="parentSlugPath">
                    <BFormInput
                      id="slug"
                      v-model="form.slug"
                      type="text"
                      placeholder="Εισάγετε το slug της κατηγορίας"
                      data-test="slug"
                    />
                    <BInputGroupAppend>
                      <BButton
                        variant="primary"
                        target="_blank"
                        :href="fullHrefPath"
                      >
                        <BIcon icon="chevron-right" />
                      </BButton>
                    </BInputGroupAppend>
                  </BInputGroup>

                  <BFormText
                    text-variant="danger"
                    data-test="slug-error"
                    v-if="errors[0]"
                  >
                    {{ errors[0] }}
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <BFormGroup label="Περιεχόμενο">
                <ckeditor
                  :editor="editor"
                  v-model="form.content"
                  data-test="content"
                  :config="editorConfig"
                  tag-name="textarea"
                />
              </BFormGroup>

              <ValidationProvider title="parent">
                <BFormGroup size="md" label="Μητρική κατηγορία">
                  <DynamicSelectMenuCategories
                    id="parent"
                    v-model="selectedCategory"
                    :disabled="!canEditProductCategories"
                    @select-category="handleCategorySelect"
                    data-test="parent"
                  />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="is_mega_menu_visible">
                <BFormGroup size="md" label="Ορατή στο Mega Menu">
                  <BFormCheckbox v-model="form.is_mega_menu_visible" switch />
                </BFormGroup>
              </ValidationProvider>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';
import slug from 'slug';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/CategoriesEditLayout';
import CategoriesEditMethodsMixin from './mixins/CategoriesEditMethodsMixin';
import DynamicSelectMenuCategories from '../components/DynamicSelectMenuCategories';

export default {
  components: {
    BaseButtonSubmit,
    DynamicSelectMenuCategories,
    Layout,
  },

  mixins: [EditorMixin, CategoriesEditMethodsMixin],

  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Κατηγορίες',
        to: { name: 'categories' },
      },
      {
        text: 'Επεξεργασία',
        to: { name: 'categories.edit.general' },
      },
      {
        text: 'Γενικά',
        active: true,
      },
    ],
    selectedCategory: undefined,
  }),

  computed: {
    // Full path including the current category's slug for href
    fullHrefPath() {
      let slugs = [];
      const collectSlugs = category => {
        if (category.parent) {
          collectSlugs(category.parent);
        }
        if (category.slug) {
          slugs.push(category.slug);
        }
      };

      if (this.model && this.model.slug) {
        collectSlugs(this.model);
      }

      return `${this.eshopURL}/c/${slugs.join('/')}`;
    },

    // Path up to the parent category for prepend
    parentSlugPath() {
      let slugs = [];
      const collectSlugs = category => {
        if (category.parent) {
          collectSlugs(category.parent);
        }
        if (category.slug) {
          slugs.push(category.slug);
        }
      };

      if (this.model && this.model.parent) {
        collectSlugs(this.model.parent);
      }

      return `${this.eshopURL}/c/${slugs.join('/')}`;
    },
  },

  watch: {
    'form.title': {
      handler(value, oldValue) {
        if (oldValue) {
          this.form = {
            ...this.form,
            slug: slug(value),
          };
        }
      },
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/categories/${this.$route.params.id}`,
      );

      this.model = data.category;
      if (this.model.parent) {
        this.selectedCategory = {
          ...this.model.parent,
          titles: this.model.parent_categories,
        };
      }

      this.initForm();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί ο χρήστης',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    initForm() {
      this.form = {
        ...pick(this.model, [
          'title',
          'slug',
          'is_mega_menu_visible',
          'parent_id',
        ]),
        content: this.model.content || '',
      };
    },

    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/categories/${this.$route.params.id}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία της κατηγορίας ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit('get-counters');

        await this.$router.push({ name: 'categories.all' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία της κατηγορίας δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.initForm();
      this.selectedCategory = {
        ...this.model.parent,
        titles: this.model.parent_categories,
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    handleCategorySelect(category) {
      this.selectedCategory = category;
      this.form.parent_id = category.id;
    },
  },
};
</script>
