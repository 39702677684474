<template>
  <BOverlay :show="isLoading" rounded="sm">
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <BDropdown
          size="sm"
          right
          data-test="posts-categories-actions"
          variant="primary"
        >
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <!-- <BDropdownItem :to="{ name: 'postCategories.add' }">
            Προσθήκη κατηγορίας άρθρου
          </BDropdownItem> -->

          <BDropdownDivider />

          <BDropdownItemButton class="delete-btn" @click="handleDelete">
            Διαγραφή κατηγορίας άρθρου
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
            data-test="form"
          >
            <BCardBody>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Όνομα *">
                  <BFormInput
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Εισάγετε το όνομα της κατηγορίας"
                    data-test="title"
                    autofocus
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το όνομα της κατηγορίας
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="slug"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Slug *">
                  <BInputGroup :prepend="`${eshopURL}/blog/categories/`">
                    <BFormInput
                      id="slug"
                      v-model="form.slug"
                      type="text"
                      placeholder="Εισάγετε το slug της κατηγορίας"
                      data-test="slug"
                    />
                    <BInputGroupAppend>
                      <BButton
                        variant="primary"
                        target="_blank"
                        :href="`${eshopURL}/blog/categories/${model.slug}`"
                      >
                        <BIcon icon="chevron-right" />
                      </BButton>
                    </BInputGroupAppend>
                  </BInputGroup>
                  <BFormText
                    text-variant="danger"
                    data-test="slug-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το slug της κατηγορίας
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                title="content"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Περιεχόμενο *" class="mb-0">
                  <ckeditor
                    :editor="editor"
                    v-model="form.content"
                    data-test="content"
                    :config="editorConfig"
                    tag-name="textarea"
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε περιεχόμενο για την κατηγορία
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';
import slug from 'slug';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/PostCategoriesEditLayout';
import PostCategoriesEditMethodsMixin from './mixins/PostCategoriesEditMethodsMixin';

export default {
  components: {
    BaseButtonSubmit,
    Layout,
  },

  mixins: [EditorMixin, PostCategoriesEditMethodsMixin],

  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Κατηγορίες άρθρων',
        to: { name: 'postCategories' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
  }),

  watch: {
    'form.title': {
      handler(value) {
        this.form = {
          ...this.form,
          slug: slug(value),
        };
      },
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/post-categories/${this.$route.params.id}`,
      );

      this.model = data.postCategory;
      this.initForm();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί η κατηγορία',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    initForm() {
      this.form = {
        ...pick(this.model, ['title', 'slug']),
        content: this.model.content || '',
      };
    },

    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(
          `/post-categories/${this.$route.params.id}`,
          this.form,
        );

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία της κατηγορίας ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'postCategories.list' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία της κατηγορίας δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.initForm();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
