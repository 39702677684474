
import { Vue, Component } from 'vue-property-decorator';
import { pick } from 'lodash';

import EditorMixin from '@/mixins/EditorMixin';
import request from '@/utils/request';
import { Banner } from '@/types';

@Component({
  mixins: [EditorMixin],
})
export default class BannersEdit extends Vue {
  isSaving = false;
  isLoading = false;
  model: Pick<
    Banner,
    | 'title'
    | 'title_en'
    | 'subtitle'
    | 'subtitle_en'
    | 'link'
    | 'path'
    | 'active'
  > = {
    path: '',
    title: '',
    title_en: '',
    subtitle: '',
    subtitle_en: '',
    link: '',
    active: false,
  };
  form: Pick<
    Banner,
    | 'title'
    | 'title_en'
    | 'subtitle'
    | 'subtitle_en'
    | 'link'
    | 'path'
    | 'active'
  > = {
    path: '',
    title: '',
    title_en: '',
    subtitle: '',
    subtitle_en: '',
    link: '',
    active: false,
  };
  selectedPhoto = null;
  src: string | File | ArrayBuffer | null | undefined = '';
  isImageBroken = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Banners',
      to: { name: 'content.banners.list' },
    },
    {
      text: 'Προσθήκη',
      active: true,
    },
  ];
  DOImgURL: string = process.env.VUE_APP_DO_IMAGE_CDN_URL || '';

  get photoURL() {
    return `${this.DOImgURL}${this.src}`;
  }

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/banners/${this.$route.params.id}`);

      this.model = data.banner;
      this.form = pick(this.model, [
        'path',
        'title',
        'title_en',
        'subtitle',
        'subtitle_en',
        'link',
        'active',
      ]);

      this.src = this.form.path;

      this.$nextTick(() => {
        this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το banner',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;

      const formData = new FormData();
      formData.append('title', this.form.title || '');
      formData.append('title_en', this.form.title_en || '');
      formData.append('subtitle', this.form.subtitle || '');
      formData.append('subtitle_en', this.form.subtitle_en || '');
      formData.append('link', this.form.link || '');
      formData.append('active', this.form.active.toString());
      formData.append('image', this.form.path);

      await request.post(`/banners/${this.$route.params.id}`, formData);

      this.$swal({
        title: 'Επιτυχία',
        text: 'To banner προστέθηκε',
        icon: 'success',
        timer: 3000,
      });

      await this.$router.push({ name: 'content.banners.list' });
    } catch (err) {
      let text = 'Αδυναμία προσθήκης Banner';
      const { status, data } = (err as any).response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      if (status === 413) {
        text =
          'Το όριο μεγέθους για τις εικόνες είναι 1.5 MB. Μειώστε το μέγεθος του αρχείου και δοκιμάστε ξανά';
      }

      this.$swal({
        title: 'Σφάλμα',
        text,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleFileChange(e: Event) {
    const files =
      (e.target as HTMLInputElement)?.files ||
      (e as DragEvent)?.dataTransfer?.files;

    if (!files?.length) {
      return;
    }

    this.form.path = files[0];
    this.readImage();
  }

  readImage() {
    const reader = new FileReader();
    reader.onload = e => {
      this.src = e?.target?.result;
    };
    reader.readAsDataURL(this.form.path as File);
  }

  handleDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: 'Θέλετε να διαγράψετε αυτό το Banner;',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/banners/${this.$route.params.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: 'Το Banner διαγράφηκε επιτυχώς',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          await this.$router.push({ name: 'content.banners.list' });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: 'Το Banner δεν διεγράφη',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleBrokenImage() {
    this.isImageBroken = true;
  }

  handleReset() {
    this.form = pick(this.model, [
      'path',
      'title',
      'title_en',
      'subtitle',
      'subtitle_en',
      'link',
      'active',
    ]);
    this.src = pick(this.model, ['path']).path;
    this.$nextTick(() => {
      this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
