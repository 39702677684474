<template>
  <Layout :breadcrumb-items="breadcrumbItems">
    <BCard no-body>
      <BCardBody>
        <BCard
          v-for="(service, i) in organization.services"
          :key="i"
          no-body
          class="mb-3"
        >
          <BCardHeader>
            <BCardTitle class="d-flex flex-grow-1 align-items-center mb-0">
              {{ i }}
            </BCardTitle>

            <small v-if="!service.enabled" class="mt-1">
              Η υπηρεσία αυτή δεν είναι ενεργοποιημένη για τον οργανισμό σας
            </small>
          </BCardHeader>
          <BCardBody v-if="service.services">
            <div v-for="(section, k) in service.services" :key="k" class="mb-4">
              <h5 class="mb-1">{{ section.title }}</h5>

              <div class="d-flex">
                <div class="d-flex flex-grow-1 align-items-center">
                  <label class="mr-2 mb-0">Προβολή</label>
                  <BFormCheckbox
                    :disabled="true"
                    :checked="section.active"
                    switch
                  />
                </div>
              </div>
            </div>
          </BCardBody>
        </BCard>
      </BCardBody>
    </BCard>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Layout from './components/OrganizationLayout';

export default {
  components: {
    Layout,
  },

  data: () => ({
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Οργανισμός',
        to: { name: 'organization' },
      },
      {
        text: 'Υπηρεσίες',
        active: true,
      },
    ],
  }),

  async created() {
    await this.getOrganization();
  },

  computed: {
    ...mapGetters({
      organization: 'organization/getOrganization',
    }),
  },

  methods: {
    ...mapActions({
      getOrganization: 'organization/getOrganization',
    }),
  },
};
</script>
