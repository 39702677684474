<template>
  <RouterView
    :counters="counters"
    :isCountersLoaded="isCountersLoaded"
    @get-counters="handleCounters"
  />
</template>

<script>
import request from '@/utils/request';

export default {
  data: () => ({
    counters: {
      all: 0,
      cancelled: 0,
      pending: 0,
      sent: 0,
      unfinished: 0,
      withdrew: 0,
    },
    isCountersLoaded: false,
  }),

  methods: {
    async handleCounters() {
      try {
        const { data } = await request.get('/orders/counters');

        this.counters = {
          all: data.counters.all,
          pending: data.counters.pending,
          sent: data.counters.sent,
          cancelled: data.counters.cancelled,
          withdrew: data.counters.withdrew,
          unfinished: data.counters.unfinished,
        };

        this.isCountersLoaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
