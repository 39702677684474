<template>
  <RouterView
    :counters="counters"
    @get-counters="handleCounters"
    :isCountersLoaded="isCountersLoaded"
  />
</template>

<script>
import request from '@/utils/request';

export default {
  data: () => ({
    counters: {
      all: 0,
      active: 0,
      inactive: 0,
    },
    isCountersLoaded: false,
  }),

  async created() {
    await this.handleCounters();
  },

  methods: {
    async handleCounters() {
      try {
        const { data } = await request.get('/categories/counters');

        this.counters = data.counters;
        this.isCountersLoaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
