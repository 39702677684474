
import { Component, Prop, Vue } from 'vue-property-decorator';
import PaginationMixin from '@/mixins/PaginationMixin';

import { Banner } from '@/types';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';

@Component({
  mixins: [PaginationMixin],
  components: {
    ListThreeDotsMenu,
  },
})
export default class BannerItem extends Vue {
  @Prop({ type: Object, required: false }) readonly item!: Banner;

  isImageBroken = false;

  get isDeleted() {
    return this.item.deleted_at !== null;
  }

  handleDelete() {
    this.$emit('delete-item', this.item);
  }

  handleBrokenImage() {
    this.isImageBroken = true;
  }

  handleChangeActive() {
    this.$emit('change-active', this.item);
  }
}
