
import { Component, Prop, Vue } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';

import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import PromosMethodsMixin from './mixins/PromosMethodsMixin';
import Layout from './components/PromosLayout.vue';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';
import { Promo } from '@/types';

@Component({
  mixins: [QueryParamsPaginationMixin, PromosMethodsMixin],
  components: {
    Layout,
    ListPagination,
    ListThreeDotsMenu,
    ListActionsBar,
  },
})
export default class PromosAll extends Vue {
  @Prop({ type: Object, required: false }) readonly counters!: Object;
  @Prop({ type: Boolean, required: false }) readonly isCountersLoaded!: boolean;

  sortBy = 'title';
  sortDesc = false;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Όνομα', key: 'title', sortable: true },
    { label: 'Ενεργό', key: 'active' },
    { label: 'Crawlable', key: 'crawlable' },
    { label: '', key: 'actions', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Promos',
      active: true,
    },
  ];
  filters = [
    {
      label: 'Ενεργό',
      apiName: 'active',
      type: 'boolean',
      value: false,
    },
  ];
  isLoading = false;
  total = 0;
  items: Array<Promo> = [];
  searchTerm = '';

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async promosProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/promos', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.promos.total;
      this.items = data.promos.data;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα promo pages',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
