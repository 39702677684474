<template>
  <BOverlay :show="true" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex border-top">
      <div class="tab-header">
        Νέα Μάρκα
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm
          @submit.prevent="handleSubmit(handleForm)"
          novalidate
          data-test="form"
        >
          <BCardBody>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Όνομα *">
                <BFormInput
                  id="title"
                  v-model="form.title"
                  type="text"
                  placeholder="Εισάγετε το όνομα του brand"
                  data-test="title"
                  autofocus
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε το όνομα του brand
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              name="slug"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup size="md" label="Slug *">
                <BInputGroup :prepend="`${eshopURL}/brands/`">
                  <BFormInput
                    id="slug"
                    v-model="form.slug"
                    type="text"
                    placeholder="Εισάγετε το slug του brand"
                    data-test="slug"
                  />
                </BInputGroup>

                <BFormText
                  text-variant="danger"
                  data-test="slug-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε το slug του brand
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              title="content"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Περιεχόμενο *" class="mb-0">
                <ckeditor
                  :editor="editor"
                  v-model="form.content"
                  data-test="content"
                  :config="editorConfig"
                  tag-name="textarea"
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε περιεχόμενο για το brand
                </BFormText>
              </BFormGroup>
            </ValidationProvider>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
    <template #overlay>
      <div class="text-center">
        <h3 id="cancel-label">CommerceX premium or out of scope feature</h3>
      </div>
    </template>
  </BOverlay>
</template>

<script>
import slug from 'slug';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';

export default {
  mixins: [EditorMixin],

  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
    isSaving: false,
    form: {
      title: '',
      slug: '',
      active: false,
    },
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Μάρκες',
        to: { name: 'brands' },
      },
      {
        text: 'Προσθήκη',
        active: true,
      },
    ],
  }),

  watch: {
    'form.title': {
      handler(value) {
        this.form = {
          ...this.form,
          slug: slug(value),
        };
      },
    },
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/brands', this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Η μάρκα προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit('get-counters');

        await this.$router.push({ name: 'brands.all' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης brand',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        title: '',
        slug: '',
        content: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
