import qs from 'qs';
import request from '@/utils/request';

export default {
  methods: {
    handleDelete(item) {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε την ομάδα φίλτρων: ${item.title};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/filterGroups/${item.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Η ομάδα φίλτρων ${item.title} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Η ομάδα φίλτρων ${item.title} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε ${
          this.selectedIds.length === 1
            ? 'η επιλεγμένη ομάδα φίλτρων;'
            : `οι επιλεγμένες ${this.selectedIds.length} ομάδες φίλτρων;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete('/filterGroups', {
              params: {
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            }),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'Η ομάδα φίλτρων διαγράφηκε επιτυχώς'
                    : `${this.selectedIds.length} ομάδες φίλτρων διαγράφηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
                showConfirmButton: false,
              });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Η ομάδα φίλτρων δεν διεγράφη'
                  : 'Οι ομάδες φίλτρων δεν διεγράφησαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassActivate() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να ενεργοποιήσετε ${
          this.selectedIds.length === 1
            ? 'την επιλεγμένη ομάδα φίλτρων;'
            : `τις επιλεγμένες ${this.selectedIds.length} ομάδες φίλτρων;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.patch(
              '/filterGroups',
              {},
              {
                params: {
                  active: true,
                  ids: this.selectedIds,
                },
                paramsSerializer: params => {
                  return qs.stringify(params, {
                    arrayFormat: 'comma',
                    encode: false,
                  });
                },
              },
            ),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'H ομάδα φίλτρων ενεργοποιήθηκε επιτυχώς'
                    : `${this.selectedIds.length} ομάδες φίλτρων ενεργοποιήθηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
              });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'H ομάδα φίλτρων δεν ενεργοποιήθηκε'
                  : 'Οι ομάδες φίλτρων δεν ενεργοποιήθηκαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassDeactivate() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να απενεργοποιήσετε ${
          this.selectedIds.length === 1
            ? 'την επιλεγμένη ομάδα φίλτρων;'
            : `τις επιλεγμένες ${this.selectedIds.length} ομάδες φίλτρων;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.patch(
              '/filterGroups',
              {},
              {
                params: {
                  active: false,
                  ids: this.selectedIds,
                },
                paramsSerializer: params => {
                  return qs.stringify(params, {
                    arrayFormat: 'comma',
                    encode: false,
                  });
                },
              },
            ),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'Η ομάδα φίλτρων απενεργοποιήθηκε επιτυχώς'
                    : `${this.selectedIds.length} ομάδες φίλτρων απενεργοποιήθηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
              });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Η ομάδα φίλτρων δεν απενεργοποιήθηκε'
                  : 'Οι ομάδες φίλτρων δεν απενεργοποιήθηκαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    async handleToggleActive(item) {
      try {
        await request.patch(`/filterGroups/${item.id}/active`);

        this.$swal({
          title: 'Επιτυχία',
          text: `Η ομάδα φίλτρων ${
            item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
          }!`,
          icon: 'success',
          timer: 3000,
        });

        this.$emit('get-counters');
        this.$nextTick(() => {
          this.$refs.table.refresh();
        });
      } catch (error) {
        this.$swal({
          title: 'Σφάλμα',
          text: `Αδυναμία ${
            item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
          } ομάδας φίλτρων`,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
  },
};
