<template>
  <Layout :breadcrumb-items="breadcrumbItems">
    <template #nav>
      <div class="d-flex justify-content-between align-items-center border-top">
        <div class="tab-header">
          {{ isLoading ? 'Λήψη Δεδομένων...' : model.name }}
        </div>
        <BDropdown
          size="sm"
          right
          data-test="posts-categories-actions"
          variant="primary"
        >
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <BDropdownItem :to="{ name: 'organization.users.add' }">
            Προσθήκη χρήστη
          </BDropdownItem>

          <BDropdownDivider />

          <BDropdownItemButton
            v-if="canDelete"
            class="delete-btn"
            @click="handleDelete"
          >
            Διαγραφή χρήστη
          </BDropdownItemButton>
        </BDropdown>
      </div>
    </template>

    <BOverlay :show="isLoading" no-fade>
      <BCard no-body class="main-card no-border-top-left">
        <BCardBody>
          <BRow class="mt-5">
            <BCol md="3" class="mb-3">
              <h5 class="mb-3">Λεπτομέρειες χρήστη</h5>
              <BCardText class="font-weight-light">
                Επεξεργαστείτε τα στοιχεία του χρήστη και αποθηκεύστε για να
                εφαρμοστούν οι αλλαγές.
              </BCardText>
              <BCardText class="font-weight-light">
                <BIcon
                  icon="patch-exclamation"
                  class="mr-1 text-warning"
                />Προσοχή η διαγραφή γίνεται οριστικά και ο χρήστης δεν θα έχει
                πρόσβαση στο διαχειριστικό σας περιβάλλον παρά μόνο αν τον
                δημιουργήσετε εκ νέου.
              </BCardText>
            </BCol>
            <BCol class="form-wrapper mb-4">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <BForm
                  @submit.prevent="handleSubmit(handleForm)"
                  data-test="user-detail-form"
                  novalidate
                >
                  <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <BFormGroup size="md" label="Όνομα *">
                      <BInputGroup size="md">
                        <BInputGroupPrepend>
                          <BInputGroupText>
                            <BIcon icon="person" />
                          </BInputGroupText>
                        </BInputGroupPrepend>
                        <BFormInput
                          id="name"
                          v-model="form.name"
                          type="text"
                          placeholder="Εισάγετε όνομα"
                          data-test="name"
                          autofocus
                        />
                      </BInputGroup>

                      <BFormText
                        text-variant="danger"
                        data-test="name-error"
                        v-if="errors[0]"
                      >
                        Παρακαλώ εισάγετε το όνομα χρήστη
                      </BFormText>
                    </BFormGroup>
                  </ValidationProvider>

                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ errors, failedRules }"
                  >
                    <BFormGroup size="md" label="Email *">
                      <BInputGroup size="md">
                        <BInputGroupPrepend>
                          <BInputGroupText>
                            <BIcon icon="envelope" />
                          </BInputGroupText>
                        </BInputGroupPrepend>
                        <BFormInput
                          id="email"
                          v-model="form.email"
                          type="email"
                          placeholder="Εισάγετε email"
                          data-test="email"
                        />
                      </BInputGroup>
                      <BFormText
                        text-variant="danger"
                        data-test="email-error"
                        v-if="failedRules.required"
                      >
                        Παρακαλώ εισάγετε το email του χρήστη
                      </BFormText>
                      <BFormText
                        text-variant="danger"
                        data-test="email-error"
                        v-else-if="failedRules.email"
                      >
                        Παρακαλώ εισάγετε ενα έγκυρο email
                      </BFormText>
                      <BFormText
                        text-variant="danger"
                        data-test="email-error"
                        v-else-if="errors[0]"
                      >
                        {{ errors[0] }}
                      </BFormText>
                    </BFormGroup>
                  </ValidationProvider>

                  <ValidationProvider name="active" rules="required">
                    <BFormGroup size="md" label="Ενεργός" class="mb-5">
                      <BFormCheckbox v-model="form.active" switch />
                    </BFormGroup>
                  </ValidationProvider>

                  <BRow class="justify-content-end">
                    <BCol>
                      <BButton
                        class="mb-3"
                        @click="handleReset"
                        block
                        data-test="reset-button"
                      >
                        <BIcon icon="arrow-counterclockwise" />
                        Επαναφορά
                      </BButton>

                      <BaseButtonSubmit
                        variant="primary"
                        icon="person-plus-fill"
                        :block="true"
                        :isSaving="isSaving"
                        title="Αποθήκευση"
                      />
                    </BCol>
                  </BRow>
                </BForm>
              </ValidationObserver>
            </BCol>
          </BRow>
          <hr />
          <BRow class="justify-content-start">
            <BCol md="3" class="mb-3 mt-5">
              <h5 class="mb-3">Αλλαγή Κωδικού</h5>
              <BCardText class="font-weight-light">
                Σας συνιστούμε μία φορά το 6μηνο να αλλάζετε τον κωδικό σας.
              </BCardText>
              <BCardText class="font-weight-light">
                <BIcon icon="patch-exclamation" class="mr-1 text-warning" />Ο
                κωδικός σας πρέπει να έχει τουλάχιστον 8 ψηφία τα οποία μπορεί
                να είναι γράμματα κεφαλαία ή πεζά, σύμβολα και αριθμοί
              </BCardText>
            </BCol>
            <BCol class="mt-5 form-wrapper">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <BForm
                  @submit.prevent="handleSubmit(handlePasswordUpdate)"
                  novalidate
                >
                  <ValidationProvider
                    name="oldPassword"
                    rules="required|min:8"
                    v-slot="{ errors, failedRules }"
                  >
                    <BFormGroup
                      size="md"
                      label="Τρέχον Κωδικός *"
                      label-for="oldPassword"
                    >
                      <BInputGroup size="md">
                        <BInputGroupPrepend>
                          <BInputGroupText>
                            <BIcon icon="lock" />
                          </BInputGroupText>
                        </BInputGroupPrepend>
                        <BFormInput
                          id="oldPassword"
                          v-model="form.oldPassword"
                          type="password"
                          placeholder="Εισάγετε τον τρέχον κωδικό"
                          autocomplete="new-password"
                        />
                      </BInputGroup>
                      <BFormText v-if="failedRules.min" text-variant="danger">
                        O κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες
                      </BFormText>
                      <BFormText v-else-if="errors[0]" text-variant="danger">
                        Παρακαλώ εισάγετε τoν τρέχον κωδικό σας
                      </BFormText>
                    </BFormGroup>
                  </ValidationProvider>
                  <ValidationProvider
                    name="new_password"
                    rules="required|min:8"
                    v-slot="{ errors, failedRules }"
                  >
                    <BFormGroup
                      size="md"
                      label="Νέος Κωδικός *"
                      label-for="newPassword"
                    >
                      <BInputGroup size="md">
                        <BInputGroupPrepend>
                          <BInputGroupText>
                            <BIcon icon="lock" />
                          </BInputGroupText>
                        </BInputGroupPrepend>
                        <BFormInput
                          id="newPassword"
                          v-model="form.newPassword"
                          type="password"
                          placeholder="Εισάγετε τον νέο κωδικό"
                          autocomplete="new-password"
                        />
                      </BInputGroup>
                      <BFormText v-if="failedRules.min" text-variant="danger">
                        O κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες
                      </BFormText>
                      <BFormText v-else-if="errors[0]" text-variant="danger">
                        Παρακαλώ εισάγετε τoν νέο κωδικό σας
                      </BFormText>
                    </BFormGroup>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, failedRules }"
                    name="new_password_confirmation"
                    rules="required|min:8|confirmed:new_password"
                  >
                    <BFormGroup
                      size="md"
                      label="Επιβεβαίωση Κωδικού *"
                      label-for="newPasswordConfirmation"
                    >
                      <BInputGroup size="md">
                        <BInputGroupPrepend>
                          <BInputGroupText>
                            <BIcon icon="lock" />
                          </BInputGroupText>
                        </BInputGroupPrepend>
                        <BFormInput
                          id="newPasswordConfirmation"
                          v-model="form.passwordConfirmation"
                          type="password"
                          placeholder="Επιβεβαιώστε τον νέο κωδικό"
                          autocomplete="new-password"
                        />
                      </BInputGroup>
                      <BFormText v-if="failedRules.min" text-variant="danger">
                        O κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες
                      </BFormText>
                      <BFormText
                        v-else-if="failedRules.confirmed"
                        text-variant="danger"
                      >
                        Οι κωδικοί δεν ταιριάζουν
                      </BFormText>
                      <BFormText v-else-if="errors[0]" text-variant="danger">
                        Παρακαλώ επαληθεύστε τoν κωδικό σας
                      </BFormText>
                    </BFormGroup>
                  </ValidationProvider>

                  <BFormText
                    text-variant="danger"
                    class="text-center mb-2"
                    v-if="oldPasswordError"
                  >
                    {{ oldPasswordError }}
                  </BFormText>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="key-fill"
                    :block="true"
                    :isSaving="isSaving"
                    title="Αλλαγή Κωδικού"
                    :class="['mb-4', { 'pointer-events-none': isSaving }]"
                  />
                </BForm>
              </ValidationObserver>
            </BCol>
          </BRow>
        </BCardBody>
      </BCard>
    </BOverlay>
  </Layout>
</template>

<script>
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import Layout from '../components/OrganizationLayout';
import { mapGetters } from 'vuex';

export default {
  components: {
    Layout,
    BaseButtonSubmit,
  },

  data: () => ({
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    oldPasswordError: '',
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Οργανισμός',
        to: { name: 'organization' },
      },
      {
        text: 'Χρήστες',
        to: { name: 'organization.users.list' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
  }),

  computed: {
    ...mapGetters({
      user: 'session/getUser',
    }),

    canDelete() {
      return this.user.id !== this.model.id;
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/users/${this.$route.params.id}`);

      this.model = data.user;
      this.form = pick(this.model, ['name', 'email', 'active']);

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί ο χρήστης',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        this.oldPasswordError = '';
        await request.put(`/users/${this.$route.params.id}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του χρήστη ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του χρήστη δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },
    async handlePasswordUpdate() {
      try {
        await request.put('/process-update-password', {
          old_password: this.form.oldPassword,
          new_password: this.form.newPassword,
          new_password_confirmation: this.form.passwordConfirmation,
          id: this.$route.params.id,
        });

        this.$swal({
          title: 'Επιτυχία',
          text: 'Ο νέος κωδικός πρόσβασης αποθηκεύτηκε επιτυχώς!',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.handleReset();
      } catch (error) {
        if (error.response.data.messages.old_password[0]) {
          this.oldPasswordError = error.response.data.messages.old_password[0];
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Η ανανέωση του κωδικού απέτυχε.',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },

    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το χρήστη: ${this.model.name};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/users/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Ο χρήστης ${this.model.name} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            await this.$router.push({ name: 'users.list' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Ο χρήστης ${this.model.name} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleReset() {
      this.form = pick(this.model, ['name', 'email', 'active']);
      this.oldPasswordError = '';
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>

<style scoped>
.input-width {
  width: 50%;
}
</style>
