
import { Component, Vue, Watch } from 'vue-property-decorator';
import { pick } from 'lodash';
import request from '@/utils/request';

import Layout from './components/OrganizationLayout.vue';
import { SendMethod } from '@/types';

type FormType = {
  name?: string;
  logo?: string | null;
};

@Component({
  components: {
    Layout,
  },
})
export default class OrganizationGeneral extends Vue {
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Οργανισμός',
      to: { name: 'organization' },
    },
    {
      text: 'Γενικά Στοιχεία',
      active: true,
    },
  ];

  form: FormType = {};
  model: FormType = {};
  isLoading = false;
  logoPreviewURL: string | null | undefined = null;

  @Watch('model.logo', { immediate: true })
  handleModelLogoChange(newVal: string) {
    if (newVal) {
      this.logoPreviewURL = newVal;
    }
  }

  @Watch('form.logo', { immediate: true })
  handleLogoChange(newVal: SendMethod[]) {
    if (newVal) {
      this.logoPreviewURL = URL.createObjectURL(newVal);
    }
  }

  async getData() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/organization/`);
      this.model = data.data;

      this.form = pick(this.model, ['name']);

      this.$nextTick(() => {
        this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθούν τα στοιχεία',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  created() {
    this.getData();
  }

  async handleForm() {
    try {
      this.isLoading = true;
      const formData = new FormData();

      formData.append('name', this.form.name ?? '');
      if (this.form.logo) {
        formData.append('logo', this.form.logo);
      }

      await request.post(`/organization`, formData);

      await this.getData();

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα στοιχεία του οργανισμού ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα στοιχεία του οργανισμού δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleResetLogo() {
    this.logoPreviewURL = null;
    this.form = {
      ...this.form,
      logo: null,
    };
  }

  handleReset() {
    this.form = pick(this.model, ['name']);
    this.logoPreviewURL = this.model.logo;
    this.$nextTick(() => {
      this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
