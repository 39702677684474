<template>
  <div>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex border-top">
      <div class="tab-header">
        Νέος Πελάτης
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
          <BCardBody>
            <ValidationProvider
              name="firstName"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup size="md" label="Όνομα *">
                <BFormInput
                  id="firstName"
                  v-model="form.firstName"
                  type="text"
                  placeholder="Εισάγετε όνομα"
                  data-test="firstName"
                  autofocus
                />
                <BFormText
                  text-variant="danger"
                  data-test="name-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε το όνομα του πελάτη
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              name="lastName"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup size="md" label="Επώνυμο *">
                <BFormInput
                  id="lastName"
                  v-model="form.lastName"
                  type="text"
                  placeholder="Εισάγετε επώνυμο"
                  data-test="lastName"
                />

                <BFormText
                  text-variant="danger"
                  data-test="name-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε το επώνυμο του πελάτη
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors, failedRules }"
            >
              <BFormGroup size="md" label="Email *">
                <BFormInput
                  id="email"
                  v-model="form.email"
                  type="email"
                  placeholder="Εισάγετε email"
                  data-test="email"
                />
                <BFormText
                  text-variant="danger"
                  data-test="email-error"
                  v-if="failedRules.required"
                >
                  Παρακαλώ εισάγετε το email του πελάτη
                </BFormText>
                <BFormText
                  text-variant="danger"
                  data-test="email-error"
                  v-else-if="failedRules.email"
                >
                  Παρακαλώ εισάγετε ενα έγκυρο email
                </BFormText>
                <BFormText
                  text-variant="danger"
                  data-test="email-error"
                  v-else-if="errors[0]"
                >
                  {{ errors[0] }}
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, failedRules }"
              name="mobile"
              rules="required"
            >
              <BFormGroup size="md" label="Κινητό *">
                <VueTelInput
                  v-model="form.mobile"
                  placeholder="Εισάγετε κινητό"
                  :input-options="{
                    id: 'mobile',
                  }"
                  valid-characters-only
                  defaultCountry="gr"
                  :onlyCountries="['gr']"
                  :autoDefaultCountry="false"
                />
                <BFormText
                  v-if="failedRules.required"
                  class="d-block"
                  text-variant="danger"
                >
                  Εισάγετε κινητό
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <BFormGroup size="md" label="Τηλέφωνο">
              <VueTelInput
                v-model="form.telephone"
                placeholder="Εισάγετε τηλέφωνο"
                :input-options="{
                  id: 'telephone',
                }"
                valid-characters-only
                defaultCountry="gr"
                :onlyCountries="['gr']"
                :autoDefaultCountry="false"
              />
            </BFormGroup>

            <BFormGroup size="md" label="Φύλο">
              <BFormSelect v-model="form.gender" :options="options" />
            </BFormGroup>

            <BFormGroup size="md" label="Ημ. Γέννησης" class="mb-5">
              <BFormDatepicker
                id="birthday"
                v-model="form.birthday"
                locale="el"
                :start-weekday="1"
                :value-as-date="false"
                show-decade-nav
                placeholder="Επιλέξτε ημ/νία"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </BFormGroup>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />
                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </div>
</template>

<script>
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
  },

  data: () => ({
    form: {
      email: '',
      firstName: '',
      lastName: '',
      mobile: '',
      telephone: '',
      gender: null,
      birthday: '',
    },
    isSaving: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Πελάτες',
        to: { name: 'customers' },
      },
      {
        text: 'Προσθήκη',
        active: true,
      },
    ],
    options: [
      { value: null, text: 'Επιλέξτε φύλο' },
      { value: 'female', text: 'Γυναίκα' },
      { value: 'male', text: 'Άνδρας' },
      { value: 'other', text: 'Άλλο' },
    ],
  }),

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/customers', this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Ο Πελάτης Προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'customers.all' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία Προσθήκης Πελάτη',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        telephone: '',
        gender: '',
        birthday: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
