import Vue from 'vue';
import Vuex from 'vuex';

import plugins from './plugins';
import session from './session';
import organization from './organization';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    session,
    organization,
  },
  plugins,
});
