
import { Component, Vue } from 'vue-property-decorator';
import { debounce, flow, get, sortBy, uniq } from 'lodash';

import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/ProductsEditLayout.vue';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';
import MultiSelectTag from '@/components/MultiSelectTag.vue';
import { FilterGroup, Product, FilterGroupFilter, Loading } from '@/types';

@Component({
  components: {
    Layout,
    MultiSelectTag,
  },
  mixins: [EditorMixin, ProductsEditMethodsMixin],
})
export default class ProductsEditFilters extends Vue {
  isLoading = false;
  isSaving = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Προϊόντα',
      to: { name: 'products' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'products.edit.general' },
    },
    {
      text: 'Φίλτρα',
      active: true,
    },
  ];
  filters: FilterGroupFilter[] = [];
  filterGroups: Array<
    FilterGroup & {
      filters: Array<FilterGroupFilter>;
      selections: Array<FilterGroupFilter> | [];
    }
  > = [];
  allFilterGroups: Array<
    FilterGroup & { filters: Array<FilterGroupFilter> }
  > = [];
  model: Product | {} = {};

  async getFilters() {
    try {
      this.isLoading = true;
      const [
        filtersResponse,
        filterGroupsResponse,
        productResponse,
      ] = await Promise.all([
        request.get(`/products/${this.$route.params.id}/filters`, {
          params: { paginated: false },
        }),
        request.get(`/filterGroups`, { params: { paginated: false } }),
        request.get(`/products/${this.$route.params.id}`),
      ]);

      this.filters = filtersResponse.data.filters;
      this.allFilterGroups = filterGroupsResponse.data.filterGroups;
      this.model = productResponse.data.product;

      const filterGroupsIds = this.filters.map(
        filter => filter.filter_group_id,
      );
      const filtersIds = this.filters.map(filter => filter.id);

      this.filterGroups = this.allFilterGroups.reduce(
        (
          acc: Array<
            FilterGroup & {
              selections: Array<FilterGroupFilter>;
              filters: Array<FilterGroupFilter>;
            }
          >,
          filterGroup: FilterGroup & { filters: Array<FilterGroupFilter> },
        ) => {
          if (filterGroupsIds.includes(filterGroup.id)) {
            return [
              ...acc,
              {
                ...filterGroup,
                selections: filterGroup.filters.filter(filter =>
                  filtersIds.includes(filter.id),
                ),
              },
            ];
          }

          return acc;
        },
        [],
      );
    } catch (err) {
      console.log(err);
      // this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα φίλτρα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    try {
      await this.getFilters();
    } catch (err) {
      console.log(err);
    }
  }

  async handleSave() {
    try {
      this.isSaving = true;
      const filters = this.filterGroups.reduce(
        (acc, filterGroup: FilterGroup) => {
          const ids = get(filterGroup, 'selections', []).map(
            ({ id }: { id: FilterGroupFilter['id'] }) => id,
          );

          return flow(uniq, sortBy)([...acc, ...ids]);
        },
        [],
      );

      await request.put(`/products/${this.$route.params.id}/filters`, {
        filters,
      });

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα φίλτρα του προϊόντος ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      await this.getFilters();
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να αποθηκευτούν τα φίλτρα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  selectFilterGroup(
    filterGroups: Array<FilterGroup & { filters: Array<FilterGroupFilter> }>,
  ) {
    const existingIDs = this.filterGroups.map(({ id }) => id);

    this.filterGroups = [
      ...this.filterGroups,
      ...filterGroups
        .filter(filterGroup => !existingIDs.includes(filterGroup.id))
        .map(filterGroup => ({
          ...filterGroup,
          selections: [],
        })),
    ];
  }

  removeFilterGroup(
    filterGroup: FilterGroup & { filters: Array<FilterGroupFilter> },
  ) {
    this.filterGroups = this.filterGroups.filter(
      ({ id }) => id !== filterGroup.id,
    );
  }

  selectFilter(id: FilterGroup['id'], filters: Array<FilterGroupFilter>) {
    this.filterGroups = this.filterGroups.map(filterGroup =>
      filterGroup.id === id
        ? {
            ...filterGroup,
            selections: filters,
          }
        : filterGroup,
    );
  }

  removeFilter(id: FilterGroup['id'], filter: FilterGroupFilter) {
    console.log('removeFilter', id, filter);
    this.filterGroups = this.filterGroups.map(filterGroup =>
      filterGroup.id === id
        ? {
            ...filterGroup,
            selections: filterGroup.selections.filter(
              ({ id }) => id !== filter.id,
            ),
          }
        : filterGroup,
    );
  }

  handleSearchFilterGroup = debounce(this.onSearchFilterGroup, 500);
  handleSearchFilter = debounce(this.onSearchFilter, 500);

  async onSearchFilterGroup(search: string, loading: Loading) {
    if (search) {
      try {
        loading(true);

        const { data } = await request.get('/filterGroups', {
          params: {
            paginated: false,
            filter: search,
          },
        });

        this.allFilterGroups = data.filterGroups;
      } catch (err) {
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεν μπόρεσαν να βρεθούν αποτελέσματα',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        loading(false);
      }
    }
  }

  async onSearchFilter(search: string, loading: Loading) {
    if (search) {
      try {
        loading(true);

        const { data } = await request.get('/filters', {
          params: {
            paginated: false,
            filter: search,
          },
        });

        this.allFilterGroups = data.filterGroups;
      } catch (err) {
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεν μπόρεσαν να βρεθούν αποτελέσματα',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        loading(false);
      }
    }
  }

  handleInput(value: FilterGroup & { filters: Array<FilterGroupFilter> }) {
    console.log('handleInput');
    console.log(value);
    this.allFilterGroups = [];
    // this.$emit('select-customer', value);
  }

  handleInputFilter(value: FilterGroupFilter) {
    console.log('handleInputFilter');
    console.log(value);
  }

  handleReset() {}
}
