<template>
  <ExistingItems :items="items" title="Φίλτρα Ομάδας">
    <template v-slot:item="props" v-if="items.length">
      <RouterLink
        :to="{
          name: 'filterGroup.filters.edit',
          params: {
            id: props.item.filter_group_id,
            filter: props.item.id,
          },
        }"
      >
        {{ props.item.title }}
      </RouterLink>
    </template>

    <p v-else class="mb-0">Δε βρέθηκαν αποτελέσματα</p>
  </ExistingItems>
</template>

<script>
import ExistingItems from '../../../components/ExistingItems/index.vue';

export default {
  components: {
    ExistingItems,
  },
  props: {
    items: Array,
    filterGroup: Object,
  },
};
</script>
