
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

type Item = {
  id: string;
  deleted_at: string | null;
};

@Component
export default class ListThreeDotsMenu extends Vue {
  @Prop({ type: Object, required: false }) readonly item!: Item;
  @Prop({ type: String, required: true }) readonly routeName!: string;
  @Prop({ type: Boolean, default: true }) readonly canDelete!: boolean;
  @Prop({ type: Boolean, default: false }) readonly canCancel!: boolean;
  @Prop({ type: Boolean, default: false }) readonly canRestore!: boolean;

  get isDeleted() {
    return this.item.deleted_at !== null;
  }

  @Emit('on-delete')
  handleDelete() {
    return this.item;
  }

  @Emit('on-cancel')
  handleCancel() {
    return this.item;
  }

  @Emit('on-restore')
  handleRestore() {
    return this.item;
  }
}
