import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { VueTelInput } from 'vue-tel-input';
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMoment from 'vue-moment';
import Gravatar from 'vue-gravatar';
import isURL from 'validator/lib/isURL';
import isJSON from 'validator/lib/isJSON';
import qs from 'qs';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import {
  required,
  email,
  confirmed,
  min,
  min_value,
  regex,
  mimes,
  integer,
} from 'vee-validate/dist/rules';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueCodemirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript.js';
import VueClipboard from 'vue-clipboard2';
import Vue2Filters from 'vue2-filters';
import moment from 'moment';

extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('min', min);
extend('min_value', min_value);
extend('regex', regex);
extend('mimes', mimes);
extend('integer', integer);
extend('url', {
  validate: value => !(value && !isURL(value)),
});

extend('json', {
  validate: value => !(value && !isJSON(value)),
});

const sweetOptions = {
  confirmButtonColor: '#16c995',
  cancelButtonColor: '#f74f78',
};

const Vue2FiltersConfig = {
  currency: {
    symbol: '',
    decimalDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false,
  },
};

import store from './store';
import router from './router';
import BaseButtonSubmit from './components/BaseButtonSubmit.vue';

Vue.use(VueTelInput);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2, sweetOptions);
Vue.use(VueMoment);
Vue.component('v-gravatar', Gravatar);
Vue.component('v-select', vSelect);
Vue.use(CKEditor);
Vue.use(VueCodemirror, {
  options: {
    tabSize: 4,
    mode: {
      name: 'javascript',
      json: true,
    },
    lineNumbers: true,
    line: true,
  },
});
Vue.use(VueClipboard);
Vue.use(Vue2Filters, Vue2FiltersConfig);

Vue.prototype.$qs = qs;

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VueTelInput', VueTelInput);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VueTelInput', VueTelInput);
Vue.component('BaseButtonSubmit', BaseButtonSubmit);

Vue.config.productionTip = false;

Vue.filter('date', function(value: string, format = 'DD-MM-YYYY') {
  return moment(value).format(format);
});

Vue.filter('number', function(value: number) {
  return new Intl.NumberFormat('de-DE').format(value);
});

Vue.filter('money', function(value: number) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  async created() {
    try {
      await store.dispatch('session/getUser');
    } catch (err) {
      console.log(err);
    }
  },
}).$mount('#app');
