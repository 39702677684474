
import { Component, Vue } from 'vue-property-decorator';
import VJstree from 'vue-jstree';
import { cloneDeep } from 'lodash';

import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/ProductsEditLayout.vue';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';
import { Category, Product } from '@/types';

type Node = Category & {
  text: string;
  opened?: boolean;
  selected?: boolean;
};

@Component({
  components: {
    Layout,
    VJstree,
  },
  mixins: [EditorMixin, ProductsEditMethodsMixin],
})
export default class ProductsEditCategories extends Vue {
  isLoading = false;
  isSaving = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Προϊόντα',
      to: { name: 'products' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'products.edit.general' },
    },
    {
      text: 'Κατηγορίες',
      active: true,
    },
  ];
  tree: Node[] = [];
  modelTree: Node[] = [];
  categories: Category[] = [];
  productCategories: Category[] = [];
  selectedCategories: Category[] = [];
  model: Product | {} = {};

  itemClick({ model }: { model: Node }) {
    // Check if editing product categories is allowed
    if (this.canEditProductCategories) {
      if (model.selected) {
        this.selectedCategories = [...this.selectedCategories, model];
      } else {
        this.selectedCategories = this.selectedCategories.filter(
          ({ id }) => id !== model.id,
        );
      }
      this.tree = this.getTreeData(this.categories);
    } else {
      return;
    }
  }

  get canEditProductCategories() {
    return process.env.VUE_APP_CAN_EDIT_PRODUCT_CATEGORIES === 'true';
  }

  getTreeData(categories: Category[], level: number = 0): Node[] {
    // Helper function to check if category has selected children
    const hasSelectedChildren = (category: Category, checkLevel: number) => {
      if (!category.children) return false;

      if (checkLevel === 0) {
        return category.children.some(child =>
          child.children?.some(grandChild =>
            this.selectedCategories.some(({ id }) => id === grandChild.id),
          ),
        );
      }

      return category.children.some(child =>
        this.selectedCategories.some(({ id }) => id === child.id),
      );
    };

    // Main logic starts here
    return categories
      .filter(category => category.level === level)
      .map(category => {
        const children = category.children ?? [];
        const disabled = children.length === 0;
        const selected = this.selectedCategories.some(
          ({ id }) => id === category.id,
        );
        const hasSelectedCategories = hasSelectedChildren(category, level);

        // Check if category is already opened
        const isCategoryAlreadyOpened = this.tree.some(
          node => node.id === category.id && node.opened,
        );

        return {
          ...category,
          disabled,
          selected: selected || hasSelectedCategories,
          opened: hasSelectedCategories || isCategoryAlreadyOpened,
          text: category.title,
          children: this.getTreeData(children, level + 1),
        };
      });
  }

  async getCategories() {
    try {
      this.isLoading = true;
      const [categoriesData, productCategoriesData] = await Promise.all([
        request.get('/categories/', {
          params: {
            paginated: false,
            sortDesc: false,
          },
        }),
        request.get(`/products/${this.$route.params.id}/categories`),
      ]);

      this.categories = categoriesData.data.categories;

      this.productCategories = productCategoriesData.data.categories;
      this.selectedCategories = productCategoriesData.data.categories;
      this.modelTree = this.getTreeData(this.categories);
      this.tree = cloneDeep(this.modelTree);
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι κατηγορίες',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    await this.getCategories();
    const { data } = await request.get(`/products/${this.$route.params.id}`);

    this.model = data.product;
  }

  async handleSave() {
    try {
      this.isSaving = true;
      const categories = this.selectedCategories.map(category => category.id);

      await request.put(`/products/${this.$route.params.id}/categories`, {
        categories,
      });
      await this.getCategories();
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να αποθηκευτούν οι κατηγορίες',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleReset() {
    this.selectedCategories = this.productCategories;
    this.tree = cloneDeep(this.modelTree);
  }
}
