export default {
  data: () => ({
    allSelected: false,
    indeterminate: false,
    isLoading: false,
    isLoaded: false,
    items: [],
    searchTerm: '',
    selectedIds: [],
    total: 0,
  }),

  watch: {
    selectedIds(newVal) {
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.itemsIds.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },

    items() {
      this.resetSelections();
    },
  },

  computed: {
    hasPagination() {
      return !this.isLoading && this.total > 0;
    },

    itemsIds() {
      return this.items.map(item => item.id);
    },

    hasSelectedItems() {
      return this.selectedIds.length > 0;
    },
  },

  methods: {
    handleSizeSelection(value) {
      this.$router
        .push({
          name: this.$route.name,
          query: { ...this.$route.query, perPage: value },
        })
        .catch(() => {});
    },

    handlePageSelection(value) {
      this.$router
        .push({
          name: this.$route.name,
          query: { ...this.$route.query, page: value },
        })
        .catch(() => {});
    },

    toggleAll(checked) {
      this.selectedIds = checked ? this.itemsIds.slice() : [];
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (this.selectedIds.includes(item.id)) return 'row-selected';
    },

    resetSelections() {
      this.selectedIds = [];
      this.allSelected = false;
    },

    handleSearch(term) {
      this.resetSelections();
      this.searchTerm = term;
      this.handlePageSelection(1);
    },

    handleClearSearch() {
      this.searchTerm = '';
      this.resetSelections();
      this.handlePageSelection(1);
    },
  },
};
