<template>
  <BOverlay :show="isLoading" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex justify-content-between align-items-center border-top">
      <div class="tab-header">
        {{ model.code }}
      </div>

      <BButton variant="primary" @click="handleDelete" size="sm">
        <BIcon icon="trash" class="mr-2" /> Διαγραφή
      </BButton>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
          <BCardBody>
            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider
                  name="code"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <BFormGroup size="md" label="Κωδικός *">
                    <BFormInput
                      id="code"
                      v-model="form.code"
                      type="text"
                      placeholder="Εισάγετε τον κωδικό του κουπονιού"
                      data-test="code"
                      autofocus
                      disabled
                    />
                    <BFormText
                      text-variant="danger"
                      data-test="code-error"
                      v-if="errors[0]"
                    >
                      Παρακαλώ εισάγετε τον κωδικό του κουπονιού
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider name="title">
                  <BFormGroup size="md" label="Τίτλος">
                    <BInputGroup>
                      <BFormInput
                        id="title"
                        v-model="form.title"
                        type="text"
                        placeholder="Εισάγετε τον τίτλο του κουπονιού"
                        data-test="title"
                      />
                    </BInputGroup>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider name="min_price">
                  <BFormGroup size="md" label="Ελάχιστη Αξία Παραγγελίας">
                    <BInputGroup>
                      <BFormInput
                        id="min_price"
                        v-model="form.min_price"
                        type="number"
                        placeholder="Εισάγετε την ελάχιστη αξία της παραγγελίας"
                        data-test="min_price"
                        step="1"
                      />
                    </BInputGroup>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider
                  name="value"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <BFormGroup size="md" label="Ποσό Κουπονιού *">
                    <BInputGroup>
                      <BFormInput
                        id="value"
                        v-model="form.value"
                        type="number"
                        placeholder="Εισάγετε το ποσό του κουπονιού"
                        data-test="value"
                      />
                      <BInputGroupAppend>
                        <BFormSelect
                          class="type-select"
                          v-model="form.type"
                          :options="typeOptions"
                        />
                      </BInputGroupAppend>
                    </BInputGroup>

                    <BFormText
                      text-variant="danger"
                      data-test="value-error"
                      v-if="errors[0]"
                    >
                      Παρακαλώ εισάγετε το ποσό του κουπονιού
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider name="date_start">
                  <BFormGroup size="md" label="Ημερομηνία Έναρξης">
                    <BFormDatepicker
                      id="date_start"
                      v-model="form.date_start"
                      locale="el"
                      :start-weekday="1"
                      :value-as-date="false"
                      show-decade-nav
                      reset-button
                      label-reset-button="Καθαρισμός"
                      label-no-date-selected="Κενή ημερομηνία"
                      placeholder="Επιλέξτε ημ/νία"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider name="date_end">
                  <BFormGroup size="md" label="Ημερομηνία Λήξης">
                    <BFormDatepicker
                      id="date_end"
                      v-model="form.date_end"
                      locale="el"
                      :start-weekday="1"
                      :value-as-date="false"
                      show-decade-nav
                      reset-button
                      label-reset-button="Καθαρισμός"
                      label-no-date-selected="Κενή ημερομηνία"
                      placeholder="Επιλέξτε ημ/νία"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider
                  title="active"
                  rules="required"
                  name="active"
                >
                  <BFormGroup size="md" label="Ενεργό *" class="mb-0">
                    <BFormCheckbox v-model="form.active" switch />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider name="one_timer">
                  <BFormGroup size="md" label="Μιας χρήσης">
                    <BFormCheckbox v-model="form.one_timer" switch />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
  },

  data: () => ({
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Κουπόνια',
        to: { name: 'coupons' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
    timerOptions: [
      {
        text: 'Ναι',
        value: true,
      },
      {
        text: 'Όχι',
        value: false,
      },
    ],
    typeOptions: [
      { value: 'amount', text: '€' },
      { value: 'percentage', text: '%' },
    ],
  }),

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/coupons/${this.$route.params.id}`);

      this.model = data.coupon;
      this.handleReset();
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το κουπόνι',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/coupons/${this.$route.params.id}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του κουπονιού ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        console.log(err);
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του κουπονιού δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το κουπόνι: ${this.model.code};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/coupons/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Το κουπόνι ${this.model.code} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            await this.$router.push({ name: 'coupons.list' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Το κουπόνι ${this.model.code} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleReset() {
      this.form = pick(this.model, [
        'active',
        'code',
        'date_end',
        'date_start',
        'min_price',
        'one_timer',
        'title',
        'type',
        'value',
      ]);
      if (!this.form.title) {
        this.form.title = this.model.code;
      }
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.type-select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
</style>
