<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions v-if="canDeleteBrands || canAddBrands">
        <BDropdown size="sm" right data-test="brand-actions" variant="primary">
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <BDropdownItemButton
            v-if="canDeleteBrands"
            class="delete-btn"
            @click="handleDelete"
          >
            Διαγραφή μάρκας
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
            data-test="form"
          >
            <BCardBody>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Όνομα *">
                  <BFormInput
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Εισάγετε το όνομα brand"
                    data-test="title"
                    autofocus
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το όνομα της μάρκας
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="slug"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Slug *">
                  <BInputGroup :prepend="`${eshopURL}/`">
                    <BFormInput
                      id="slug"
                      v-model="form.slug"
                      type="text"
                      placeholder="Εισάγετε το slug της μάρκας"
                      data-test="slug"
                    />
                    <BInputGroupAppend>
                      <BButton
                        variant="primary"
                        target="_blank"
                        :href="`${eshopURL}/${model.slug}`"
                      >
                        <BIcon icon="chevron-right" />
                      </BButton>
                    </BInputGroupAppend>
                  </BInputGroup>

                  <BFormText
                    text-variant="danger"
                    data-test="slug-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το slug της μάρκας
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                title="content"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Περιεχόμενο *">
                  <ckeditor
                    :editor="editor"
                    v-model="form.content"
                    data-test="content"
                    :config="editorConfig"
                    tag-name="textarea"
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε περιεχόμενο για το brand
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="active" rules="required">
                <BFormGroup size="md" label="Ενεργό" class="mb-0">
                  <BFormCheckbox v-model="form.active" switch />
                </BFormGroup>
              </ValidationProvider>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';
import slug from 'slug';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/BrandsEditLayout';
import BrandsEditMethodsMixin from './mixins/BrandsEditMethodsMixin';

export default {
  components: {
    BaseButtonSubmit,
    Layout,
  },

  mixins: [EditorMixin, BrandsEditMethodsMixin],

  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Μάρκες',
        to: { name: 'brands' },
      },
      {
        text: 'Επεξεργασία',
        to: { name: 'brands.edit.general' },
      },
      {
        text: 'Γενικά',
        active: true,
      },
    ],
  }),

  watch: {
    'form.title': {
      handler(value) {
        this.form = {
          ...this.form,
          slug: slug(value),
        };
      },
    },
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/brands/${this.$route.params.id}`);

      this.model = data.brand;
      this.initForm();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί η μάρκα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    initForm() {
      this.form = {
        ...pick(this.model, ['title', 'slug', 'active']),
        content: this.model.content || '',
      };
    },

    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/brands/${this.$route.params.id}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία της μάρκας ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit('get-counters');
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία της μάρκας δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.initForm();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
