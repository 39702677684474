
import { pick, isEmpty, omit } from 'lodash';
import { Vue, Component, Watch } from 'vue-property-decorator';

import slug from 'slug';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit.vue';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/PromosEditLayout.vue';
import PromosEditMethodsMixin from './mixins/PromosEditMethodsMixin';
import { Product, Promo } from '@/types';
import DynamicSelectMenuProducts from '@/components/DynamicSelectMenuProducts.vue';
import MultiSelectTag from '@/components/MultiSelectTag.vue';

@Component({
  mixins: [EditorMixin, PromosEditMethodsMixin],
  components: {
    BaseButtonSubmit,
    Layout,
    DynamicSelectMenuProducts,
    MultiSelectTag,
  },
})
export default class PromosEdit extends Vue {
  eshopURL = process.env.VUE_APP_ESHOP_URL;
  selectedProducts: any = {};
  isSaving = false;
  isLoading = false;
  fileSizeLimit = false;
  model: Pick<
    Promo,
    'title' | 'slug' | 'active' | 'crawlable' | 'products' | 'path'
  > = {
    title: '',
    slug: '',
    active: false,
    crawlable: false,
    products: [],
    path: '',
  };
  form: Pick<
    Promo,
    'title' | 'slug' | 'active' | 'crawlable' | 'products' | 'path'
  > = {
    title: '',
    slug: '',
    active: false,
    crawlable: false,
    products: [],
    path: '',
  };
  selectedPhoto = null;
  src: string | File | ArrayBuffer | null | undefined = '';
  isImageBroken = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Marketing Pages',
      to: { name: 'promos' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'promos.edit.general' },
    },
  ];

  get hasProducts() {
    return !isEmpty(this.selectedProducts);
  }

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/promos/${this.$route.params.id}`);

      this.model = data.promo;
      this.form = {
        ...pick(this.model, [
          'title',
          'slug',
          'active',
          'crawlable',
          'products',
          'path',
        ]),
      };

      this.selectedProducts = this.form.products.reduce(
        (obj, item: any) =>
          Object.assign(obj, { [item.id]: { count: 1, item } }),
        {},
      );

      this.src = this.form.path;

      this.$nextTick(() => {
        (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το marketing page',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('form.title')
  handler(value: string) {
    this.form = {
      ...this.form,
      slug: slug(value),
    };
  }

  async handleForm() {
    try {
      this.isSaving = true;

      const formData = new FormData();
      formData.append('title', this.form.title);
      formData.append('slug', this.form.slug);
      formData.append('crawlable', this.form.crawlable.toString());
      formData.append('active', this.form.active.toString());
      formData.append('image', this.form.path);
      formData.set(
        'products',
        JSON.stringify(Object.keys(this.selectedProducts)),
      );

      await request.post(`/promos/${this.$route.params.id}`, formData);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα στοιχεία του marketing page ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$emit('get-counters');
    } catch (err) {
      let text = 'Τα στοιχεία του marketing page δεν ενημερώθηκαν';

      const { status, data } = (err as any).response;

      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      if (status === 413) {
        text =
          'Το όριο μεγέθους για τις εικόνες είναι 1.5 MB. Μειώστε το μέγεθος του αρχείου και δοκιμάστε ξανά';
      }

      this.$swal({
        title: 'Σφάλμα',
        text,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleFileChange(e: any) {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    if (files[0].size > 1572864) {
      this.fileSizeLimit = true;
    } else {
      this.fileSizeLimit = false;
    }
    this.form.path = files[0];
    this.readImage();
  }

  readImage() {
    const reader = new FileReader();
    reader.onload = e => {
      this.src = e?.target?.result;
    };
    this.form.path && reader.readAsDataURL(this.form.path as File);
  }

  handleReset() {
    this.form = {
      title: '',
      slug: '',
      active: false,
      crawlable: false,
      products: [],
      path: '',
    };
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).reset();
    });
  }

  async searchProducts(term: string) {
    if (term.length) {
      await request.get('/products', {
        params: {
          paginated: false,
          filter: term,
        },
      });
    }
  }

  handleProductsSelect(product: Product) {
    this.selectedProducts = {
      ...this.selectedProducts,
      [product.id]: {
        count: 1,
        item: {
          ...product,
          photo: product.photos[0],
        },
      },
    };
  }

  handleTagDeleted(idToBeDeleted: string) {
    this.selectedProducts = omit(this.selectedProducts, [idToBeDeleted]);
  }
}
