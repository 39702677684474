/* eslint-disable */
const getters = {
  getUser(state) {
    return state.user;
  },

  getIsSuper(state, getters) {
    return getters.getUser.authority === 1;
  },

  getToken(state) {
    return state.token;
  },

  getAuthenticated(state) {
    return !!state.token; // Returns true if token is not empty
  },
};

export default getters;
