
import { Component, Prop, Vue } from 'vue-property-decorator';

import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import Layout from './components/ProductsLayout.vue';
import ProductThumbnail from '@/components/ProductThumbnail.vue';
import ProductsMethodsMixin from './mixins/ProductsMethodsMixin';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';
import ProductsMassUpdatesModal from './components/ProductsMassUpdatesModal.vue';
import qs from 'qs';
import { omit } from 'lodash';

@Component({
  components: {
    ProductThumbnail,
    ListActionsBar,
    Layout,
    ListPagination,
    ListThreeDotsMenu,
    ProductsMassUpdatesModal,
  },
  mixins: [QueryParamsPaginationMixin, ProductsMethodsMixin],
})
export default class ProductsInactive extends Vue {
  @Prop({ type: Object, required: false }) readonly counters!: Object;
  @Prop({ type: Boolean, required: false }) readonly isCountersLoaded!: boolean;

  sortBy = 'final_price';
  sortDesc = false;
  total = 0;
  items = [];
  searchTerm = '';
  isLoading = false;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Φώτο', key: 'thumbnail' },
    { label: 'Όνομα', key: 'title', sortable: true },
    { label: 'Stock', key: 'stock' },
    { label: 'Barcodes', key: 'barcodes' },
    { label: 'Π.Λ Τιμή (€)', key: 'sell_price', sortable: true },
    { label: 'Έκπτωση (%)', key: 'discount', sortable: true },
    { label: 'Τελική τιμή (€)', key: 'final_price', sortable: true },
    { label: 'Ενεργό', key: 'active' },
    { label: '', key: 'actions', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Προϊόντα',
      active: true,
    },
  ];

  filters = [
    {
      label: 'Νέο',
      apiName: 'is_new',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Ενεργό',
      apiName: 'active',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Προσφορά',
      apiName: 'is_offer',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Δημοφιλές',
      apiName: 'is_popular',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Έχει Κατηγορία',
      apiName: 'has_category',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Brand',
      apiName: 'brand_id',
      type: 'brand',
      value: '',
    },
    {
      label: 'Κατηγορία',
      apiName: 'category_id',
      type: 'category',
      value: '',
    },
  ];

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  async productsProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/products', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          active: false,
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.products.total;
      this.items = data.products.data;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα προϊόντα',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
