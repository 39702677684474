<template>
  <BOverlay :show="isLoading" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems" :type="model.type">
      <template #actions>
        <BButton variant="primary" @click="handleDelete" size="sm">
          <BIcon icon="trash" class="mr-2" /> Διαγραφή
        </BButton>
      </template>
      <BCard no-body class="main-card no-border-top-left">
        <div class="ml-4 mt-4">
          Τύπος πελάτη:
          <BBadge
            class="px-5 py-2 ml-4 mt-4 font-size-sm text-capitalize"
            :variant="typeVariant"
          >
            {{ model.type }}
          </BBadge>
        </div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
            <BCardBody>
              <ValidationProvider
                name="firstName"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Όνομα *">
                  <BFormInput
                    id="firstName"
                    v-model="form.firstName"
                    type="text"
                    placeholder="Εισάγετε όνομα"
                    data-test="firstName"
                    autofocus
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="firstName-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το όνομα του πελάτη
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="lastName"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Επώνυμο *">
                  <BFormInput
                    id="lastName"
                    v-model="form.lastName"
                    type="text"
                    placeholder="Εισάγετε επώνυμο"
                    data-test="lastName"
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="lastName-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το επώνυμο του πελάτη
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors, failedRules }"
              >
                <BFormGroup size="md" label="Email *">
                  <BFormInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    placeholder="Εισάγετε email"
                    data-test="email"
                  />
                  <BFormText
                    text-variant="danger"
                    data-test="email-error"
                    v-if="failedRules.required"
                  >
                    Παρακαλώ εισάγετε το email του πελάτη
                  </BFormText>
                  <BFormText
                    text-variant="danger"
                    data-test="email-error"
                    v-else-if="failedRules.email"
                  >
                    Παρακαλώ εισάγετε ενα έγκυρο email
                  </BFormText>
                  <BFormText
                    text-variant="danger"
                    data-test="email-error"
                    v-else-if="errors[0]"
                  >
                    {{ errors[0] }}
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors, failedRules }"
                name="mobile"
                rules="required"
              >
                <BFormGroup size="md" label="Κινητό *">
                  <VueTelInput
                    v-model="form.mobile"
                    placeholder="Εισάγετε κινητό"
                    :input-options="{
                      id: 'mobile',
                    }"
                    valid-characters-only
                    defaultCountry="gr"
                    :onlyCountries="['gr']"
                    :autoDefaultCountry="false"
                  />
                  <BFormText
                    v-if="failedRules.required"
                    class="d-block"
                    text-variant="danger"
                  >
                    Εισάγετε κινητό
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <BFormGroup size="md" label="Τηλέφωνο">
                <VueTelInput
                  v-model="form.telephone"
                  placeholder="Εισάγετε τηλέφωνο"
                  :input-options="{
                    id: 'telephone',
                  }"
                  valid-characters-only
                  defaultCountry="gr"
                  :onlyCountries="['gr']"
                  :autoDefaultCountry="false"
                />
              </BFormGroup>

              <BFormGroup size="md" label="Φύλο">
                <BFormSelect v-model="form.gender" :options="options" />
              </BFormGroup>

              <BFormGroup size="md" label="Ημ. Γέννησης" class="mb-5">
                <BFormDatepicker
                  id="birthday"
                  v-model="form.birthday"
                  locale="el"
                  :start-weekday="1"
                  :value-as-date="false"
                  show-decade-nav
                  placeholder="Επιλέξτε ημ/νία"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                />
              </BFormGroup>
            </BCardBody>
            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="person-plus-fill"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />
                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import Layout from './components/CustomersEditLayout';
import { CustomerType } from '@/types';

@Component({
  components: {
    BaseButtonSubmit,
    Layout,
  },
})
export default class CustomersEditGeneral extends Vue {
  model = {};
  form = {};
  isSaving = false;
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Πελάτες',
      to: { name: 'customers' },
    },
    {
      text: 'Επεξεργασία',
      active: true,
    },
  ];
  options = [
    { value: null, text: 'Επιλέξτε φύλο' },
    { value: 'female', text: 'Γυναίκα' },
    { value: 'male', text: 'Άνδρας' },
    { value: 'other', text: 'Άλλο' },
  ];

  get typeVariant() {
    return this.model.type === CustomerType.Profile ? 'success' : 'warning';
  }

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/customers/${this.$route.params.id}`);

      this.model = data.customer;

      this.form = {
        ...pick(this.model, [
          'firstName',
          'lastName',
          'email',
          'mobile',
          'telephone',
          'gender',
          'birthday',
        ]),
        mobile: this.model.mobile || '',
        telephone: this.model.telephone || '',
      };

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί ο πελάτης',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.put(`/customers/${this.$route.params.id}`, this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα στοιχεία του πελάτη ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      await this.$router.push({ name: 'customers.all' });
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        this.$refs.form.setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα στοιχεία του πελάτη δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε τον πελάτη: ${this.model.fullName};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/customers/${this.$route.params.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Ο πελάτης ${this.model.fullName} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          await this.$router.push({ name: 'customers.all' });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleReset() {
    this.form = {
      ...pick(this.model, [
        'firstName',
        'lastName',
        'email',
        'mobile',
        'telephone',
        'gender',
        'birthday',
      ]),
      mobile: this.model.mobile || '',
      telephone: this.model.telephone || '',
    };
  }
}
</script>
