var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BDropdown',{staticClass:"m-0",attrs:{"right":"","variant":"none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('BIcon',{attrs:{"icon":"three-dots-vertical"}})]},proxy:true}])},[_c('BDropdownItem',{attrs:{"to":{
      name: 'filterGroup.filters.edit',
      params: {
        id: _vm.item.filter_group_id,
        filter: _vm.item.id,
      },
    }}},[_vm._v(" Επεξεργασία ")]),(_vm.canDelete)?[_c('BDropdownDivider'),_c('BDropdownItemButton',{staticClass:"delete-btn",on:{"click":_vm.handleDelete}},[_vm._v(" Διαγραφή ")])]:_vm._e(),(_vm.canCancel && !_vm.isCanceled)?[_c('BDropdownDivider'),_c('BDropdownItemButton',{staticClass:"delete-btn",on:{"click":_vm.handleCancel}},[_vm._v(" Ακύρωση ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }