import qs from 'qs';
import request from '@/utils/request';

export default {
  methods: {
    handleDelete(item) {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε τον πελάτη: ${item.fullName};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/customers/${item.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Ο πελάτης ${item.fullName} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Ο πελάτης ${item.fullName} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε ${
          this.selectedIds.length === 1
            ? 'τον επιλεγμένο πελάτη;'
            : `τους επιλεγμένους ${this.selectedIds.length} πελάτες;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete('/customers', {
              params: {
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            }),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'Ο πελάτης διαγράφηκε επιτυχώς'
                    : `${this.selectedIds.length} πελάτες διαγράφηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
                showConfirmButton: false,
              });

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Ο πελάτης δεν διεγράφη'
                  : 'Οι πελάτες δεν διεγράφησαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },
  },
};
