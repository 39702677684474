
import { Vue, Component } from 'vue-property-decorator';
import EditorMixin from '@/mixins/EditorMixin';
import request from '@/utils/request';
import { Banner } from '@/types';

@Component({
  mixins: [EditorMixin],
})
export default class BannersAdd extends Vue {
  isSaving = false;
  form: Pick<
    Banner,
    | 'title'
    | 'title_en'
    | 'subtitle'
    | 'subtitle_en'
    | 'link'
    | 'path'
    | 'active'
  > = {
    path: '',
    title: '',
    title_en: '',
    subtitle: '',
    subtitle_en: '',
    link: '',
    active: false,
  };
  selectedPhoto = '';
  src: string | ArrayBuffer | null | undefined = '';
  isDropzoneOpen = true;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Banners',
      to: { name: 'content.banners.list' },
    },
    {
      text: 'Προσθήκη',
      active: true,
    },
  ];

  selectPhotoToReplace() {
    this.isDropzoneOpen = true;
  }

  async handleForm() {
    try {
      this.isSaving = true;

      const formData = new FormData();
      formData.append('title', this.form.title);
      formData.append('title_en', this.form.title_en);
      formData.append('subtitle', this.form.subtitle);
      formData.append('subtitle_en', this.form.subtitle_en);
      formData.append('link', this.form.link);
      formData.append('active', this.form.active.toString());
      formData.append('image', this.form.path);

      await request.post('/banners', formData);

      this.$swal({
        title: 'Επιτυχία',
        text: 'To banner προστέθηκε',
        icon: 'success',
        timer: 3000,
      });

      await this.$router.push({ name: 'content.banners.list' });
    } catch (err) {
      let text = 'Αδυναμία προσθήκης Banner';
      //Catch clause variable type annotation must be 'any' or 'unknown' if specified
      const { status, data } = (err as any).response || {};
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      if (status === 413) {
        text =
          'Το όριο μεγέθους για τις εικόνες είναι 1.5 MB. Μειώστε το μέγεθος του αρχείου και δοκιμάστε ξανά';
      }

      this.$swal({
        title: 'Σφάλμα',
        text,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleFileChange(e: any) {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }

    this.form.path = files[0];
    this.isDropzoneOpen = false;
    this.readImage();
    return true;
  }

  readImage() {
    const reader = new FileReader();
    reader.onload = e => {
      this.src = e?.target?.result;
    };
    this.form.path && reader.readAsDataURL(this.form.path as File);
  }

  handleReset() {
    this.form = {
      path: '',
      title: '',
      title_en: '',
      subtitle: '',
      subtitle_en: '',
      link: '',
      active: false,
    };
    this.selectedPhoto = '';
    this.$nextTick(() => {
      this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
