import { render, staticRenderFns } from "./OrganizationGeneral.vue?vue&type=template&id=01b8fb4c&scoped=true"
import script from "./OrganizationGeneral.vue?vue&type=script&lang=ts"
export * from "./OrganizationGeneral.vue?vue&type=script&lang=ts"
import style0 from "./OrganizationGeneral.vue?vue&type=style&index=0&id=01b8fb4c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b8fb4c",
  null
  
)

export default component.exports