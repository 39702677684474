import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/el';

export default {
  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      language: 'el',
    },
  }),
};
