
import { Vue, Component } from 'vue-property-decorator';

import request from '@/utils/request';
import { Address, Customer } from '@/types';
import Layout from '@/views/Dashboard/views/Customers/CustomersEdit/components/CustomersEditLayout.vue';

@Component({
  components: {
    Layout,
  },
})
export default class CustomersEditAddresses extends Vue {
  model = {};
  form = {};
  isSaving = false;
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Πελάτες',
      to: { name: 'customers' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'customers.edit.general' },
    },
    {
      text: 'Διευθύνσεις',
      active: true,
    },
  ];
  fields = ['#', { label: 'Διεύθυνση', key: 'address', class: 'w-auto' }];
  items: Address[] = [];
  customer: Customer | null = null;

  async addressesProvider() {
    try {
      this.isLoading = true;

      const { data } = await request.get(
        `/customers/${this.$route.params.id}/addresses/`,
      );
      const { data: customerData } = await request.get(
        `/customers/${this.$route.params.id}`,
      );

      this.customer = customerData.customer;
      this.items = data.addresses;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι διευθύνσεις',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε τον πελάτη: ${this.customer?.fullName};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/customers/${this.customersName}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Ο πελάτης ${this.customersName} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          await this.$router.push({ name: 'customers.all' });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `Ο πελάτης ${this.customersName} δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  get customersName() {
    return this.customer?.fullName ?? '';
  }

  get customersType() {
    return this.customer?.type;
  }
}
