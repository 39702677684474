const getters = {
  getOrganization(state) {
    return state.data;
  },

  getServices(state, { getOrganization }) {
    return getOrganization.services;
  },

  getEnabledService: (state, { getServices }) => service => {
    return getServices[service]?.enabled === true;
  },
};

export default getters;
