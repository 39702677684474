import request from '@/utils/request';

export default {
  methods: {
    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το promo: ${this.model.title};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/promos/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Το promo ${this.model.title} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
            });

            this.$emit('get-counters');

            await this.$router.push({ name: 'promos.all' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Το promo ${this.model.title} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },
  },
};
