<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title p-0">
        <RouterLink v-if="to" :to="to">
          <h6>
            {{ title }}
          </h6>
        </RouterLink>
        <h6 v-else>{{ title }}</h6>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <slot v-for="item in items" :item="item" name="item" />

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
    to: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  max-height: 400px;
  overflow-y: auto;
}

a {
  display: block;
  margin-bottom: 0;
  padding: 5px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #dbdbdb;
  }

  &[disabled] {
    color: #7a7a7a;
    cursor: not-allowed;
  }
}

.card-header-title a {
  padding: 0;
}

.card-content {
  padding: 1.5rem;
}

.card-header-title {
  padding: 4px;
}
</style>
