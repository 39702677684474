<template>
  <BOverlay :show="true" rounded="sm" no-fade>
    <RouterView />

    <template #overlay>
      <div class="text-center">
        <h3 id="cancel-label">CommerceX premium feature</h3>
      </div>
    </template>
  </BOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      organization: 'organization/getOrganization',
    }),

    isOrganizationActive() {
      if (this.organization.services.blog) {
        return this.organization.services.blog.services.postCategories.active;
      }
      return true;
    },
  },
};
</script>
