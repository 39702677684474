<template>
  <div>
    <BBreadcrumb :items="breadcrumbItems" />

    <div
      class="d-flex justify-content-between align-items-center border-top mb-0"
    >
      <BNav class="nav-tab-header" tabs>
        <BNavItem
          exact
          exact-active-class="active"
          :to="{ name: 'content.questions.list' }"
        >
          Συχνές Ερωτήσεις
        </BNavItem>
      </BNav>
      <BButton
        variant="primary"
        :to="{ name: 'content.questions.add' }"
        size="sm"
      >
        <BIcon icon="plus-circle" class="mr-2" /> Προσθήκη
      </BButton>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ListActionsBar
        :disabled="!hasSelectedItems"
        has-activate-option
        has-deactivate-option
        @clear-search="handleClearSearch"
        @mass-activate="handleMassActivate"
        @mass-deactivate="handleMassDeactivate"
        @mass-delete="handleMassDelete"
        @on-search="handleSearch"
      />

      <BCardBody>
        <BTable
          hover
          bordered
          striped
          no-sort-reset
          sort-icon-left
          responsive="md"
          id="table"
          ref="table"
          :items="questionsProvider"
          :current-page="currentPage"
          :fields="fields"
          :filter="searchTerm"
          :busy="isLoading"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          show-empty
          data-test="questions-list"
        >
          <template v-slot:head(checkbox)>
            <BFormCheckbox
              v-model="allSelected"
              :indeterminate="indeterminate"
              class="text-left"
              @change="toggleAll"
            />
          </template>

          <template v-slot:cell(checkbox)="data">
            <BFormCheckboxGroup
              :id="data.item.id"
              v-model="selectedIds"
              :options="[data.item.id]"
              class="text-left listed-checkbox"
            />
          </template>

          <template v-slot:cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(question)="data">
            <RouterLink
              :to="{
                name: 'content.questions.edit',
                params: { id: data.item.id },
              }"
              class="text-decoration-none"
            >
              {{ data.item.question }}
            </RouterLink>
          </template>

          <template v-slot:cell(active)="data">
            <BFormCheckbox
              :checked="data.item.active"
              switch
              @change="handleToggleActive(data.item)"
            />
          </template>

          <template v-slot:cell(actions)="data">
            <ListThreeDotsMenu
              :item="data.item"
              route-name="content.questions.edit"
              @on-delete="handleDelete"
            />
          </template>

          <template v-slot:table-busy>
            <div class="text-center my-4">
              <div class="mb-4">
                <BSpinner class="align-middle" />
              </div>
              <h5>Λήψη δεδομένων...</h5>
            </div>
          </template>

          <template v-slot:empty>
            <h5 class="mb-0 py-2 text-center">Δεν βρέθηκαν ερωτήσεις</h5>
          </template>

          <template v-slot:emptyfiltered>
            <div class="mb-0 py-2 text-center">
              Δεν βρέθηκαν αποτελέσματα αναζήτησης για "{{ searchTerm }}"
            </div>
          </template>
        </BTable>
      </BCardBody>
    </BCard>
  </div>
</template>

<script>
import request from '@/utils/request';
import PaginationMixin from '@/mixins/PaginationMixin';
import QuestionsMethodsMixin from './mixins/QuestionsMethodsMixin';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu';
import ListActionsBar from '@/components/ListActionsBar';

export default {
  mixins: [PaginationMixin, QuestionsMethodsMixin],

  components: {
    ListThreeDotsMenu,
    ListActionsBar,
  },

  props: {
    counters: Object,
    isCountersLoaded: Boolean,
  },

  data: () => ({
    sortBy: 'title',
    sortDesc: false,
    fields: [
      { label: '', key: 'checkbox', class: 'text-right' },
      '#',
      { label: 'Ερώτηση', key: 'question', sortable: true },
      { label: 'Ενεργή', key: 'active' },
      { label: '', key: 'actions', class: 'text-right' },
    ],
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Συχνές Ερωτήσεις',
        active: true,
      },
    ],
  }),

  methods: {
    async questionsProvider() {
      try {
        this.isLoading = true;

        const { data } = await request.get('/questions');

        this.items = data.questions;

        return this.items;
      } catch (err) {
        this.$router.push({ name: 'error' });
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεv μπόρεσαν να φορτωθούν οι ερωτήσεις',
          icon: 'error',
          timer: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
