
import { Component, Prop, Vue } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';

import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import FiltersMethodsMixin from './mixins/FiltersMethodsMixin';
import Layout from './components/FiltersLayout.vue';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from './components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';
import { FilterGroup } from '@/types';

@Component({
  mixins: [QueryParamsPaginationMixin, FiltersMethodsMixin],
  components: {
    Layout,
    ListPagination,
    ListThreeDotsMenu,
    ListActionsBar,
  },
})
export default class FilterGroupsAll extends Vue {
  @Prop({ type: Object, required: false }) readonly counters!: Object;
  @Prop({ type: Boolean, required: false }) readonly isCountersLoaded!: boolean;

  sortBy = 'title';
  sortDesc = false;
  filterGroup = {};
  items = [];

  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Τίτλος', key: 'title', sortable: true },
    { label: 'Προβολή', key: 'active' },
    { label: '', key: 'actions', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Ομάδα Φίλτρων',
      to: { name: 'filterGroups.all' },
    },
    {
      text: '...',
      to: {
        name: 'filterGroups.edit',
        params: { id: this.$route.params.id },
      },
    },
    {
      text: 'Φίλτρα',
    },
  ];
  filters = [
    {
      label: 'Εμφανές',
      apiName: 'active', //? check c implementation
      type: 'boolean',
      value: false,
    },
  ];
  isLoading = false;
  total = 0;
  searchTerm = '';

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async filtersProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get(
        `/filterGroups/${this.$route.params.id}/filters`,
        {
          params: {
            page: this.$route.query.page,
            size: this.$route.query.perPage,
            sortBy: ctx.sortBy,
            sortDesc: ctx.sortDesc,
            ...ctx.filter,
          },
          paramsSerializer: params => {
            return qs.stringify(params, {
              arrayFormat: 'comma',
              encode: false,
            });
          },
        },
      );

      this.total = data.filters.total;
      this.items = data.filters.data;
      this.filterGroup = data.filterGroup;

      this.breadcrumbItems[1].text = (this.filterGroup as FilterGroup).title;
      if (this.breadcrumbItems[1].to && this.breadcrumbItems[1].to.params) {
        this.breadcrumbItems[1].to.params.id = (this
          .filterGroup as FilterGroup).id;
      }

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα φίλτρα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
