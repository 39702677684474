
import { Component, Vue } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';
import { BTable } from 'bootstrap-vue';
import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import { Post } from '@/types';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';

@Component({
  mixins: [QueryParamsPaginationMixin],

  components: {
    ListThreeDotsMenu,
    ListPagination,
    ListActionsBar,
  },
})
export default class PostsList extends Vue {
  sortBy = 'title';
  sortDesc = false;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Όνομα', key: 'title', sortable: true },
    { label: 'Ενεργό', key: 'active' },
    { label: '', key: 'actions', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Άρθρα',
      active: true,
    },
  ];
  filters = [
    {
      label: 'Ενεργό',
      apiName: 'active',
      type: 'boolean',
      value: false,
    },
  ];
  isLoading = false;
  isLoaded = false;
  total = 0;
  items = [];
  searchTerm = '';
  selectedIds = [];

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async postsProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/posts', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.posts.total;
      this.items = data.posts.data;
      this.isLoaded = true;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα άρθρα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleDelete(item: Post) {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε το άρθρο: ${item.title};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/posts/${item.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `To άρθρο ${item.title} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `To άρθρο ${item.title} δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε ${
        this.selectedIds.length === 1
          ? 'το επιλεγμένο άρθρο;'
          : `τα επιλεγμένα ${this.selectedIds.length} άρθρα;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete('/posts', {
            params: {
              ids: this.selectedIds,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {
                arrayFormat: 'comma',
                encode: false,
              });
            },
          }),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το άρθρο διαγράφηκε επιτυχώς'
                  : `${this.selectedIds.length} άρθρα διαγράφηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το άρθρο δεν διεγράφη'
                : 'Τα άρθρα δεν διεγράφησαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassActivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'το επιλεγμένο άρθρο;'
          : `τα επιλεγμένα ${this.selectedIds.length} άρθρο;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            '/posts',
            {},
            {
              params: {
                active: true,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το άρθρο ενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} άρθρα ενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το άρθρο δεν ενεργοποιήθηκε'
                : 'Τα άρθρα δεν ενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassDeactivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να απενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'το άρθρο κουπόνι;'
          : `τα επιλεγμένα ${this.selectedIds.length} άρθρα;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            '/posts',
            {},
            {
              params: {
                active: false,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το άρθρο απενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} άρθρα απενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το άρθρο δεν απενεργοποιήθηκε'
                : 'Τα άρθρα δεν απενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  async handleToggleActive(item: Post) {
    try {
      await request.patch(`/posts/${item.id}/active`);

      this.$swal({
        title: 'Επιτυχία',
        text: `Το άρθρο ${
          item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
        }!`,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$nextTick(() => {
        this.$refs.table && (this.$refs.table as BTable).refresh();
      });
    } catch (error) {
      this.$swal({
        title: 'Σφάλμα',
        text: `Αδυναμία ${
          item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
        } άρθρου`,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }
}
