/* eslint-disable */
const mutations = {
  loginUser(state, { user, token }) {
    state.user = user;
    state.token = token;
  },

  logoutUser(state) {
    state.user = {};
    state.token = '';
  },

  setUser(state, { user }) {
    state.user = user;
  },

  updateUserName(state, { name }) {
    state.user = {
      ...state.user,
      name,
    };
  },

  setToken(state, { token }) {
    state.token = token;
  },
};

export default mutations;
