import request from '@/utils/request';

export default {
  computed: {
    canAddProductCategories() {
      return process.env.VUE_APP_CAN_ADD_PRODUCT_CATEGORIES === 'true';
    },

    canEditProductCategories() {
      return process.env.VUE_APP_CAN_EDIT_PRODUCT_CATEGORIES === 'true';
    },

    canDeleteCategories() {
      return process.env.VUE_APP_CAN_DELETE_CATEGORIES === 'true';
    },
  },

  methods: {
    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε την κατηγορία: ${this.model.title};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/categories/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Η κατηγορία ${this.model.title} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
            });

            this.$emit('get-counters');

            await this.$router.push({ name: 'categories.all' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Η κατηγορία ${this.model.title} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
            });
          }
        }
      });
    },
  },
};
