
import { Component, Vue } from 'vue-property-decorator';

import { pick } from 'lodash';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/CategoriesEditLayout.vue';
import CategoriesEditMethodsMixin from './mixins/CategoriesEditMethodsMixin';

import { Category } from '@/types';

@Component({
  mixins: [EditorMixin, CategoriesEditMethodsMixin],
  components: {
    Layout,
  },
})
export default class CategoriesEditEnglish extends Vue {
  availabilities = [];
  brands = [];
  isLoading = false;
  isSaving = false;
  model: Partial<Category> = {};
  form: Partial<Category> = {};
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Κατηγορίες',
      to: { name: 'categories' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'categories.edit.general' },
    },
    {
      text: 'Αγγλικά',
      active: true,
    },
  ];

  async getData() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/categories/${this.$route.params.id}`,
      );

      this.model = data.category;
      this.initForm();
      this.$nextTick(() => {
        this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί η κατηγορία',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  initForm() {
    this.form = {
      ...pick(this.model, ['title_en']),
      content_en: this.model.content_en || '',
    };
  }

  created() {
    this.getData();
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.patch(`/categories/${this.$route.params.id}`, this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα στοιχεία της κατηγορίας ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$emit('get-counters');

      await this.getData();
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα στοιχεία της κατηγορίας δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleReset() {
    this.initForm();
    this.$nextTick(() => {
      this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
