export type Photo = {
  id: string;
  path: string;
  unique_id: string | null;
  photoable_type: string;
  photoable_id: string;
  order: number;
  deleted_at: string | null;
  created_at: string;
  updated_at: string | null;
};

export enum CustomerType {
  Profile = 'profile',
  Guest = 'guest',
}

export type Customer = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  telephone: string | null;
  gender: string | null;
  birthday: string | null;
  type: CustomerType;
  last_login_at: string | null;
  deleted_at: string | null;
  created_at: string;
  updated_at: string | null;
  has_password: boolean;
  fullName: string;
};

export type Address = {
  id: string;
  firstName: string;
  lastName: string;
  streetName: string;
  streetNumber: string;
  zipcode: string;
  city: string;
  region: string;
  mobile: string;
  telephone: string | null;
  customer_id: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string | null;
};

export type Barcode = {
  id: string;
  code: string;
  product_id: string;
  created_at: string;
  updated_at: string | null;
};

export type Product = {
  id: string;
  brand: Brand;
  uuid: string;
  title: string;
  title_en: string | null;
  slug: string;
  content: string;
  content_en: string | null;
  ingredients: string | null;
  instructions: string | null;
  stock: number;
  active: boolean;
  is_offer: boolean;
  is_new: boolean;
  is_popular: boolean;
  is_proposal: boolean;
  is_brochure: boolean;
  buy_price: number;
  sell_price: number;
  final_price: number;
  discount: number;
  brand_id: string;
  availability_id: string;
  always_available: boolean;
  details: unknown;
  dimensions: string;
  weight: number;
  volume: number;
  skroutz_feed: boolean;
  best_price_feed: boolean;
  facebook_feed: boolean;
  google_feed: boolean;
  deleted_at: string | null;
  created_at: string;
  updated_at: string | null;
  barcodes: Array<Barcode>;
  photos: Array<Photo>;
  vat: number;
};

export type CartItem = {
  count: number;
  item: Product & { photo?: Photo };
};

export type CartItems = Record<CartItem['item']['id'], CartItem>;

export enum CouponType {
  Amount = 'amount',
  Percentage = 'percentage',
}

export type Coupon = {
  id: string;
  active: boolean;
  code: string;
  date_end: string;
  date_start: string;
  min_price: number;
  one_timer: boolean;
  title: string;
  type: 'amount' | 'percentage';
  value: number;
};

export type Newsletter = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  created_at: string;
  updated_at: string | null;
};

export enum SendMethodSlug {
  StorePickup = 'store_pickup',
  SpeedexCourier = 'speedex_courier',
}

export type SendMethod = {
  id: string;
  title: string;
  slug: SendMethodSlug;
  active: boolean;
  created_at: string;
  updated_at: string | null;
};

export enum OrderMethodSlug {
  Eshop = 'eshop', // Eshop orders
  Phone = 'phone', // Orders placed via phone
  SocialMedia = 'social-media', // Orders placed via social media
  Email = 'email', // Orders placed via email
}

export type OrderMethod = {
  id: string;
  title: string;
  slug: OrderMethodSlug;
  active: boolean;
  created_at: string;
  updated_at: string | null;
};

export enum CheckoutMethodSlug {
  PayInStore = 'pay_in_store', // Pay in store
  CreditCard = 'credit_card', // Debit/Credit card payment
  BankAccount = 'bank_account', // Bank deposit
  PayOnDelivery = 'pay_on_delivery', // Cash on delivery
}

export type CheckoutMethod = {
  id: string;
  title: string;
  slug: CheckoutMethodSlug;
  active: boolean;
  charge: number;
  created_at: string;
  updated_at: string | null;
};

export type OrderAddress = Pick<
  Address,
  | 'firstName'
  | 'lastName'
  | 'streetName'
  | 'streetNumber'
  | 'zipcode'
  | 'city'
  | 'region'
  | 'mobile'
  | 'telephone'
>;

export type Invoice = {
  company: string;
  profession: string;
  vatNumber: string;
  vatOffice: string;
};

export enum OrderStatusSlug {
  New = 'new', // New order
  Confirm = 'confirm', // Order confirmed
  Check = 'check', // Stock check
  Pick = 'pick', // Picking items
  Voucher = 'voucher', // Voucher generated
  ForInvoice = 'for_invoice', // For invoicing
  Invoice = 'invoice', // Invoiced
  ForDelivery = 'for_delivery', // Sent for delivery
  Delivered = 'delivered', // Delivered
  Cancelled = 'cancelled', // Cancelled
}

export type OrderStatus = {
  id: string;
  order: number;
  active: boolean;
  slug: OrderStatusSlug;
  title: string;
};

export type Transaction = {
  id: string;
  amount: number;
  status: string;
  viva_id: string;
  description: string | null;
  order_id: string;
  viva_order_id: string;
  viva_event_id: string;
  viva_eci: string;
  created_at: string;
  updated_at: string | null;
};

export enum OrderType {
  Profile = 'profile',
  Guest = 'guest',
}

export type Order = {
  id: string;
  code: string;
  email: string;
  charge_address: OrderAddress | null;
  send_address: OrderAddress | null;
  invoice: Invoice | null;
  sum_products: number;
  sum_total: number;
  products: CartItem[];
  customer_id: string;
  checkout_method_id: string | null;
  checkout_method: CheckoutMethod | null;
  send_method_id: string | null;
  send_method: SendMethod | null;
  coupon_id: string;
  statuses: OrderStatus[];
  status_id: string;
  status: OrderStatus;
  type: OrderType;
  notes: string;
  courier_notes: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string | null;
  customer: Customer;
  send_charge?: number | null;
  coupon?: Coupon | null;
  is_gift: boolean;
  gift_cost: number;
  checkout_charge: number | null;
  is_unfinished: number | boolean;
  transaction: Transaction | null;
  vouchers: Array<string>;
  parcels_count: number;
  order_method_id: string | null;
  order_method: OrderMethod | null;
};

export type Loading = (status: boolean) => void;

export type Category = {
  active: boolean;
  content: string | null;
  created_at: string;
  deleted_at: string | null;
  id: string;
  level: number;
  order: number;
  parent_id: string | null;
  slug: string;
  title: string;
  updated_at?: string;
  is_mega_menu_visible: boolean;
  content_en: string | null;
  title_en: string | null;
  children?: Category[];
};

export type Note = {
  id: string;
  comment: string;
  user: {
    id: string;
    name: string;
  };
  created_at: string;
};

export type Comment = {
  id: string;
  content: string;
  active: boolean;
  created_at: string;
  customer: {
    id: string;
    fullName: string;
  };
};

export type Review = {
  id: string;
  stars: number;
  content: string | null;
  active: boolean;
  created_at: string;
  customer: {
    id: string;
    fullName: string;
  };
};

export type PostCategory = {
  content: string;
  content_en: string | null;
  created_at: string;
  deleted_at: string | null;
  id: string;
  slug: string;
  title: string;
  title_en: string | null;
  updated_at: string;
};

export type Author = {
  id: string;
  email: string;
  name: string;
};

export type Post = {
  active: boolean;
  author_id: null;
  content: string;
  content_en: string | null;
  title_en: string | null;
  created_at: string;
  deleted_at: string | null;
  id: string;
  slug: string;
  title: string;
  updated_at: string | null;
  post_categories: PostCategory[];
};

export type Brand = {
  active: boolean;
  content: string;
  content_en: string | null;
  created_at: string;
  deleted_at: string | null;
  id: string;
  slug: string;
  title: string;
  title_en: string | null;
  updated_at: string;
};

export type Filter = {
  type: 'boolean' | 'string' | 'brand' | 'category';
  label: string;
  apiName: string;
  value: string | boolean;
};

export type FilterGroup = {
  id: string;
  title: string;
};

export type FilterGroupFilter = {
  id: string;
  title: string;
  filter_group_id: string;
};

export type Banner = {
  title: string;
  title_en: string;
  subtitle: string;
  subtitle_en: string;
  link: string;
  path: File | string;
  active: boolean;
  id: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string | null;
  order: number;
  unique_id: string;
};

export type Promo = {
  id: number;
  slug: string;
  title: string;
  created_at: string;
  updated_at: string;
  uuid: string;
  active: boolean;
  crawlable: boolean;
  products: Array<string>;
  path: File | string;
};
