<template>
  <Layout :breadcrumb-items="breadcrumbItems">
    <template #nav>
      <div class="d-flex border-top">
        <div class="tab-header">
          Προσθήκη Νέου Χρήστη
        </div>
      </div>
    </template>

    <template #default>
      <BCard no-body class="main-card no-border-top-left">
        <BCardBody>
          <BRow>
            <BCol md="3" class="mb-3">
              <BCardText class="font-weight-light">
                Συμπληρώστε τη φόρμα και αποθηκεύστε για να δημιουργήσετε ένα
                νέο χρήστη που θα έχει πρόσβαση στο διαχειριστικό σας
                περιβάλλον.
              </BCardText>
            </BCol>
            <BCol class="form-wrapper mb-4">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
                  <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <BFormGroup size="md" label="Όνομα *">
                      <BInputGroup size="md">
                        <BInputGroupPrepend>
                          <BInputGroupText>
                            <BIcon icon="person" />
                          </BInputGroupText>
                        </BInputGroupPrepend>
                        <BFormInput
                          id="name"
                          v-model="form.name"
                          type="text"
                          placeholder="Εισάγετε όνομα"
                          data-test="name"
                          autofocus
                        />
                      </BInputGroup>

                      <BFormText
                        text-variant="danger"
                        data-test="name-error"
                        v-if="errors[0]"
                      >
                        Παρακαλώ εισάγετε το όνομα χρήστη
                      </BFormText>
                    </BFormGroup>
                  </ValidationProvider>

                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ errors, failedRules }"
                  >
                    <BFormGroup size="md" label="Email *" class="mb-5">
                      <BInputGroup size="md">
                        <BInputGroupPrepend>
                          <BInputGroupText>
                            <BIcon icon="envelope" />
                          </BInputGroupText>
                        </BInputGroupPrepend>
                        <BFormInput
                          id="email"
                          v-model="form.email"
                          type="email"
                          placeholder="Εισάγετε email"
                          data-test="email"
                        />
                      </BInputGroup>
                      <BFormText
                        text-variant="danger"
                        data-test="email-error"
                        v-if="failedRules.required"
                      >
                        Παρακαλώ εισάγετε το email του χρήστη
                      </BFormText>
                      <BFormText
                        text-variant="danger"
                        data-test="email-error"
                        v-else-if="failedRules.email"
                      >
                        Παρακαλώ εισάγετε ενα έγκυρο email
                      </BFormText>
                      <BFormText
                        text-variant="danger"
                        data-test="email-error"
                        v-else-if="errors[0]"
                      >
                        {{ errors[0] }}
                      </BFormText>
                    </BFormGroup>
                  </ValidationProvider>
                  <BRow class="justify-content-end">
                    <BCol>
                      <BButton
                        class="mb-3"
                        @click="handleReset"
                        block
                        data-test="reset-button"
                      >
                        <BIcon icon="arrow-counterclockwise" />
                        Επαναφορά
                      </BButton>

                      <BaseButtonSubmit
                        variant="primary"
                        icon="check-circle"
                        :block="true"
                        :isSaving="isSaving"
                        title="Αποθήκευση"
                      />
                    </BCol>
                  </BRow>
                </BForm>
              </ValidationObserver>
            </BCol>
          </BRow>
        </BCardBody>
      </BCard>
    </template>
  </Layout>
</template>

<script>
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import Layout from '../components/OrganizationLayout';

export default {
  components: {
    Layout,
    BaseButtonSubmit,
  },

  data: () => ({
    form: {
      email: '',
      name: '',
    },
    isSaving: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Οργανισμός',
        to: { name: 'organization' },
      },
      {
        text: 'Χρήστες',
        to: { name: 'organization.users.list' },
      },
      {
        text: 'Προσθήκη Νέου Χρήστη',
        active: true,
      },
    ],
  }),

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/users', this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Ο Χρήστης Προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'organization.users.list' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία Προσθήκης Χρήστη',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        email: '',
        name: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
