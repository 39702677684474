import createLogger from 'vuex/dist/logger';
import { saveState } from '@/utils/localStorage';

/* eslint-disable */
const localStoragePlugin = store => {
  store.subscribe((mutation, { session }) =>
    saveState({
      session,
    }),
  );
};

export default process.env.NODE_ENV !== 'production'
  ? [createLogger(), localStoragePlugin]
  : [localStoragePlugin];
