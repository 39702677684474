<template>
  <section
    class="d-flex flex-column align-items-center justify-content-between flex-grow-1 min-vh-100"
  >
    <div
      class="container d-flex flex-column align-items-center justify-content-center flex-grow-1 h-100 py-3"
    >
      <img
        src="@/assets/img/logo_horizontal.svg"
        alt="Logo"
        class="mb-3 logo"
      />

      <div class="w-100 pt-3">
        <div class="form-wrapper mx-auto">
          <!-- Sign in view-->
          <div class="show" id="signin-view">
            <h1 class="h2 text-center">Συνδεθείτε</h1>
            <p class="font-size-ms text-muted mb-4">
              Συνδεθείτε στο διαχειριστικό περιβάλλον χρησιμοποιώντας το email
              και τον κωδικό σας.
            </p>

            <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
              <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors, failedRules }"
                >
                  <BFormGroup size="md" label="Email">
                    <BInputGroup size="md">
                      <BInputGroupPrepend>
                        <BInputGroupText>
                          <BIcon icon="envelope" />
                        </BInputGroupText>
                      </BInputGroupPrepend>
                      <BFormInput
                        id="email"
                        v-model="form.email"
                        type="email"
                        placeholder="Εισάγετε το email σας"
                        data-test="email"
                        autofocus
                      />
                    </BInputGroup>
                    <BFormText
                      text-variant="danger"
                      data-test="email-error"
                      v-if="failedRules.required"
                    >
                      Παρακαλώ εισάγετε το email σας
                    </BFormText>
                    <BFormText
                      text-variant="danger"
                      data-test="email-error"
                      v-else-if="failedRules.email"
                    >
                      Παρακαλώ εισάγετε ενα έγκυρο email
                    </BFormText>
                    <BFormText
                      text-variant="danger"
                      data-test="email-error"
                      v-else-if="errors[0]"
                    >
                      {{ errors[0] }}
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>

                <ValidationProvider
                  name="password"
                  rules="required|min:8"
                  v-slot="{ errors, failedRules }"
                >
                  <BFormGroup size="md" label="Κωδικός">
                    <BInputGroup size="md">
                      <BInputGroupPrepend>
                        <BInputGroupText>
                          <BIcon icon="lock" />
                        </BInputGroupText>
                      </BInputGroupPrepend>
                      <BFormInput
                        id="password"
                        v-model="form.password"
                        type="password"
                        placeholder="Εισάγετε τον κωδικό σας"
                        data-test="password"
                        autocomplete="new-password"
                      />
                    </BInputGroup>
                    <BFormText
                      text-variant="danger"
                      data-test="password-error"
                      v-if="failedRules.min"
                    >
                      O κωδικός πρέπει να περιέχει τουλάχιστο 8 χαρακτήρες
                    </BFormText>
                    <BFormText
                      text-variant="danger"
                      data-test="password-error"
                      v-else-if="errors[0]"
                    >
                      Παρακαλώ εισάγετε τoν κωδικό σας
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>

                <div class="text-right form-group">
                  <RouterLink
                    class="nav-link-style font-size-ms"
                    :to="{ name: 'forgotPassword' }"
                    data-test="forgot-password"
                  >
                    Ξέχασες τον κωδικό;
                  </RouterLink>
                </div>

                <VueRecaptcha
                  ref="loginRecaptcha"
                  size="invisible"
                  :sitekey="captchaKey"
                  :load-recaptcha-script="true"
                  @verify="onVerify"
                  @expired="onExpired"
                >
                  <BFormText
                    text-variant="danger"
                    class="text-center mb-2"
                    v-if="error400"
                  >
                    {{ error400 }}
                  </BFormText>
                  <BaseButtonSubmit
                    variant="primary"
                    :block="true"
                    :isSaving="isSaving"
                    :isDisabled="invalid"
                    title="Σύνδεση"
                  />
                </VueRecaptcha>
              </BForm>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <footer class="cs-footer">
      <div class="container font-size-sm mb-0 py-3">
        <span class="text-muted mr-1">
          © All rights reserved. Made with ♥ by </span
        ><a
          class="nav-link-style font-weight-normal"
          rel="noopener"
          href="https://commercex.gr"
          target="_blank"
        >
          CommerceX
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';

import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
    VueRecaptcha,
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      isSaving: false,
      captchaKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      error400: '',
    };
  },

  methods: {
    ...mapActions({ loginUser: 'session/loginUser' }),

    async handleForm() {
      try {
        const loginRecaptcha = this.$refs.loginRecaptcha;
        await loginRecaptcha.execute();
      } catch {
        this.$swal({
          toast: true,
          title: 'Σφάλμα',
          text: 'Η σύνδεση απέτυχε!',
          icon: 'error',
          timer: 3000,
          position: 'bottom-end',
        });
      }
    },

    onExpired() {
      this.$swal({
        toast: true,
        title: 'Σφάλμα',
        text: 'Προέκυψε κάποιο σφάλμα παρακαλώ δοκιμάστε ξανά',
        icon: 'error',
        timer: 3000,
        button: false,
        position: 'bottom-end',
      });
    },

    async onVerify(token) {
      try {
        this.isSaving = true;
        this.error400 = '';
        axios.defaults.baseURL = 'https://api.pharme.gr';
        const response = await axios.post('api/captcha', {
          token,
        });
        if (response.error) {
          throw new Error('Η επικύρωση του Captcha απέτυχε');
        }

        await this.loginUser({ user: this.form });
        this.$router.push({ name: 'home' });

        this.$swal({
          toast: true,
          title: 'Επιτυχία',
          text: 'Η σύνδεση έγινε με επιτυχία!',
          icon: 'success',
          timer: 3000,
          position: 'bottom-end',
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.error400 = data.message;
          return;
        }
        this.$swal({
          title: 'Error',
          text: 'Αδυναμία Σύνδεσης',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 250px;

  @include breakpoint(md) {
    width: 320px;
  }
}
</style>
