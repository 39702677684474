
import { Component, Vue, Watch } from 'vue-property-decorator';
import request from '@/utils/request';
import { isEmpty, pick } from 'lodash';
import {
  Address,
  CartItem,
  CartItems,
  CheckoutMethod,
  CouponType,
  Customer,
  Invoice,
  OrderAddress,
  OrderMethod,
  Product,
  SendMethod,
  SendMethodSlug,
} from '@/types';
import BaseButtonSubmit from '@/components/BaseButtonSubmit.vue';
import DynamicSelectMenuCustomers from '@/components/DynamicSelectMenuCustomers.vue';
import DynamicSelectMenuProducts from '@/components/DynamicSelectMenuProducts.vue';
import OrdersProductsTable from './components/OrdersProductsTable.vue';
import OrdersDetails from './components/OrdersDetails.vue';
import { calculateSendCharges } from '@/utils/calculations';

@Component({
  components: {
    DynamicSelectMenuProducts,
    DynamicSelectMenuCustomers,
    OrdersProductsTable,
    OrdersDetails,
    BaseButtonSubmit,
  },
})
export default class OrdersAdd extends Vue {
  form = {
    is_gift: false,
    gift_wishes: null,
    gift_cost: 0,
    send_charge: 0,
    checkout_charge: 0,
    customer_id: '',
    coupon_code: '',
    charge_address: {
      firstName: '',
      lastName: '',
      streetName: '',
      streetNumber: '',
      zipcode: '',
      city: '',
      region: '',
      mobile: '',
      telephone: '',
    } as OrderAddress,
    email: '',
    type: 'profile',
    products: [] as CartItem[],
    checkout_method_id: '',
    send_method_id: '',
    order_method_id: '',
    send_address: null as OrderAddress | null,
    invoice: null as Invoice | null,
    notes: '',
    courier_notes: '',
  };
  coupon_value = 0;
  coupon_type = '';
  coupon_min_price = 0;
  sameAddress = true;
  selectedCustomer: Customer | null = null;
  selectedProducts: CartItems = {};
  selectedProductsTotalSellPrice = 0;
  selectedProductsTotalFinalPrice = 0;
  selectedCustomerAddresses: Address[] = [];
  selectedCustomerAddress: string | null = null;
  selectedOrderMethod = 'receipt';
  sendMethods: SendMethod[] = [];
  checkoutMethods: CheckoutMethod[] = [];
  orderMethods: OrderMethod[] = [];
  isLoading = false;
  isSaving = false;
  sendCharges: any = {};
  vatOffices: any[] = [];
  settings: any;
  totalFinalPrice = 0;
  totalWeight = 0;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Παραγγελίες',
      to: { name: 'orders' },
    },
    {
      text: 'Προσθήκη',
      active: true,
    },
  ];

  get hasCustomerAddresses() {
    return !isEmpty(this.selectedCustomerAddresses);
  }

  get addressesOptions() {
    return this.selectedCustomerAddresses.map(address => ({
      value: address.id,
      text: `${address.region}, ${address.city}, ${address.zipcode}, ${address.streetName} ${address.streetNumber}`,
    }));
  }

  get isInvoice() {
    return this.selectedOrderMethod === 'invoice';
  }

  get hasProducts() {
    return !isEmpty(this.selectedProducts);
  }

  get isPharmacy() {
    if (this.form.send_method_id && !isEmpty(this.sendMethods)) {
      const method = this.sendMethods.find(
        method => method.id === this.form.send_method_id,
      );

      if (method) {
        return method.slug === SendMethodSlug.StorePickup;
      }
    }

    return false;
  }

  @Watch('selectedCustomerAddress', { immediate: true })
  handleSelectedCustomerAddressChange(newVal: string | null) {
    const selectedAddress = this.selectedCustomerAddresses.find(
      (address: Address) => address.id === newVal,
    );

    if (selectedAddress) {
      this.form = {
        ...this.form,
        charge_address: {
          ...pick(selectedAddress, [
            'firstName',
            'lastName',
            'streetName',
            'streetNumber',
            'zipcode',
            'city',
            'region',
            'mobile',
            'telephone',
          ]),
        },
      };
    } else {
      this.form = {
        ...this.form,
        charge_address: {
          firstName: '',
          lastName: '',
          streetName: '',
          streetNumber: '',
          zipcode: '',
          city: '',
          region: '',
          mobile: '',
          telephone: '',
        },
      };
    }
  }

  @Watch('selectedProducts', { immediate: true })
  handleSelectedProductsChange(newVal: CartItems) {
    const products = Object.values(newVal).map(({ count, item }: CartItem) => {
      return {
        count,
        item,
      };
    });

    const itemsSellPrice = Object.values(products).reduce(
      (acc, item) => acc + item.count * item.item.sell_price,
      0,
    );

    this.selectedProductsTotalSellPrice = parseFloat(itemsSellPrice.toFixed(2));

    const itemsFinalPrice = Object.values(products).reduce(
      (acc, item) => acc + item.count * item.item.final_price,
      0,
    );

    this.selectedProductsTotalFinalPrice = parseFloat(
      itemsFinalPrice.toFixed(2),
    );

    if (isEmpty(products)) {
      this.handleDeleteCoupon();
    } else if (this.coupon_value !== 0) {
      this.validateCoupon();
    }

    this.totalFinalPrice = this.handleTotalFinalPriceChange();
    this.totalWeight = this.handleTotalWeightChange();
    this.updateSendCharges();

    this.form = {
      ...this.form,
      products,
    };
  }

  validateCoupon() {
    const isValidAmount =
      this.selectedProductsTotalSellPrice >= this.coupon_min_price &&
      this.selectedProductsTotalFinalPrice >= this.coupon_value;

    if (!isValidAmount) {
      this.handleDeleteCoupon();
    }
  }

  @Watch('isInvoice', { immediate: true })
  handleIsInvoiceChange(newVal: boolean) {
    this.form = {
      ...this.form,
      invoice: newVal
        ? {
            company: '',
            profession: '',
            vatNumber: '',
            vatOffice: '',
          }
        : null,
    };
  }

  @Watch('sameAddress', { immediate: true })
  handleSameAddressChange(newVal: boolean) {
    this.form = {
      ...this.form,
      send_address: newVal
        ? null
        : {
            firstName: '',
            lastName: '',
            streetName: '',
            streetNumber: '',
            zipcode: '',
            city: '',
            region: '',
            mobile: '',
            telephone: '',
          },
    };
  }

  @Watch('form.checkout_method_id', { immediate: true })
  async handleCheckoutMethodChange(newVal: string) {
    try {
      const selectedMethod = this.checkoutMethods.find(
        method => method.id === newVal,
      );
      if (selectedMethod) {
        this.form = {
          ...this.form,
          checkout_charge: selectedMethod.charge,
        };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch settings:', error);
    }
  }

  handleTotalFinalPriceChange() {
    const itemsPrice = Object.values(this.selectedProducts).reduce(
      (acc, item) => acc + item.count * item.item.final_price,
      0,
    );

    let price = itemsPrice;

    const hasCoupon = this.coupon_value !== 0;
    if (hasCoupon) {
      if (this.coupon_type === CouponType.Percentage) {
        price *= (100 - Number(this.coupon_value)) / 100;
      }

      if (this.coupon_type === CouponType.Amount) {
        price -= Number(this.coupon_value);

        price = price < 0 ? 0 : price;
      }
      price = parseFloat(price.toFixed(2));
    }

    return parseFloat(price.toFixed(2));
  }

  handleTotalWeightChange() {
    const weight = Object.values(this.selectedProducts)
      .reduce((acc, item) => {
        // Ensure count and weight are treated as numbers, default to 0 if not a number
        const count = Number(item.count) || 0;
        const itemWeight = Number(item.item.weight) || 0;

        return acc + count * itemWeight;
      }, 0)
      .toFixed(2);

    // Parse the calculated weight, default to 0 if NaN
    const parsedWeight = parseFloat(weight);
    return isNaN(parsedWeight) ? 0 : parsedWeight;
  }

  @Watch('isPharmacy', { immediate: true })
  handleIsPharmacyChange() {
    this.updateSendCharges();
  }

  async updateSendCharges() {
    if (this.isPharmacy) {
      this.form = {
        ...this.form,
        send_charge: 0,
      };
    } else {
      try {
        const response = await request.get('/send-charges');
        this.sendCharges = response.data.sendCharges;
        this.form = {
          ...this.form,
          send_charge: calculateSendCharges(
            this.isPharmacy,
            this.totalFinalPrice,
            this.totalWeight,
            { sendCharges: this.sendCharges },
          ),
        };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Failed to fetch send charges:', error);
      }
    }
  }

  @Watch('sendMethods', { immediate: true })
  handleSendMethodsChange(newVal: SendMethod[]) {
    if (!isEmpty(newVal)) {
      this.form = {
        ...this.form,
        send_method_id: newVal[0].id,
      };
    }
  }

  @Watch('checkoutMethods', { immediate: true })
  handleCheckoutMethodsChange(newVal: SendMethod[]) {
    if (!isEmpty(newVal)) {
      this.form = {
        ...this.form,
        checkout_method_id: newVal[0].id,
      };
    }
  }

  @Watch('form.is_gift', { immediate: true })
  handleIsGiftChange(newVal: boolean) {
    if (newVal) {
      this.form = {
        ...this.form,
        gift_cost: 1,
      };
    } else {
      this.form = {
        ...this.form,
        gift_cost: 0,
        gift_wishes: null,
      };
    }
  }

  handleDeleteCoupon() {
    this.coupon_value = 0;
    this.coupon_type = '';
    this.coupon_min_price = 0;
    this.form.coupon_code = '';
  }

  async handleApplyCoupon() {
    try {
      const response = await request.get(
        `/coupons/${this.form.coupon_code}/code`,
      );

      this.coupon_value = response.data.data.value;
      this.coupon_type = response.data.data.type;
      this.coupon_min_price = response.data.data.min_price;

      this.validateCoupon();

      if (this.coupon_value === 0) {
        throw new Error('Coupon is not valid');
      }

      this.$swal({
        title: 'Επιτυχία',
        text: 'Ο κωδικός κουπονιού είναι έγκυρος',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (error) {
      this.$swal({
        title: 'Σφάλμα',
        text: 'Ο κωδικός κουπονιού δεν είναι έγκυρος',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
      this.form.coupon_code = '';
    }
  }

  async created() {
    try {
      this.isLoading = true;

      const response = await Promise.all([
        request.get('/send-methods', {
          params: {
            paginated: false,
          },
        }),
        request.get('/checkout-methods', {
          params: {
            paginated: false,
          },
        }),
        request.get(`/vat-offices`),
        request.get('/settings'),
      ]);

      this.sendMethods = response[0].data.sendMethods;
      this.vatOffices = response[2].data.data.map((office: any) => ({
        value: office.id,
        text: office.title,
      }));
      this.settings = response[3].data.settings;
      this.orderMethods = this.settings.orderMethods;
      this.checkoutMethods = this.settings.checkoutMethods.filter(
        (method: CheckoutMethod) => method.slug !== 'credit_card',
      );
      this.form.order_method_id = this.orderMethods[0].id;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα δεδομένα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    if (this.sameAddress) {
      this.form.send_address = { ...this.form.charge_address };
    }

    (this.$refs.form as HTMLFormElement)
      .validate()
      .then(async (success: boolean) => {
        if (success) {
          try {
            this.isSaving = true;

            await request.post('/orders', this.form);

            this.$swal({
              title: 'Επιτυχία',
              text: 'H παραγγελία προστέθηκε',
              icon: 'success',
              timer: 3000,
            });

            await this.$router.push({ name: 'orders.all' });
          } catch (err) {
            const { status, data } = err.response;
            if (status === 400) {
              (this.$refs.form as HTMLFormElement).setErrors(data.messages);
            }

            this.$swal({
              title: 'Σφάλμα',
              text: 'Αδυναμία προσθήκης παραγγελίας',
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          } finally {
            this.isSaving = false;
          }
        } else {
          this.$swal({
            toast: true,
            title: 'Σφάλμα',
            text: 'Παρακαλώ συμπληρώστε τα υποχρεωτικά πεδία',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
            position: 'bottom-end',
          });
        }
      });
  }

  handleCustomerSelect(customer: Customer) {
    this.selectedCustomer = customer;
    this.form = {
      ...this.form,
      customer_id: customer?.id ?? '',
      email: customer?.email ?? '',
    };

    if (customer) {
      this.getCustomerAddresses();
    } else {
      this.selectedCustomerAddresses = [];
      this.selectedCustomerAddress = null;
    }
  }

  handleProductsSelect(product: Product) {
    this.selectedProducts = {
      ...this.selectedProducts,
      [product.id]: {
        count: 1,
        item: {
          ...product,
          photo: product.photos[0],
        },
      },
    };
  }

  async getCustomerAddresses() {
    try {
      this.isLoading = true;

      const { data } = await request.get(
        `/customers/${this.form.customer_id}/addresses`,
      );

      this.selectedCustomerAddresses = data.addresses;
      this.selectedCustomerAddress = !isEmpty(this.selectedCustomerAddresses)
        ? this.selectedCustomerAddresses[0].id
        : null;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι διευθύνσεις του πελάτη',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleChangeCount(count: number, id: string) {
    this.$nextTick(() => {
      this.selectedProducts = {
        ...this.selectedProducts,
        [id]: {
          ...this.selectedProducts[id],
          count,
        },
      };
    });
  }

  handleDeleteProduct(id: string) {
    const products = Object.values(this.selectedProducts).reduce(
      (acc: CartItems, { count, item }: { count: number; item: Product }) => {
        if (item.id !== id) {
          acc = {
            ...acc,
            [item.id]: {
              count,
              item,
            },
          };
        }

        return acc;
      },
      {},
    );

    this.$nextTick(() => {
      this.selectedProducts = {
        ...products,
      };
    });
  }

  handleReset() {
    this.form = {
      customer_id: '',
      charge_address: {
        firstName: '',
        lastName: '',
        streetName: '',
        streetNumber: '',
        zipcode: '',
        city: '',
        region: '',
        mobile: '',
        telephone: '',
      } as OrderAddress,
      email: '',
      type: 'profile',
      products: [] as CartItem[],
      checkout_method_id: '',
      send_method_id: '',
      send_address: null as OrderAddress | null,
      invoice: null as Invoice | null,
      notes: '',
      courier_notes: '',
      is_gift: false,
      gift_wishes: null,
      gift_cost: 0,
      send_charge: 0,
      checkout_charge: 0,
      order_method_id: this.orderMethods[0].id,
      coupon_code: '',
    };
    this.sameAddress = true;
    this.selectedCustomer = null;
    this.selectedCustomerAddresses = [];
    this.selectedCustomerAddress = null;
    this.selectedOrderMethod = 'receipt';

    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).reset();
      this.$refs.products as Vue & { handleReset: () => void };
      this.$refs.customer as Vue & { handleReset: () => void };
    });
  }
}
