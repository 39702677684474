
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { Customer, Loading } from '@/types';
import request from '@/utils/request';

@Component
export default class DynamicSelectMenuCustomers extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  readonly clearable!: boolean;
  @Prop({ type: String, required: false, default: 'fullName' })
  readonly label!: string;
  @Prop({ type: String, required: false, default: 'Αναζήτηση...' })
  readonly placeholder!: string;
  @Prop({ type: Boolean, required: false, default: false })
  readonly required!: boolean;
  @Prop({ type: Boolean, required: false, default: true })
  readonly searchable!: boolean;
  @Prop({ type: Object, required: false, default: null })
  readonly value!: Customer;
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  selected: Customer | null = null;
  options: Customer[] = [];

  @Watch('value', { immediate: true })
  handleValueChange(newVal: Customer) {
    this.selected = newVal;
  }

  handleSearch = debounce(this.onSearch, 500);

  async onSearch(search: string, loading: Loading) {
    if (search) {
      try {
        loading(true);

        const { data } = await request.get('/customers', {
          params: {
            paginated: false,
            filter: search,
          },
        });

        this.options = data.customers;
      } catch (err) {
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεν μπόρεσαν να βρεθούν αποτελέσματα',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        loading(false);
      }
    }
  }

  handleInput(value: Customer) {
    this.options = [];
    this.$emit('select-customer', value);
  }

  handleReset() {
    this.options = [];
  }
}
