
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CartItem } from '@/types';
import { BvEvent } from 'bootstrap-vue';

@Component
export default class OrdersProductsTable extends Vue {
  @Prop({ type: Object, required: false }) readonly product!: CartItem;
  @Prop({ type: Function, required: true }) readonly onChangeCount!: (
    e: BvEvent,
    id: string,
  ) => void;

  get isAlwaysAvailable() {
    return this.product.item.always_available;
  }

  get stock() {
    // When a product is always available we set the maximum stock number to 1000 otherwise we set it with product's stock value
    return this.isAlwaysAvailable ? 1000 : this.product.item.stock;
  }
}
