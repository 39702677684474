<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex justify-content-between align-items-center border-top">
      <div class="tab-header">
        {{ model.title }}
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left mb-5">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm
          @submit.prevent="handleSubmit(handleForm)"
          novalidate
          data-test="form"
        >
          <BCardBody>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Τίτλος *">
                <BFormInput
                  id="title"
                  v-model="form.title"
                  type="text"
                  placeholder="Εισάγετε τον τίτλο της σελίδας"
                  data-test="title"
                  autofocus
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε τον τίτλο της σελίδας
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <BFormGroup label="Slug *">
              <BInputGroup :prepend="`${eshopURL}/info/`">
                <BFormInput
                  id="slug"
                  v-model="form.slug"
                  type="text"
                  placeholder="Εισάγετε το slug της σελίδας"
                  data-test="slug"
                  disabled
                />
                <BInputGroupAppend>
                  <BButton
                    variant="primary"
                    target="_blank"
                    :href="`${eshopURL}/info/${model.slug}`"
                  >
                    <BIcon icon="chevron-right" />
                  </BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BFormGroup>

            <ValidationProvider
              title="content"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Περιεχόμενο *">
                <ckeditor
                  :editor="editor"
                  v-model="form.content"
                  data-test="content"
                  :config="editorConfig"
                  tag-name="textarea"
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε περιεχόμενο για τη σελίδα
                </BFormText>
              </BFormGroup>
            </ValidationProvider>
          </BCardBody>

          <BCardFooter class="sticky-footer">
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import EditorMixin from '@/mixins/EditorMixin';

export default {
  components: {
    BaseButtonSubmit,
  },

  mixins: [EditorMixin],

  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Στατικές Σελίδες',
        to: { name: 'content.pages' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
  }),

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/pages/${this.$route.params.slug}`);

      this.model = data.page;
      this.initForm();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί η σελίδα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    initForm() {
      this.form = {
        ...pick(this.model, ['title', 'slug']),
        content: this.model.content || '',
      };
    },

    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/pages/${this.$route.params.slug}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία της σελίδας ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία της σελίδας δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.initForm();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
