import request from '@/utils/request';

export default {
  methods: {
    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το άρθρο: ${this.model.title};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/posts/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Το άρθρο ${this.model.title} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            await this.$router.push({ name: 'posts.list' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Το άρθρο ${this.model.title} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },
  },
};
