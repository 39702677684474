import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales';

Vue.use(VueI18n);
Vue.config.lang = 'el';

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'el',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'el',
  messages: locales,
});
