
import { Component, Vue } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import request from '@/utils/request';
import { Category } from '@/types';
import Layout from './components/CategoriesEditLayout.vue';
import CategoriesEditMethodsMixin from './mixins/CategoriesEditMethodsMixin';

@Component({
  components: {
    Layout,
    Draggable,
  },
  mixins: [CategoriesEditMethodsMixin],
})
export default class CategoriesEditOrder extends Vue {
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Κατηγορίες',
      to: { name: 'categories' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'categories.edit.general' },
    },
    {
      text: 'Σειρά Προβολής',
      active: true,
    },
  ];
  isLoading = false;
  categories: Category[] = [];
  model: Category | {} = {};

  async created() {
    await this.getCategories();
    const { data } = await request.get(`/categories/${this.$route.params.id}`);

    this.model = data.category;
  }

  async getCategories() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/categories/${this.$route.params.id}/siblings`,
      );
      this.categories = data.categories ?? [];
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι κατηγορίες',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleReorder({
    moved: { oldIndex, newIndex },
  }: {
    moved: { oldIndex: number; newIndex: number };
  }) {
    try {
      this.isLoading = true;
      const movedItem = this.categories.find(
        (item, index) => index === oldIndex,
      ) as Category;
      const remainingItems = this.categories.filter(
        (item, index) => index !== oldIndex,
      );

      const reorderedCategories =
        [
          ...remainingItems.slice(0, newIndex),
          movedItem,
          ...remainingItems.slice(newIndex),
        ] || [];

      this.categories = reorderedCategories;

      const reorderedCategoriesIds = reorderedCategories.map(
        category => category?.id,
      );

      const data = {
        categories: reorderedCategoriesIds,
      };

      await request.put(
        `/categories/${this.$route.params.id}/siblings/reorder`,
        data,
      );
      await this.getCategories();

      this.$swal({
        toast: true,
        title: 'Επιτυχία',
        text: 'Η κατηγορία αναδιατάχθηκε',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
        position: 'bottom-end',
      });
    } catch (err) {
      this.$swal({
        toast: true,
        title: 'Σφάλμα',
        text: 'Αδυναμία αναδιάταξης κατηγορίας',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        position: 'bottom-end',
      });
    } finally {
      this.isLoading = false;
    }
  }
}
