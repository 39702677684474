<template>
  <div>
    <BBreadcrumb :items="breadcrumbItems" />

    <BCard no-body>
      <BCardHeader>
        <BCardTitle class="mb-0" title-tag="h5">
          Marketing
        </BCardTitle>
      </BCardHeader>

      <BCardBody></BCardBody>
    </BCard>
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Marketing',
        active: true,
      },
    ],
  }),
};
</script>
