
import { Component, Watch, Vue } from 'vue-property-decorator';

import { pick } from 'lodash';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import { Product } from '@/types';
import Layout from './components/ProductsEditLayout.vue';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';

@Component({
  mixins: [EditorMixin, ProductsEditMethodsMixin],
  components: {
    Layout,
  },
})
export default class ProductsEditGeneral extends Vue {
  eshopURL = process.env.VUE_APP_ESHOP_URL;
  availabilities = [];
  brands = [];
  isLoading = false;
  isSaving = false;
  model: Partial<Product> = {};
  form: Partial<Product> = {
    barcodes: [],
  };
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Προϊόντα',
      to: { name: 'products' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'products.edit.general' },
    },
    {
      text: 'Γενικά Στοιχεία',
      active: true,
    },
  ];

  get parentSlugPath() {
    return `${this.eshopURL}/${this.model.brand?.slug}/`;
  }

  get fullHrefPath() {
    return `${this.eshopURL}/${this.model.brand?.slug}/${this.form.slug}`;
  }

  @Watch('isAlwaysAvailable')
  handleIsAlwaysAvailable(newVal: boolean) {
    if (newVal) {
      this.form.stock = this.model.stock;
    }
  }

  get isDeleted() {
    return this.model.deleted_at !== null;
  }

  get isAlwaysAvailable() {
    return this.form.always_available;
  }

  async created() {
    this.eshopURL = process.env.VUE_APP_ESHOP_URL;
    try {
      this.isLoading = true;
      const response = await Promise.all([
        request.get(`/products/${this.$route.params.id}`),
        request.get('/brands', {
          params: {
            paginated: false,
          },
        }),
        request.get(`/availabilities/`),
      ]);

      this.model = response[0].data.product;
      this.brands = response[1].data.brands;
      this.availabilities = response[2].data.availabilities;
      this.initForm();

      this.$nextTick(() => {
        this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το προϊόν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.put(`/products/${this.$route.params.id}`, this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα στοιχεία του προϊόντος ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$emit('get-counters');
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα στοιχεία του προϊόντος δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  initForm() {
    this.form = {
      ...pick(this.model, [
        'title',
        'slug',
        'code',
        'mpn',
        'active',
        'brand_id',
        'barcodes',
        'weight',
        'volume',
        'dimensions',
        'stock',
        'availability_id',
        'is_new',
        'is_offer',
        'is_brochure',
        'is_popular',
        'is_proposal',
        'always_available',
      ]),
      content: this.model.content || '',
      ingredients: this.model.ingredients || '',
      instructions: this.model.instructions || '',
    };
  }

  handleReset() {
    this.initForm();

    this.$nextTick(() => {
      this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
    });
  }

  formatDate(dateString: string | null | undefined) {
    if (!dateString) {
      return '-';
    }

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // Use 24-hour format
    };

    // Convert dateString to Date object
    const date = new Date(dateString);

    const formattedDate = new Intl.DateTimeFormat('el-GR', options).format(
      date,
    );

    return formattedDate;
  }
}
