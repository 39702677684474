export default {
  menuItems: {
    banners: 'Banners',
    home: 'Αρχική',
    products: 'Προϊόντα',
    brands: 'Μάρκες',
    categories: 'Κατηγορίες',
    orders: 'Παραγγελίες',
    customers: 'Πελάτες',
    marketing: 'Marketing',
    coupons: 'Κουπόνια',
    promos: 'Marketing Pages',
    newsletters: 'Newsletter',
    blog: 'Blog',
    posts: 'Άρθρα',
    authors: 'Συγγραφείς',
    stats: 'Στατιστικά',
    postCategories: 'Κατηγορίες Άρθρων',
    content: 'Περιεχόμενο',
    contentPages: 'Στατικές Σελίδες',
    faq: 'Συχνές Ερωτήσεις',
    settings: 'Ρυθμίσεις',
    checkoutMethods: 'Τρόποι πληρωμής',
    sendMethods: 'Τρόποι αποστολής',
    filterGroups: 'Ομάδες Φίλτρων',
  },
};
