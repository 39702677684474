<template>
  <ExistingItems :items="items" title="Υφιστάμενες Ομάδες Φίλτρων">
    <template v-slot:item="props">
      <RouterLink
        :to="{ name: 'filterGroups.edit', params: { id: props.item.id } }"
        class="text-decoration-none"
      >
        {{ props.item.title }}
      </RouterLink>
    </template>
  </ExistingItems>
</template>

<script>
import ExistingItems from '../ExistingItems';

export default {
  components: {
    ExistingItems,
  },
  props: {
    items: Array,
  },
};
</script>
