<template>
  <section
    class="d-flex flex-column align-items-center justify-content-between flex-grow-1 min-vh-100"
  >
    <div
      class="container d-flex flex-column align-items-center justify-content-center flex-grow-1 h-100 py-3"
    >
      <img
        src="@/assets/img/logo_horizontal.svg"
        alt="Logo"
        class="mb-3 logo"
      />

      <div class="w-100 pt-3">
        <div class="form-wrapper mx-auto">
          <!-- Forgot Password view-->

          <h1 class="h2 text-center">Επαναφορά Κωδικού</h1>
          <p class="font-size-ms text-center text-muted mb-4">
            Χρησιμοποιείστε το email σας για να επαναφέρετε τον κωδικό σας.
          </p>

          <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
            <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors, failedRules }"
              >
                <BFormGroup size="md" label="Email">
                  <BInputGroup size="md">
                    <BInputGroupPrepend>
                      <BInputGroupText>
                        <BIcon icon="envelope" />
                      </BInputGroupText>
                    </BInputGroupPrepend>
                    <BFormInput
                      id="email"
                      v-model="user.email"
                      type="email"
                      placeholder="Εισάγετε το email σας"
                      data-test="email"
                      autofocus
                    />
                  </BInputGroup>
                  <BFormText
                    text-variant="danger"
                    data-test="email-error"
                    v-if="failedRules.email"
                  >
                    Παρακαλώ εισάγετε ενα έγκυρο email
                  </BFormText>
                  <BFormText
                    text-variant="danger"
                    data-test="email-error"
                    v-else-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το email σας
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <div class="text-right form-group">
                <RouterLink
                  class="nav-link-style font-size-ms"
                  :to="{ name: 'login' }"
                  data-test="login"
                >
                  Συνδεθείτε στο διαχειριστικό περιβάλλον
                </RouterLink>
              </div>

              <BaseButtonSubmit
                variant="primary"
                :block="true"
                :isSaving="isSaving"
                :isDisabled="invalid"
                title="Επαναφορά"
              />
            </BForm>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <footer class="cs-footer">
      <div class="container font-size-sm mb-0 py-3">
        <span class="text-muted mr-1"
          >© All rights reserved. Made with ♥ by</span
        ><a
          class="nav-link-style font-weight-normal"
          rel="noopener"
          href="https://helloworld.gr/"
          target="_blank"
        >
          Hello World PC
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import request from '@/utils/request';

export default {
  components: {
    BaseButtonSubmit,
  },

  data: () => ({
    user: {
      email: '',
      redirect: '/',
      is_admin: true,
    },
    isSaving: false,
  }),

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/ask-reset-password', this.user);

        // Handle response here

        this.$swal({
          title: 'Επιτυχία',
          text:
            'Έχει σταλεί email με οδηγίες για την επαναφορά του κωδικού σας στο email σας',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Error',
          text: 'Αδυναμία επαναφοράς κωδικού',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 150px;

  @include breakpoint(md) {
    width: 220px;
  }
}
</style>
