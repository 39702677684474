
import { Vue, Component } from 'vue-property-decorator';

import request from '@/utils/request';
import ProductThumbnail from '@/components/ProductThumbnail.vue';
import Layout from '@/views/Dashboard/views/Customers/CustomersEdit/components/CustomersEditLayout.vue';
import { Customer, Product } from '@/types';

@Component({
  components: {
    Layout,
    ProductThumbnail,
  },
})
export default class CustomersEditFavourites extends Vue {
  model = {};
  form = {};
  isSaving = false;
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Πελάτες',
      to: { name: 'customers' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'customers.edit.general' },
    },
    {
      text: 'Αγαπημένα',
      active: true,
    },
  ];
  fields = [
    '#',
    { label: 'Φώτο', key: 'thumbnail' },
    { label: 'Όνομα', key: 'title', sortable: true },
    { label: 'Stock', key: 'stock' },
    { label: 'Barcodes', key: 'barcodes' },
    { label: 'Π.Λ Τιμή (€)', key: 'sell_price', sortable: true },
    { label: 'Έκπτωση (%)', key: 'discount', sortable: true },
    { label: 'Τελική τιμή (€)', key: 'final_price', sortable: true },
    { label: 'Ενεργό', key: 'active' },
  ];
  items: Product[] = [];
  customer: Customer | null = null;

  async productsProvider() {
    try {
      this.isLoading = true;

      const { data } = await request.get(
        `/customers/${this.$route.params.id}/favourites/`,
      );
      const { data: customerData } = await request.get(
        `/customers/${this.$route.params.id}`,
      );

      this.customer = customerData.customer;
      this.items = data.favourites;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα αγαπημένα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε τον πελάτη: ${this.customersName};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/customers/${this.$route.params.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Ο πελάτης ${this.customersName} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          await this.$router.push({ name: 'customers.all' });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `Ο πελάτης ${this.customersName} δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  get customersName() {
    return this.customer?.fullName ?? '';
  }

  get customersType() {
    return this.customer?.type;
  }
}
