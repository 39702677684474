<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex justify-content-between align-items-center border-top">
      <div class="tab-header">
        Ερώτηση
      </div>

      <BButton variant="primary" @click="handleDelete" size="sm">
        <BIcon icon="trash" class="mr-2" /> Διαγραφή
      </BButton>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm
          @submit.prevent="handleSubmit(handleForm)"
          novalidate
          data-test="form"
        >
          <BCardBody>
            <ValidationProvider
              name="question"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup size="md" label="Ερώτηση *">
                <BTextarea
                  v-model="form.question"
                  name="question"
                  data-test="question"
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε την ερώτηση
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              name="answer"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Απάντηση *">
                <BTextarea
                  v-model="form.answer"
                  name="answer"
                  data-test="question"
                />

                <BFormText
                  text-variant="danger"
                  data-test="slug-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε την απάντηση
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider name="active" rules="required">
              <BFormGroup size="md" label="Ενεργή" class="mb-0">
                <BFormCheckbox v-model="form.active" switch />
              </BFormGroup>
            </ValidationProvider>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import EditorMixin from '@/mixins/EditorMixin';

export default {
  components: {
    BaseButtonSubmit,
  },

  mixins: [EditorMixin],

  data: () => ({
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Συχνές Ερωτήσεις',
        to: { name: 'content.questions' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
  }),

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/questions/${this.$route.params.id}`);

      this.model = data.question;
      this.form = pick(this.model, ['question', 'answer', 'active']);

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί η μάρκα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/questions/${this.$route.params.id}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία της ερώτησης ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία της ερώτησης δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: 'Θέλετε να διαγράψετε την ερώτηση;',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/questions/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: 'Η ερώτηση διαγράφηκε επιτυχώς',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            await this.$router.push({ name: 'content.questions' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: 'Η ερώτηση δεν διεγράφη',
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleReset() {
      this.form = pick(this.model, ['question', 'answer', 'active']);
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
