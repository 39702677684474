
import { Component, Vue, Watch } from 'vue-property-decorator';
import request from '@/utils/request';
import { cloneDeep, isEmpty, isEqual, pick } from 'lodash';
import * as base64js from 'base64-js';
import {
  Address,
  CartItem,
  CartItems,
  CheckoutMethod,
  CouponType,
  Customer,
  Invoice,
  Note,
  Order,
  OrderAddress,
  OrderMethod,
  OrderStatus,
  Product,
  SendMethod,
  SendMethodSlug,
} from '@/types';
import BaseButtonSubmit from '@/components/BaseButtonSubmit.vue';
import DynamicSelectMenuCustomers from '@/components/DynamicSelectMenuCustomers.vue';
import DynamicSelectMenuProducts from '@/components/DynamicSelectMenuProducts.vue';
import OrdersProductsTable from '../components/OrdersProductsTable.vue';
import OrdersDetails from '../components/OrdersDetails.vue';
import OrdersStatuses from '../components/OrdersStatuses.vue';
import Layout from './components/OrdersEditLayout.vue';
import OrdersPrint from '../components/OrdersPrint.vue';
import { calculateSendCharges } from '@/utils/calculations';

@Component({
  components: {
    DynamicSelectMenuProducts,
    DynamicSelectMenuCustomers,
    OrdersProductsTable,
    OrdersDetails,
    OrdersStatuses,
    BaseButtonSubmit,
    Layout,
    OrdersPrint,
  },
})
export default class OrdersEditGeneral extends Vue {
  order = {} as Order;
  model = {} as Order;
  form = {
    customer_id: '',
    charge_address: {
      firstName: '',
      lastName: '',
      streetName: '',
      streetNumber: '',
      zipcode: '',
      city: '',
      region: '',
      mobile: '',
      telephone: '',
    } as OrderAddress,
    email: '',
    type: 'profile',
    products: [] as CartItem[],
    checkout_method_id: '' as string | null, // Allow null
    order_method_id: '' as string | null, // Allow null
    send_method_id: '' as string | null, // Allow null
    send_address: null as OrderAddress | null,
    invoice: null as Invoice | null,
    notes: '',
    courier_notes: '',
    coupon_code: '',
    coupons_id: '',
    coupons_type: '',
    is_gift: false,
    gift_wishes: '',
    gift_cost: 0,
    send_charge: 0,
    checkout_charge: 0,
  };
  sameAddress = true;
  selectedCustomer: Customer | null = null;
  selectedProducts: CartItems = {};
  selectedCustomerAddresses: Address[] = [];
  selectedCustomerAddress: string | null = null;
  selectedOrderMethod = 'receipt';
  sendMethods: SendMethod[] = [];
  checkoutMethods: CheckoutMethod[] = [];
  orderMethods: OrderMethod[] = [];
  adminNotes: Note[] = [];
  isLoading = false;
  isSaving = false;
  coupon_value: number = 0;
  coupon_type: string | null = null;
  coupon_min_price = 0;
  vatOffices: any[] = [];
  totalFinalPrice = 0;
  selectedProductsTotalSellPrice = 0;
  selectedProductsTotalFinalPrice = 0;
  totalWeight = 0;
  sendCharges: any = [];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Παραγγελίες',
      to: { name: 'orders' },
    },
    {
      text: 'Επεξεργασία',
      active: true,
    },
  ];

  get isPharmacy() {
    if (this.form.send_method_id && !isEmpty(this.sendMethods)) {
      const method = this.sendMethods.find(
        method => method.id === this.form.send_method_id,
      );

      if (method) {
        return method.slug === SendMethodSlug.StorePickup;
      }
    }

    return false;
  }

  get orderId() {
    return this.$route.params.id;
  }

  get hasOrder() {
    return !isEmpty(this.order);
  }

  get hasCustomerAddresses() {
    return !isEmpty(this.selectedCustomerAddresses);
  }

  get addressesOptions() {
    return this.selectedCustomerAddresses.map(address => ({
      value: address.id,
      text: `${address.region}, ${address.city}, ${address.zipcode}, ${address.streetName} ${address.streetNumber}`,
    }));
  }

  get isInvoice() {
    return this.selectedOrderMethod === 'invoice';
  }

  get hasProducts() {
    return !isEmpty(this.selectedProducts);
  }

  get isCanceled() {
    return !isEmpty(this.model.deleted_at);
  }

  get isUnfinished() {
    return this.model.is_unfinished === true;
  }

  get customerName() {
    return this.model?.customer?.fullName;
  }

  get hasVouchers() {
    return (this.model?.vouchers ?? []).length > 0;
  }

  @Watch('model', { immediate: true })
  handleModelChange(newVal: Order) {
    if (!isEmpty(newVal)) {
      this.selectedCustomer = newVal.customer;
      if (
        !isEmpty(newVal.send_address) &&
        isEqual(newVal.send_address, newVal.charge_address)
      ) {
        this.sameAddress = true;
      } else {
        this.sameAddress = isEmpty(newVal.send_address);
      }

      if (!isEmpty(newVal.invoice)) {
        this.selectedOrderMethod = 'invoice';
      }

      this.selectedProducts = newVal.products.reduce(
        (acc: CartItems, { count, item }: { count: number; item: Product }) => {
          return {
            ...acc,
            [item.id]: {
              count,
              item,
            },
          };
        },
        {},
      );

      this.$nextTick(() => {
        this.form = {
          ...this.form,
          ...pick(newVal, [
            'customer_id',
            'email',
            'type',
            'checkout_method_id',
            'order_method_id',
            'send_method_id',
            'invoice',
            'notes',
            'courier_notes',
            'is_gift',
            'gift_wishes',
            'checkout_charge',
            'coupon_code',
          ]),
          send_charge: newVal.send_charge ?? 0,
          checkout_charge: newVal.checkout_charge ?? 0,
          charge_address: {
            ...(newVal.charge_address as OrderAddress),
            telephone: newVal.charge_address?.telephone ?? '',
          },
          send_address: !isEmpty(newVal.send_address)
            ? ({
                ...newVal.send_address,
                telephone: newVal.send_address?.telephone ?? '',
              } as OrderAddress)
            : null,
        };

        this.getCustomerAddresses();
      });
    }
  }

  handleTotalFinalPriceChange() {
    let price = Object.values(this.selectedProducts).reduce(
      (acc, item) => acc + item.count * item.item.final_price,
      0,
    );

    const hasCoupon = this.coupon_value !== 0;
    if (hasCoupon) {
      if (this.coupon_type === CouponType.Percentage) {
        price *= (100 - Number(this.coupon_value)) / 100;
      }

      if (this.coupon_type === CouponType.Amount) {
        price -= Number(this.coupon_value);

        price = price < 0 ? 0 : price;
      }
      price = parseFloat(price.toFixed(2));
    }

    return parseFloat(price.toFixed(2));
  }

  handleTotalWeightChange() {
    const weight = Object.values(this.selectedProducts)
      .reduce((acc, item) => {
        // Ensure count and weight are treated as numbers, default to 0 if not a number
        const count = Number(item.count) || 0;
        const itemWeight = Number(item.item.weight) || 0;

        return acc + count * itemWeight;
      }, 0)
      .toFixed(2);

    // Parse the calculated weight, default to 0 if NaN
    const parsedWeight = parseFloat(weight);
    return isNaN(parsedWeight) ? 0 : parsedWeight;
  }

  @Watch('isPharmacy', { immediate: true })
  handleIsPharmacyChange() {
    this.updateSendCharges();
  }

  async updateSendCharges() {
    if (this.isPharmacy) {
      this.form = {
        ...this.form,
        send_charge: 0,
      };
    } else {
      try {
        const response = await request.get('/send-charges');
        this.sendCharges = response.data.sendCharges;

        this.form = {
          ...this.form,
          send_charge: calculateSendCharges(
            this.isPharmacy,
            this.totalFinalPrice,
            this.totalWeight,
            { sendCharges: this.sendCharges },
          ),
        };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Failed to fetch send charges:', error);
      }
    }
  }

  @Watch('form.checkout_method_id', { immediate: true })
  async handleCheckoutMethodChange(newVal: string) {
    try {
      const selectedMethod = this.checkoutMethods.find(
        method => method.id === newVal,
      );
      if (selectedMethod) {
        this.form = {
          ...this.form,
          checkout_charge: selectedMethod.charge,
        };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch settings:', error);
    }
  }

  @Watch('selectedCustomerAddress', { immediate: true })
  handleSelectedCustomerAddressChange(newVal: string | null) {
    const selectedAddress = this.selectedCustomerAddresses.find(
      (address: Address) => address.id === newVal,
    );

    if (selectedAddress) {
      this.form = {
        ...this.form,
        charge_address: {
          ...pick(selectedAddress, [
            'firstName',
            'lastName',
            'streetName',
            'streetNumber',
            'zipcode',
            'city',
            'region',
            'mobile',
            'telephone',
          ]),
        },
      };
    } else {
      this.form = {
        ...this.form,
        charge_address: {
          firstName: '',
          lastName: '',
          streetName: '',
          streetNumber: '',
          zipcode: '',
          city: '',
          region: '',
          mobile: '',
          telephone: '',
        },
      };
    }
  }

  @Watch('selectedProducts', { immediate: true })
  handleSelectedProductsChange(newVal: CartItems) {
    const products = Object.values(newVal).map(({ count, item }: CartItem) => {
      return {
        count,
        item,
      };
    });

    const itemsSellPrice = Object.values(products).reduce(
      (acc, item) => acc + item.count * item.item.sell_price,
      0,
    );

    this.selectedProductsTotalSellPrice = parseFloat(itemsSellPrice.toFixed(2));

    const itemsFinalPrice = Object.values(products).reduce(
      (acc, item) => acc + item.count * item.item.final_price,
      0,
    );

    this.selectedProductsTotalFinalPrice = parseFloat(
      itemsFinalPrice.toFixed(2),
    );

    if (isEmpty(products)) {
      this.handleDeleteCoupon();
    } else if (this.coupon_value !== 0) {
      this.validateCoupon();
    }

    this.totalFinalPrice = this.handleTotalFinalPriceChange();
    this.totalWeight = this.handleTotalWeightChange();
    this.updateSendCharges();

    this.form = {
      ...this.form,
      products,
    };
  }

  validateCoupon() {
    const isValidAmount =
      this.selectedProductsTotalSellPrice >= this.coupon_min_price &&
      this.selectedProductsTotalFinalPrice >= this.coupon_value;

    if (!isValidAmount) {
      this.handleDeleteCoupon();
    }
  }

  @Watch('isInvoice', { immediate: true })
  handleIsInvoiceChange(newVal: boolean) {
    this.form = {
      ...this.form,
      invoice: newVal
        ? {
            company: '',
            profession: '',
            vatNumber: '',
            vatOffice: '',
          }
        : null,
    };
  }

  @Watch('sameAddress', { immediate: true })
  handleSameAddressChange(newVal: boolean) {
    this.form = {
      ...this.form,
      send_address: newVal
        ? this.form.charge_address
        : {
            firstName: '',
            lastName: '',
            streetName: '',
            streetNumber: '',
            zipcode: '',
            city: '',
            region: '',
            mobile: '',
            telephone: '',
          },
    };
  }

  @Watch('form.is_gift')
  onIsGiftChange(newValue: boolean) {
    // If checked, set gift cost to 1
    this.form.gift_cost = newValue ? 1 : 0;
  }

  async created() {
    try {
      this.isLoading = true;

      const response = await Promise.all([
        request.get(`/orders/${this.orderId}`),
        request.get('/send-methods', {
          params: {
            paginated: false,
          },
        }),
        request.get('/checkout-methods', {
          params: {
            paginated: false,
          },
        }),
        request.get('/settings', {
          params: {
            paginated: false,
          },
        }),
        request.get(`/orders/${this.orderId}/notes`),
        request.get(`/vat-offices`),
      ]);

      this.order = response[0].data.order;

      if (this.order.send_charge) {
        this.form.send_charge = this.order.send_charge;
        console.log('this.order.send_charge', this.form.send_charge);
      }

      if (this.order.coupon && this.order.coupon.value) {
        this.coupon_value = this.order.coupon.value;
        if (this.order.coupon.type) {
          this.coupon_type = this.order.coupon.type;
        }
      }
      if (this.order.is_gift) {
        this.form.gift_cost = this.order.gift_cost;
      }
      if (this.order.checkout_charge) {
        this.form.checkout_charge = this.order.checkout_charge;
      }
      this.model = cloneDeep(this.order);
      this.sendMethods = response[1].data.sendMethods;
      this.checkoutMethods = response[2].data.checkoutMethods;
      this.orderMethods = response[3].data.settings.orderMethods;
      this.adminNotes = response[4].data.notes;
      this.vatOffices = response[5].data.data.map((office: any) => ({
        value: office.id,
        text: office.title,
      }));

      // coupons
      if (this.order.coupon) {
        if (this.order.coupon.code) {
          this.form.coupon_code = this.order.coupon.code;
        }
        if (this.order.coupon.id) {
          this.form.coupons_id = this.order.coupon.id;
        }
        if (this.order.coupon.type) {
          this.form.coupons_type = this.order.coupon.type;
        }
      }
    } catch (err) {
      this.$router.push({ name: 'error' });
      await this.$swal({
        title: 'Σφάλμα',
        text: 'Δε μπόρεσε να φορτωθεί η παραγγελία',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleDeleteCoupon() {
    this.coupon_value = 0;
    this.coupon_type = '';
    this.form.coupon_code = '';
    this.coupon_min_price = 0;
  }

  async handleApplyCoupon() {
    try {
      const response = await request.get(
        `/coupons/${this.form.coupon_code}/code`,
      );

      this.coupon_value = response.data.data.value;
      this.coupon_type = response.data.data.type;
      this.coupon_min_price = response.data.data.min_price;

      this.validateCoupon();

      if (this.coupon_value === 0) {
        throw new Error('Coupon is not valid');
      }

      await this.$swal({
        title: 'Επιτυχία',
        text: 'Ο κωδικός κουπονιού είναι έγκυρος',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (error) {
      await this.$swal({
        title: 'Σφάλμα',
        text: 'Ο κωδικός κουπονιού δεν είναι έγκυρος',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
      this.form.coupon_code = '';
    }
  }

  handleForm() {
    (this.$refs.form as HTMLFormElement)
      .validate()
      .then(async (success: boolean) => {
        if (success) {
          try {
            this.isSaving = true;
            await request.put(`/orders/${this.orderId}`, this.form);

            await this.$swal({
              title: 'Επιτυχία',
              text: 'Τα στοιχεία της παραγγελίας ενημερώθηκαν',
              icon: 'success',
              timer: 3000,
            });
          } catch (err) {
            const { status, data } = err.response;
            if (status === 400) {
              (this.$refs.form as HTMLFormElement).setErrors(data.messages);
            }

            await this.$swal({
              title: 'Σφάλμα',
              text: 'Τα στοιχεία της παραγγελίας δεν ενημερώθηκαν',
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          } finally {
            this.isSaving = false;
          }
        } else {
          await this.$swal({
            toast: true,
            title: 'Σφάλμα',
            text: 'Παρακαλώ συμπληρώστε τα υποχρεωτικά πεδία',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
            position: 'bottom-end',
          });
        }
      });
  }

  handleCancel() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ακυρώσετε την παραγγελία: ${this.model.code};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/orders/${this.orderId}`);

          await this.$swal({
            title: 'Επιτυχία',
            text: `H παραγγελία ${this.model.code} ακυρώθηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
          });

          window.location.reload();
        } catch (error) {
          await this.$swal({
            title: 'Σφάλμα',
            text: `H παραγγελία ${this.model.code} δεν ακυρώθηκε`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleRestore() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να επαναφέρετε την παραγγελία: ${this.model.code};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.put(`/restore-orders/${this.orderId}`);

          await this.$swal({
            title: 'Επιτυχία',
            text: `H παραγγελία ${this.model.code} επαναφέρθηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
          });

          window.location.reload();
        } catch (error) {
          await this.$swal({
            title: 'Σφάλμα',
            text: `H παραγγελία ${this.model.code} δεν επαναφέρθηκε`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleFinish() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ολοκληρώσετε την παραγγελία: ${this.model.code};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.put(`/finish-orders/${this.orderId}`);

          await this.$swal({
            title: 'Επιτυχία',
            text: `H παραγγελία ${this.model.code} ολοκληρώθηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
          });

          window.location.reload();
        } catch (error) {
          await this.$swal({
            title: 'Σφάλμα',
            text: `H παραγγελία ${this.model.code} δεν ολοκληρώθηκε`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleProductsSelect(product: Product) {
    this.selectedProducts = {
      ...this.selectedProducts,
      [product.id]: {
        count: 1,
        item: {
          ...product,
          photo: product.photos[0],
        },
      },
    };
  }

  async getCustomerAddresses() {
    try {
      const { data } = await request.get(
        `/customers/${this.form.customer_id}/addresses`,
      );

      this.selectedCustomerAddresses = data.addresses;
    } catch (err) {
      this.$router.push({ name: 'error' });
      await this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι διευθύνσεις του πελάτη',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }

  handleChangeCount(count: number, id: string) {
    this.$nextTick(() => {
      this.selectedProducts = {
        ...this.selectedProducts,
        [id]: {
          ...this.selectedProducts[id],
          count,
        },
      };
    });
  }

  handleDeleteProduct(id: string) {
    const products = Object.values(this.selectedProducts).reduce(
      (acc: CartItems, { count, item }: { count: number; item: Product }) => {
        if (item.id !== id) {
          acc = {
            ...acc,
            [item.id]: {
              count,
              item,
            },
          };
        }

        return acc;
      },
      {},
    );

    this.$nextTick(() => {
      this.selectedProducts = {
        ...products,
      };
    });
  }

  async handleChangeStatus(isChecked: boolean, status: OrderStatus) {
    try {
      this.isLoading = true;
      if (isChecked) {
        await request.put(`/orders/${this.orderId}/statuses/${status.id}`);
      } else {
        await request.delete(`/orders/${this.orderId}/statuses/${status.id}`);
      }

      const { data } = await request.get(`/orders/${this.orderId}/statuses`);

      this.model = {
        ...this.model,
        statuses: data.statuses,
      };

      this.isLoading = false;

      await this.$swal({
        title: 'Επιτυχία',
        text: 'Το status της παραγγελίας άλλαξε επιτυχώς!!',
        icon: 'success',
        timer: 3000,
      });
    } catch (err) {
      this.isLoading = false;
      await this.$swal({
        title: 'Σφάλμα',
        text: 'To αίτημα απέτυχε',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }

  handleReset() {
    if (!isEmpty(this.model)) {
      this.selectedCustomer = this.model.customer;
      this.sameAddress =
        !isEmpty(this.model.send_address) &&
        isEqual(this.model.send_address, this.model.charge_address);

      if (!isEmpty(this.model.invoice)) {
        this.selectedOrderMethod = 'invoice';
      }

      this.selectedProducts = this.model.products.reduce(
        (acc: CartItems, { count, item }: { count: number; item: Product }) => {
          return {
            ...acc,
            [item.id]: {
              count,
              item,
            },
          };
        },
        {},
      );

      this.$nextTick(() => {
        this.form = {
          ...this.form,
          ...pick(this.model, [
            'customer_id',
            'email',
            'type',
            'checkout_method_id',
            'order_method_id',
            'send_method_id',
            'invoice',
            'notes',
            'courier_notes',
            'is_gift',
            'gift_wishes',
          ]),
          send_charge: this.model.send_charge ?? 0,
          checkout_charge: this.model.checkout_charge ?? 0,
          charge_address: {
            ...(this.model.charge_address as OrderAddress),
            telephone: this.model.charge_address?.telephone ?? '',
          },
          send_address: !isEmpty(this.model.send_address)
            ? ({
                ...this.model.send_address,
                telephone: this.model.send_address?.telephone ?? '',
              } as OrderAddress)
            : null,
        };
      });
    }
  }

  async handlePrint() {
    try {
      if (
        this.order.charge_address === null ||
        this.order.send_address === null ||
        this.order.checkout_method === null ||
        this.order.order_method === null ||
        this.order.send_method === null
      ) {
        await this.$swal({
          title: 'Αποτυχία',
          text:
            'Δεν είναι δυνατή η εκτύπωση της παραγγελίας καθώς λείπουν στοιχεία χρέωσης κι αποστολής',
          icon: 'error',
          timer: 3000,
        });
        return;
      }

      window.print();

      await this.$swal({
        title: 'Επιτυχής Εκτύπωση',
        text: 'Η παραγγελία εκτυπώθηκε',
        icon: 'success',
        timer: 3000,
      });
    } catch (err) {
      await this.$swal({
        title: 'Αποτυχία Εκτύπωσης',
        text: 'Δεν ήταν δυνατή η εκτύπωση της παραγγελίας',
        icon: 'success',
        timer: 3000,
      });
    }
  }

  async handleIssueVouchers() {
    try {
      if (
        this.order.charge_address === null ||
        this.order.send_address === null ||
        this.order.checkout_method === null ||
        this.order.order_method === null ||
        this.order.send_method === null
      ) {
        await this.$swal({
          title: 'Αποτυχία',
          text:
            'Δεν είναι δυνατή η έκδοση vouchers για την παραγγελία καθώς λείπουν στοιχεία χρέωσης κι αποστολής',
          icon: 'error',
          timer: 3000,
        });
        return;
      }

      const { value: parcelCount } = await this.$swal({
        title: 'Εισάγετε τον αριθμό των δεμάτων της παραγγελίας',
        input: 'number',
        inputPlaceholder: 'Αρ. Δεμάτων',
        inputValue: '1',
        inputAttributes: {
          min: '1',
          step: '1',
        },
        showCancelButton: true,
        confirmButtonText: 'Εκδώστε Vouchers',
        cancelButtonText: 'Ακύρωση',
        inputValidator: value => {
          return new Promise(resolve => {
            // Since value is a string due to the input type being 'number', directly parse it
            const parsedValue = parseInt(value, 10);
            if (!value || isNaN(parsedValue) || parsedValue < 1) {
              resolve('Πρέπει να εισάγετε έναν έγκυρο αριθμό!');
            } else {
              resolve(null);
            }
          });
        },
      });

      if (parcelCount !== undefined && parcelCount !== null) {
        this.isLoading = true;

        // Assert parcelCount as string and parse it to number
        const count = parseInt(parcelCount as string, 10);

        await request.post('vouchers/issueVouchers', {
          orders: { [this.orderId]: count },
        });

        const { data } = await request.get(`/orders/${this.orderId}`);
        this.order = data.order;
        this.isLoading = false;

        await this.$swal({
          title: 'Επιτυχία',
          text: 'Τα vouchers εκδόθηκαν επιτυχώς',
          icon: 'success',
          timer: 3000,
        }).then(() => {
          // Refresh the page after showing the success message
          window.location.reload();
        });
      }
    } catch (err) {
      this.isLoading = false;
      await this.$swal({
        title: 'Αποτυχία',
        text: 'Το αίτημα απέτυχε',
        icon: 'error',
        timer: 3000,
      });
    }
  }

  async handleCancelVouchers() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ακυρώσετε τα vouchers: ${this.model.vouchers.join(
        ', ',
      )};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          this.isLoading = true;
          await request.post('vouchers/cancelVouchers', {
            // This cancels all vouchers for the order
            // We could select a subset and send over
            orders: { [this.orderId]: this.order.vouchers },
          });

          const { data } = await request.get(`/orders/${this.orderId}`);

          this.order = data.order;

          this.isLoading = false;

          this.$swal({
            title: 'Επιτυχία',
            text: 'Τα vouchers ακυρώθηκαν επιτυχώς',
            icon: 'success',
            timer: 3000,
          }).then(() => {
            // Refresh the page after showing the success message
            window.location.reload();
          });
        } catch (err) {
          this.isLoading = false;
          await this.$swal({
            title: 'Αποτυχία',
            text: 'Το αίτημα απέτυχε',
            icon: 'error',
            timer: 3000,
          });
        }
      }
    });
  }

  repairBase64(base64String: string): string {
    // Remove all characters that are not part of the standard base64 alphabet
    base64String = base64String.replace(/[^A-Za-z0-9+/=]/g, '').trim();

    // Ensure padding is correctly applied
    let paddingNeeded = 4 - (base64String.length % 4);
    if (paddingNeeded < 4) {
      base64String += '='.repeat(paddingNeeded);
    }

    return base64String;
  }

  safeAtob(base64: string): Uint8Array | null {
    try {
      return base64js.toByteArray(base64);
    } catch (error) {
      console.error('Failed to decode base64:', error);
      return null; // Return null if decoding fails
    }
  }

  // Function to convert a base64 string to a Blob
  base64ToBlob(
    base64: string,
    contentType: string = 'application/pdf',
  ): Blob | null {
    // Ensure the base64 string is clean and properly padded
    const cleanBase64 = this.repairBase64(base64);
    const decodedData = this.safeAtob(cleanBase64);

    if (!decodedData) {
      return null; // Return null if decoding was not successful
    }

    // Directly use the decoded data to create a Blob
    return new Blob([decodedData], { type: contentType });
  }

  extractBase64Strings(base64Data: string): string[] {
    // Assuming that base64 PDF data blocks start with "JVBER" (base64 encoded "%PDF-")
    // This splits the string into an array at each occurrence of "JVBER", treating each as a new PDF.
    return base64Data.split(/(?=JVBER)/).filter(str => str.trim());
  }

  async handleVoucherPrint(voucher: string): Promise<void> {
    const result = await this.$swal({
      title: 'Εκτύπωση Voucher',
      text: `Θέλετε να εκτυπώσετε το voucher: ${voucher}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ναι',
    });

    if (result.isConfirmed) {
      try {
        this.isLoading = true;
        const queryString = `ids=${encodeURIComponent(voucher)}`;
        const response = await request.get(
          `/vouchers/printVouchers?${queryString}`,
        );
        const base64PDFs = this.extractBase64Strings(response.data.vouchers[0]);

        base64PDFs.forEach(base64PDF => {
          const blob = this.base64ToBlob(base64PDF);
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          } else {
            console.error('Invalid base64 data, cannot convert to Blob.');
          }
        });

        this.isLoading = false;
        await this.$swal({
          title: 'Επιτυχία',
          text: 'Τo voucher εκτυπώθηκε επιτυχώς',
          icon: 'success',
          timer: 3000,
        });
      } catch (error) {
        this.isLoading = false;
        await this.$swal(
          'Αποτυχία!',
          'Υπήρξε πρόβλημα με την εκτύπωση του voucher.',
          'error',
        );
      }
    }
  }

  async handleEmailStatus() {
    try {
      if (
        this.order.charge_address === null ||
        this.order.send_address === null ||
        this.order.checkout_method === null ||
        this.order.order_method === null ||
        this.order.send_method === null
      ) {
        await this.$swal({
          title: 'Αποτυχία',
          text:
            'Δεν είναι δυνατή η αποστολή κατάστασης της παραγγελίας καθώς λείπουν στοιχεία χρέωσης κι αποστολής',
          icon: 'error',
          timer: 3000,
        });
        return;
      }

      this.isLoading = true;
      await request.post(`/orders/${this.orderId}/status-email`);
      this.isLoading = false;

      await this.$swal({
        title: 'Επιτυχία',
        text: 'Το email στάλθηκε επιτυχώς',
        icon: 'success',
        timer: 3000,
      });
    } catch (err) {
      this.isLoading = false;

      await this.$swal({
        title: 'Αποτυχία',
        text: 'To αίτημα απέτυχε',
        icon: 'error',
        timer: 3000,
      });
    }
  }
}
