var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ExistingItems',{attrs:{"items":_vm.items,"title":"Φίλτρα Ομάδας"},scopedSlots:_vm._u([(_vm.items.length)?{key:"item",fn:function(props){return [_c('router-link',{attrs:{"to":{
        name: 'filterGroup.filters.edit',
        params: {
          id: props.item.filter_group_id,
          filter: props.item.id,
        },
      }}},[_vm._v(" "+_vm._s(props.item.title)+" ")])]}}:{key:"default",fn:function(undefined){return _c('p',[_vm._v("Δε βρέθηκαν αποτελέσματα")])}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }