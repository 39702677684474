
import { Component, Prop, Vue } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';

import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import FilterGroupsMethodsMixin from './mixins/FilterGroupsMethodsMixin';
import Layout from './components/FilterGroupsLayout.vue';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';

@Component({
  mixins: [QueryParamsPaginationMixin, FilterGroupsMethodsMixin],
  components: {
    Layout,
    ListPagination,
    ListThreeDotsMenu,
    ListActionsBar,
  },
})
export default class FilterGroupsAll extends Vue {
  @Prop({ type: Object, required: false }) readonly counters!: Object;
  @Prop({ type: Boolean, required: false }) readonly isCountersLoaded!: boolean;

  sortBy = 'title';
  sortDesc = false;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Τίτλος', key: 'title', sortable: true },
    { label: 'Φίλτρα', key: 'active' },
    { label: 'Προβολή', key: 'active' },
    { label: '', key: 'actions', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Ομάδες Φίλτρων',
      to: { name: 'filterGroups.all' },
    },
  ];
  filters = [
    {
      label: 'Εμφανές',
      apiName: 'active',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Έχει Φίλτρα',
      apiName: 'has_filters', //?
      type: 'boolean',
      value: false,
    },
  ];
  isLoading = false;
  total = 0;
  items = [];
  searchTerm = '';

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async filterGroupsProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/filterGroups', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.filterGroups.total;
      this.items = data.filterGroups.data;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι ομάδες φίλτρων',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
