
import { Component, Vue } from 'vue-property-decorator';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit.vue';
import DynamicSelectMenuCustomers from '@/components/DynamicSelectMenuCustomers.vue';
import DynamicSelectMenuProducts from '@/components/DynamicSelectMenuProducts.vue';
import { Note } from '@/types';
import OrdersProductsTable from '../components/OrdersProductsTable.vue';
import OrdersDetails from '../components/OrdersDetails.vue';
import OrdersStatuses from '../components/OrdersStatuses.vue';
import Layout from './components/OrdersEditLayout.vue';
import OrdersEditNotesModal from './components/OrdersEditNotesModal.vue';

@Component({
  components: {
    DynamicSelectMenuProducts,
    DynamicSelectMenuCustomers,
    OrdersProductsTable,
    OrdersDetails,
    OrdersStatuses,
    BaseButtonSubmit,
    Layout,
    OrdersEditNotesModal,
  },
})
export default class OrdersEditGeneral extends Vue {
  isLoading = false;
  isSaving = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Παραγγελίες',
      to: { name: 'orders' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'orders.edit.general' },
    },
    {
      text: 'Σημειώσεις',
      active: true,
    },
  ];
  notes: Note[] = [];

  get hasNotes() {
    return this.notes.length > 0;
  }

  async getNotes() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/orders/${this.$route.params.id}/notes`,
      );
      this.notes = data.notes;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δε μπόρεσαν να φορτωθούν οι σημειώσεις',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  created() {
    this.getNotes();
  }

  handleAdd() {
    this.$bvModal.show('modal-order-note');
  }
}
