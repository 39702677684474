<template>
  <BOverlay :show="isLoadingFilterGroup" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />
    <div class="d-flex border-top">
      <div class="tab-header">
        Προσθήκη Φίλτρου
      </div>
    </div>

    <div class="d-flex align-items-start">
      <BCard no-body class="main-card no-border-top-left flex-grow-1 mr-4">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
            data-test="form"
          >
            <BCardBody>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup label="Ονομασία *">
                  <BFormInput
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Εισάγετε ονομασία"
                    data-test="title"
                    autofocus
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε ονομασία
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </div>
  </BOverlay>
</template>

<script>
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';

export default {
  mixins: [EditorMixin],

  data: () => ({
    isSaving: false,
    form: {
      title: '',
    },
    filterGroup: null,
    isLoadingFilterGroup: false,
    breadcrumbItems: [
      {
        text: 'Ομάδα Φίλτρων',
        to: { name: 'filterGroups.all' },
      },
      {
        text: '',
        to: {
          name: 'filterGroups.edit',
          params: { id: null },
        },
      },
      {
        text: 'Προσθήκη Φίλτρου',
        active: true,
      },
    ],
  }),

  async created() {
    try {
      this.isLoadingFilterGroup = true;
      const { data } = await request.get(
        `/filterGroups/${this.$route.params.id}`,
      );

      this.filterGroup = data.filterGroup;

      this.breadcrumbItems[1].text = data.filterGroup.title;

      this.isLoadingFilterGroup = false;
    } catch (err) {
      this.$router.push('/error');
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;

        await request.post('filters', {
          ...this.form,
          filter_group_id: this.filterGroup.id,
        });

        this.$swal({
          title: 'Επιτυχία',
          text: ' Το φίλτρο αποθηκεύτηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$router.push({
          name: 'filterGroups.edit',
          params: [this.filterGroup.id],
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης φίλτρου',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        title: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
