import _round from 'lodash/round';
import OPERATORS from '@/constants/operators';

export const calculateSendCharges = (
  isPharmacy,
  sum,
  weight,
  { sendCharges: charges },
) => {
  if (isPharmacy) {
    return 0;
  }

  charges = JSON.parse(JSON.stringify(charges));
  const methods = OPERATORS.reduce(
    (acc, item) => ({
      ...acc,
      [item.value]: item.method,
    }),
    {},
  );

  let totalSumCharges = 0;
  let totalWeightCharges = 0;

  charges[0].sum.forEach(s => {
    if (methods[s.operator](sum, s.limit)) {
      totalSumCharges = s.charge;
    }
  });

  charges[1].weight.forEach(w => {
    if (methods[w.operator](weight, w.limit)) {
      totalWeightCharges += Math.ceil((weight - w.limit) / w.amount) * w.charge;
    }
  });

  return _round(totalSumCharges + totalWeightCharges, 2);
};
