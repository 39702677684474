
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import { Category, Filter, Brand } from '@/types';
import request from '@/utils/request';
import CategoriesSearch from './CategoriesSearch.vue';

type Form = {
  [key: string]: Filter;
};
type Params = {
  [key: string]: string | boolean;
};

@Component({
  components: {
    CategoriesSearch,
  },
})
export default class ListActionsBar extends Vue {
  @Prop({ type: Boolean, required: false }) readonly disabled!: boolean;
  @Prop({ type: Boolean, default: true }) readonly hasActionOptions!: boolean;
  @Prop({ type: Boolean, required: false })
  readonly hasActivateOption!: boolean;
  @Prop({ type: Boolean, required: false })
  readonly hasDeactivateOption!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly hasDeleteOption!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly hasCancelOption!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly hasRestoreOption!: boolean;
  @Prop({ type: Boolean, required: false })
  readonly hasMassUpdatesOption!: boolean;
  @Prop({ type: Boolean, required: false })
  readonly hasMassRestoreOption!: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  readonly hasFilterOption!: boolean;
  @Prop({ type: Array, required: false, default: () => [] })
  readonly filters!: Filter[];

  selectedCategory: Category | null = null;
  selectedCategoryTitle = '';
  brands: Brand[] = [];
  term = '';
  selectedFilter = '';
  form: Form = this.getInitialValues();
  selectedFilters = Object.keys(this.$route?.query?.filters ?? []).reduce(
    (acc, cur) => {
      const filter = this.filters.find(f => cur.includes(f.apiName));
      if (filter) {
        acc = [
          ...acc,
          // @ts-ignore
          { ...filter, value: this.$route.query.filters[cur] },
        ];
      }
      return acc;
    },
    [] as Filter[],
  );
  options = this.getOptions();

  async created() {
    this.selectedCategoryTitle = '';
    try {
      // Request to fetch brands without pagination
      const { data: brandsData } = await request.get('/brands', {
        params: { paginated: false },
      });

      this.brands = brandsData.brands;

      const selectedCategory = this.selectedFilters.find(
        f => f.apiName === 'category_id',
      );
      if (selectedCategory) {
        const { data: categoryData } = await request.get(
          `/categories/${selectedCategory.value}`,
        );
        this.selectedCategoryTitle = categoryData.category.title;
      }
    } catch (err) {
      this.$router.push({ name: 'error' });
    }
  }

  get hasFilters() {
    return !isEmpty(this.filters);
  }

  get selectedFilterType() {
    return this.filters.find(filter => filter.apiName === this.selectedFilter)
      ?.type;
  }

  getSelectedBrand(filter: Filter) {
    const selectedBrand = this.brands.find(b => b.id === filter.value) as Brand;

    return selectedBrand ? selectedBrand.title : '';
  }

  handleCategorySelect(category: Category & { titles: string[] }) {
    this.selectedCategory = category;
    this.selectedCategoryTitle = category.titles[category.titles.length - 1];
    this.form[this.selectedFilter] = {
      type: 'category',
      label: 'Κατηγορία',
      apiName: 'category_id',
      value: category.id,
    };
  }

  getInitialValues() {
    return this.filters.reduce(
      (acc, cur) => ({
        ...acc,
        // @ts-ignore
        [cur.apiName]: cur,
      }),
      {},
    );
  }

  getOptions() {
    return this.filters
      .filter(
        el =>
          !Object.keys(this.$route.query.filters || {}).find(
            element => element === el.apiName,
          ),
      )
      .map(filter => ({
        value: filter.apiName,
        text: filter.label,
      }));
  }

  handleRemoveFilter(selected: Filter) {
    this.selectedFilters = this.selectedFilters.filter(
      filter => filter.apiName !== selected.apiName,
    );

    const filterParams = this.selectedFilters.reduce(
      (acc, cur) => ({
        ...acc,
        [`filters[${cur.apiName}]`]: cur.value,
      }),
      {} as Params,
    );

    this.form = {
      ...this.form,
      [selected.apiName]: {
        ...selected,
        value: selected.type === 'boolean' ? false : '',
      },
    };

    this.$router
      .push({
        name: this.$route.name ?? '',
        query: {
          page: '1',
          perPage: this.$route.query.perPage,
          ...filterParams,
        },
      })
      .catch(() => {});

    this.options = this.getOptions();
  }

  handleForm() {
    this.$router
      .push({
        name: this.$route.name ?? '',
        query: {
          ...this.$route.query,
          page: '1',
          perPage: this.$route.query.perPage,
          [`filters[${this.selectedFilter}]`]: this.form[
            this.selectedFilter
          ].value.toString(),
        },
      })
      .catch(() => {});

    const filter = this.filters.find(f => f.apiName === this.selectedFilter);

    if (filter) {
      this.selectedFilters = [
        ...this.selectedFilters,
        {
          ...filter,
          value: this.form[this.selectedFilter].value,
        },
      ];
    }

    this.form = this.getInitialValues();
    // @ts-ignore
    this.$refs.filtersRef.hide();
    this.options = this.options.filter(
      filter => filter.value !== this.selectedFilter,
    );
    this.selectedFilter = '';
    this.selectedCategory = null;
  }

  handleClearSearch() {
    this.term = '';
    this.selectedCategoryTitle = '';
    this.selectedCategory = null;
    (this.$refs.input as HTMLInputElement).focus();
    this.$emit('clear-search');
  }
}
