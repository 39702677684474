
import { Component, Vue } from 'vue-property-decorator';
import qs from 'qs';

import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import PaginationMixin from '@/mixins/PaginationMixin';
import { Product, Review } from '@/types';
import ListPagination from '@/components/ListPagination.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';

@Component({
  components: {
    ListPagination,
    ListActionsBar,
  },
  mixins: [EditorMixin, PaginationMixin, ProductsEditMethodsMixin],
})
export default class ProductsEditReviews extends Vue {
  sortDesc = false;
  isLoading = false;
  items: Review[] = [];
  total = 0;
  currentPage = 1;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Αστέρια', key: 'stars' },
    { label: 'Περιεχόμενο', key: 'content', class: 'w-50' },
    { label: 'Πελάτης', key: 'name' },
    { label: 'Ημ/νια', key: 'date' },
    { label: 'Ενεργό', key: 'active' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Προϊόντα',
      to: { name: 'products' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'products.edit.general' },
    },
    {
      text: 'Αξιολογήσεις',
      active: true,
    },
  ];
  selectedIds: string[] = [];
  model: Product | {} = {};

  async reviewsProvider(ctx: {
    currentPage: number;
    perPage: number;
    sortDesc: string;
    filter: string;
  }) {
    try {
      this.isLoading = true;

      const { data } = await request.get(
        `/products/${this.$route.params.id}/reviews`,
        {
          params: {
            page: ctx.currentPage,
            size: ctx.perPage,
            sortDesc: ctx.sortDesc,
            filter: ctx.filter,
          },
        },
      );

      const { data: productData } = await request.get(
        `/products/${this.$route.params.id}`,
      );

      this.model = productData.product;

      this.currentPage = data.reviews.current_page;
      this.total = data.reviews.total;
      this.items = data.reviews.data;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι αξιολογήσεις',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleToggleActive(item: Review) {
    try {
      await request.patch(
        `/products/${this.$route.params.id}/reviews/${item.id}/active`,
      );

      this.$swal({
        title: 'Επιτυχία',
        text: `Η αξιολόγηση ${
          item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
        }!`,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$emit('get-counters');
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.table.refresh();
      });
    } catch (error) {
      this.$swal({
        title: 'Σφάλμα',
        text: `Αδυναμία ${
          item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
        } της αξιολόγησης`,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }

  handleMassActivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'την επιλεγμένη αξιολόγηση;'
          : `τις επιλεγμένες ${this.selectedIds.length} αξιολόγησεις;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            `/products/${this.$route.params.id}/reviews`,
            {},
            {
              params: {
                active: true,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Η αξιολόγηση ενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} αξιολογήσεις ενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.table.refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Η αξιολόγηση δεν ενεργοποιήθηκε'
                : 'Οι αξιολογήσεις δεν ενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassDeactivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να απενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'την επιλεγμένη αξιολόγηση;'
          : `τις επιλεγμένες ${this.selectedIds.length} αξιολογήσεις;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            `/products/${this.$route.params.id}/reviews`,
            {},
            {
              params: {
                active: false,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Η αξιολόγηση απενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} αξιολογήσεις απενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.table.refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Η αξιολόγηση δεν απενεργοποιήθηκε'
                : 'Οι αξιολογήσεις δεν απενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }
}
