
import { Component, Vue } from 'vue-property-decorator';
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit.vue';
import PostCategoriesEditLayout from './components/PostCategoriesEditLayout.vue';
import PostCategoriesEditMethodsMixin from './mixins/PostCategoriesEditMethodsMixin';

@Component({
  components: {
    BaseButtonSubmit,
    PostCategoriesEditLayout,
  },
  mixins: [PostCategoriesEditMethodsMixin],
})
export default class PostCategoriesEditSEO extends Vue {
  meta = {};
  form = {};
  isSaving = false;
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Κατηγορίες άρθρων',
      to: { name: 'postCategories' },
    },
    {
      text: 'SEO',
      active: true,
    },
  ];
  model = {};

  async created() {
    try {
      this.isLoading = true;
      const [metaData, categoryData] = await Promise.all([
        request.get(`/post-categories/${this.$route.params.id}/meta`),
        request.get(`/post-categories/${this.$route.params.id}`),
      ]);

      this.meta = metaData.data.meta ?? {};
      this.model = categoryData.data.postCategory ?? {};
      this.form = pick(this.meta, [
        'title',
        'description',
        'keywords',
        'image',
        'schema',
        'og_title',
        'og_description',
        'og_image',
        'og_type',
        'og_url',
      ]);

      this.$nextTick(() => {
        (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθούν τα meta tags της κατηγορίας άρθρου',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.put(
        `/post-categories/${this.$route.params.id}/meta`,
        this.form,
      );

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα meta tags της κατηγορίας άρθρου ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      await this.$router.push({ name: 'postCategories.list' });
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα meta tags της κατηγορίας άρθρου δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleReset() {
    this.form = pick(this.meta, [
      'title',
      'description',
      'keywords',
      'image',
      'schema',
      'og_title',
      'og_description',
      'og_image',
      'og_type',
      'og_url',
    ]);
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
