
import { Component, Vue } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';
import { BTable } from 'bootstrap-vue';
import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import { Author } from '@/types';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';

@Component({
  mixins: [QueryParamsPaginationMixin],

  components: {
    ListThreeDotsMenu,
    ListPagination,
    ListActionsBar,
  },
})
export default class AuthorsList extends Vue {
  sortBy = 'name';
  sortDesc = false;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Όνομα', key: 'name', sortable: true },
    {
      key: 'email',
    },
    { key: 'actions', label: '', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Συγγραφείς',
      active: true,
    },
  ];
  filters = [
    {
      label: 'Έχει Άρθρα',
      apiName: 'has_posts',
      type: 'boolean',
      value: false,
    },
  ];
  isLoading = false;
  isLoaded = false;
  total = 0;
  items = [];
  searchTerm = '';
  selectedIds = [];

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async authorsProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/authors', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.authors.total;
      this.items = data.authors.data;
      this.isLoaded = true;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν οι συγγραφείς',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleDelete(item: Author) {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε το συγγραφέα: ${item.name};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/authors/${item.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Ο συγγραφέας ${item.name} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `Ο συγγραφέας ${item.name} δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε ${
        this.selectedIds.length === 1
          ? 'τοv επιλεγμένο συγγραφέα;'
          : `τους επιλεγμένους ${this.selectedIds.length} συγγραφείς;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete('/authors', {
            params: {
              ids: this.selectedIds,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {
                arrayFormat: 'comma',
                encode: false,
              });
            },
          }),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Ο συγγραφέας διαγράφηκε επιτυχώς'
                  : `${this.selectedIds.length} συγγραφείς διαγράφηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Ο συγγραφέας δεν διεγράφη'
                : 'Οι συγγραφείς δεν διεγράφησαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }
}
