<template>
  <BOverlay :show="isLoading" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex border-top">
      <div class="tab-header">
        Νέο Κουπόνι
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
          <BCardBody>
            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider
                  name="code"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <BFormGroup size="md" label="Κωδικός *">
                    <BInputGroup>
                      <template v-slot:append>
                        <BButton
                          variant="primary"
                          v-b-tooltip.hover
                          title="Δημιουργία τυχαίου κωδικού"
                          @click="handleCreateCode"
                        >
                          <BIcon icon="arrow-clockwise" />
                        </BButton>
                      </template>
                      <BFormInput
                        id="code"
                        v-model="form.code"
                        type="text"
                        placeholder="Εισάγετε τον κωδικό του κουπονιού"
                        data-test="code"
                        autofocus
                      />
                    </BInputGroup>

                    <BFormText
                      text-variant="danger"
                      data-test="code-error"
                      v-if="errors[0]"
                    >
                      Παρακαλώ εισάγετε τον κωδικό του κουπονιού
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider name="title">
                  <BFormGroup size="md" label="Τίτλος">
                    <BInputGroup>
                      <BFormInput
                        id="title"
                        v-model="form.title"
                        type="text"
                        placeholder="Εισάγετε τον τίτλο του κουπονιού"
                        data-test="title"
                      />
                    </BInputGroup>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider name="min_price">
                  <BFormGroup size="md" label="Ελάχιστη Αξία Παραγγελίας">
                    <BInputGroup>
                      <BFormInput
                        id="min_price"
                        v-model="form.min_price"
                        type="number"
                        placeholder="Εισάγετε την ελάχιστη αξία της παραγγελίας"
                        data-test="min_price"
                        step="1"
                      />
                    </BInputGroup>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider
                  name="value"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <BFormGroup size="md" label="Ποσό Κουπονιού *">
                    <BInputGroup>
                      <BFormInput
                        id="value"
                        v-model="form.value"
                        type="number"
                        placeholder="Εισάγετε το ποσό του κουπονιού"
                        data-test="value"
                      />
                      <BInputGroupAppend>
                        <BFormSelect
                          class="type-select"
                          v-model="form.type"
                          :options="typeOptions"
                        />
                      </BInputGroupAppend>
                    </BInputGroup>

                    <BFormText
                      text-variant="danger"
                      data-test="value-error"
                      v-if="errors[0]"
                    >
                      Παρακαλώ εισάγετε το ποσό του κουπονιού
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider name="date_start">
                  <BFormGroup size="md" label="Ημερομηνία Έναρξης">
                    <BFormDatepicker
                      id="date_start"
                      v-model="form.date_start"
                      locale="el"
                      :start-weekday="1"
                      :value-as-date="false"
                      show-decade-nav
                      reset-button
                      label-reset-button="Καθαρισμός"
                      label-no-date-selected="Κενή ημερομηνία"
                      placeholder="Επιλέξτε ημ/νία"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider name="date_end">
                  <BFormGroup size="md" label="Ημερομηνία Λήξης">
                    <BFormDatepicker
                      id="date_end"
                      v-model="form.date_end"
                      locale="el"
                      :start-weekday="1"
                      :value-as-date="false"
                      show-decade-nav
                      reset-button
                      label-reset-button="Καθαρισμός"
                      label-no-date-selected="Κενή ημερομηνία"
                      placeholder="Επιλέξτε ημ/νία"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>

            <BRow>
              <BCol cols="12" md="6">
                <ValidationProvider
                  title="active"
                  rules="required"
                  name="active"
                >
                  <BFormGroup size="md" label="Ενεργό *" class="mb-0">
                    <BFormCheckbox v-model="form.active" switch />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
              <BCol cols="12" md="6">
                <ValidationProvider name="one_timer">
                  <BFormGroup size="md" label="Μιας χρήσης">
                    <BFormCheckbox v-model="form.one_timer" switch />
                  </BFormGroup>
                </ValidationProvider>
              </BCol>
            </BRow>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </BOverlay>
</template>

<script>
import moment from 'moment';
import VoucherCodeGenerator from 'voucher-code-generator';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
  },

  data: () => ({
    isLoading: false,
    form: {
      active: false,
      code: '',
      date_end: '',
      date_start: moment().format('YYYY-MM-DD'),
      min_price: 0,
      one_timer: false,
      title: '',
      type: 'amount',
      value: 0,
    },
    isSaving: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Κουπόνια',
        to: { name: 'coupons' },
      },
      {
        text: 'Προσθήκη',
        active: true,
      },
    ],
    timerOptions: [
      {
        text: 'Ναι',
        value: true,
      },
      {
        text: 'Όχι',
        value: false,
      },
    ],
    typeOptions: [
      { value: 'amount', text: '€' },
      { value: 'percentage', text: '%' },
    ],
  }),

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/coupons', this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Το κουπόνι προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'coupons.list' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης κουπονιού',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        active: false,
        code: '',
        date_end: '',
        date_start: moment().format('YYYY-MM-DD'),
        min_price: 0,
        one_timer: false,
        title: '',
        type: 'amount',
        value: 0,
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    handleCreateCode() {
      const [code] = VoucherCodeGenerator.generate({
        prefix: 'promo-',
        length: 6,
        postfix: `-${new Date().getFullYear()}`,
      });

      this.form.code = code;
      this.form.title = code;
    },
  },
};
</script>
<style lang="scss" scoped>
.type-select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
</style>
