
import { Component, Vue } from 'vue-property-decorator';
import { pick } from 'lodash';
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit.vue';
import Layout from './components/AuthorsEditLayout.vue';

@Component({
  components: {
    BaseButtonSubmit,
    Layout,
  },
})
export default class AuthorsEditSEO extends Vue {
  model = {};
  form = {};
  isSaving = false;
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Συγγραφείς',
      to: { name: 'authors' },
    },
    {
      text: 'SEO',
      active: true,
    },
  ];

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(
        `/authors/${this.$route.params.id}/meta`,
      );

      this.model = data.meta ?? {};
      this.form = pick(this.model, [
        'title',
        'description',
        'keywords',
        'image',
        'schema',
        'og_title',
        'og_description',
        'og_image',
        'og_type',
        'og_url',
      ]);

      this.$nextTick(() => {
        (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθούν τα meta tags του συγγραφέα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.put(`/authors/${this.$route.params.id}/meta`, this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα meta tags του συγγραφέα',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα meta tags του συγγραφέα δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε τον συγγραφέα;`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/authors/${this.$route.params.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Ο συγγραφέας διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          await this.$router.push({ name: 'authors.list' });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `Ο συγγραφέας δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleReset() {
    this.form = pick(this.model, [
      'title',
      'description',
      'keywords',
      'image',
      'schema',
      'og_title',
      'og_description',
      'og_image',
      'og_type',
      'og_url',
    ]);
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
