
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEmpty } from 'lodash';

export type Photo = {
  id: string;
  path: string;
  unique_id: string | null;
};

@Component
export default class ProductThumbnail extends Vue {
  @Prop({ type: Array, required: false }) readonly photos!: Photo[];
  @Prop({ type: Object, required: false }) readonly photo!: Photo;
  @Prop({ type: String, required: false }) readonly alt!: string;

  isImageBroken = false;

  get path() {
    let photo = undefined;

    if (!isEmpty(this.photo)) {
      photo = this.photo;
    } else if (!isEmpty(this.photos)) {
      photo = this.photos[0];
    }

    if (!photo || !photo.path) {
      this.handleBrokenImage();
      return '';
    }

    return `${process.env.VUE_APP_CDN_URL}?path=${encodeURIComponent(
      photo.path,
    )}&x=${60}&y=${60}`;
  }

  handleBrokenImage() {
    this.isImageBroken = true;
  }
}
