<template>
  <Layout :breadcrumb-items="breadcrumbItems">
    <BCard no-body>
      <BCardBody>
        <div v-if="isLoading" class="text-center my-4">
          <div class="mb-4">
            <BSpinner class="align-middle" />
          </div>
          <h5>Λήψη δεδομένων...</h5>
        </div>

        <div v-else class="mb-3">
          <HorizontalBarChart
            :options="chartOptions"
            :chart-data="chartSalesData"
            :height="1000"
            class="mb-5"
          />

          <HorizontalBarChart
            :options="chartOptions"
            :chart-data="chartRevenueData"
            :height="1000"
          />
        </div>
      </BCardBody>
    </BCard>
  </Layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { HorizontalBar, mixins } from 'vue-chartjs';

import request from '@/utils/request';
import Layout from './components/StatsLayout';

const { reactiveProp } = mixins;

const HorizontalBarChart = {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ['options'],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};

@Component({
  components: {
    HorizontalBarChart,
    Layout,
  },
})
export default class StatsProducts extends Vue {
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Στατιστικά',
      to: { name: 'stats.orders' },
    },
    {
      text: 'Προϊόντα',
      active: true,
    },
  ];
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: true, // Ensure the x-axis is always displayed
          ticks: {
            beginAtZero: true,
            callback(value) {
              // This callback can be used to format tick marks (e.g., adding units or modifying text)
              return value;
            },
          },
          gridLines: {
            display: true, // Controls the grid lines for x-axis
            drawBorder: true, // Draw border at the edge of the chart
            drawOnChartArea: true, // Draw grid lines for the chart area
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true, // Optionally hide the grid lines for y-axis
          },
        },
      ],
    },
  };
  chartSalesData = {};
  chartRevenueData = {};

  async created() {
    try {
      this.isLoading = true;
      const [salesResponse, revenueResponse] = await Promise.all([
        request.get('stats/top-products-sales'),
        request.get('stats/top-products-revenue'),
      ]);

      this.chartSalesData = {
        datasets: [
          {
            label: 'Σύνολο Πωλήσεων',
            data: salesResponse.data.products.map(
              product => product.total_count,
            ),
            backgroundColor: '#086DE1',
          },
        ],
        labels: salesResponse.data.products.map(product => product.title),
      };

      this.chartRevenueData = {
        datasets: [
          {
            label: 'Τζίρος Πωλήσεων',
            data: revenueResponse.data.products.map(
              product => product.total_revenue,
            ),
            backgroundColor: '#086DE1',
          },
        ],
        labels: revenueResponse.data.products.map(product => product.title),
      };
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #bar-chart {
  max-width: 100%;
}
</style>
