
import { Component, Vue } from 'vue-property-decorator';
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit.vue';
import Layout from './components/PostsEditLayout.vue';
import PostsEditMethodsMixin from './mixins/PostsEditMethodsMixin';

@Component({
  components: {
    BaseButtonSubmit,
    Layout,
  },
  mixins: [PostsEditMethodsMixin],
})
export default class PostsEditSEO extends Vue {
  model = {};
  meta = {};
  form = {};
  isSaving = false;
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Άρθρα',
      to: { name: 'posts' },
    },
    {
      text: 'SEO',
      active: true,
    },
  ];

  async created() {
    try {
      this.isLoading = true;
      const [metaData, postData] = await Promise.all([
        request.get(`/posts/${this.$route.params.id}/meta`),
        request.get(`/posts/${this.$route.params.id}`),
      ]);

      this.meta = metaData.data.meta ?? {};
      this.model = postData.data.post ?? {};
      this.form = pick(this.meta, [
        'title',
        'description',
        'keywords',
        'image',
        'schema',
        'og_title',
        'og_description',
        'og_image',
        'og_type',
        'og_url',
      ]);

      this.$nextTick(() => {
        (this.$refs.form as HTMLFormElement).reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθούν τα meta tags του άρθρου',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.put(`/posts/${this.$route.params.id}/meta`, this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα meta tags του άρθρου ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα meta tags του άρθρου δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleReset() {
    this.form = pick(this.meta, [
      'title',
      'description',
      'keywords',
      'image',
      'schema',
      'og_title',
      'og_description',
      'og_image',
      'og_type',
      'og_url',
    ]);
    this.$nextTick(() => {
      (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
