<template>
  <BModal
    id="modal-upload-photo"
    title="Προσθήκη φωτογραφίας"
    body-class="pb-0"
    centered
    hide-footer
    no-stacking
    scrollable
  >
    <template #default>
      <div class="pb-4">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm @submit.prevent="handleSubmit(handleUpload)" novalidate>
            <div v-if="hasPhoto" class="text-center">
              <BImg :src="preview" class="mb-4" fluid />

              <div class="d-flex justify-content-end align-items-center">
                <BButton
                  variant="primary"
                  type="submit"
                  class="mr-2"
                  :disabled="isSaving"
                >
                  Αποθήκευση
                  <BSpinner v-if="isSaving" small />
                </BButton>
                <BButton :disabled="isSaving" @click="handleReset"
                  >Επαναφορά</BButton
                >
              </div>
            </div>
            <div v-else>
              <ValidationProvider
                v-slot="{ errors }"
                name="photo"
                rules="mimes:image/*"
              >
                <BFormGroup class="mb-0 position-relative image-container">
                  <BButton variant="primary" class="position-absolute">
                    <BIcon icon="image" /> Επέλεξε νέα φωτογραφία
                  </BButton>
                  <BFormFile
                    id="photo"
                    accept="image/*"
                    class="position-absolute"
                    no-drop
                    @change="handleFileChange"
                  />

                  <BFormText text-variant="danger" v-if="errors[0]">
                    Εισάγετε έγκυρη png, jpg ή jpeg εικόνα
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>
            </div>
          </BForm>
        </ValidationObserver>
      </div>
    </template>
  </BModal>
</template>

<script>
export default {
  props: {
    isSaving: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      photo: null,
      preview: null,
    };
  },

  computed: {
    hasPhoto() {
      return this.photo;
    },
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.reset();
    });
  },

  methods: {
    handleFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      [this.photo] = files;

      this.createPreview();
    },

    createPreview() {
      const reader = new FileReader();

      reader.onload = e => {
        this.preview = e.target.result;
      };
      reader.readAsDataURL(this.photo);
    },

    handleUpload() {
      this.$emit('on-upload', this.photo);
    },

    handleReset() {
      this.reset();
    },

    reset() {
      this.photo = null;
      this.preview = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .image-container {
  background-color: #fafafa;
  border: 2px dashed #dddddd;
  height: 200px;

  .btn {
    left: 49%;
    top: 50%;
    transform: translate(-48%, -50%);
  }

  .custom-file {
    height: 100%;
    width: 100%;
  }

  .custom-file-input,
  .custom-file-label {
    height: 100%;
    opacity: 0;
  }

  .custom-file-label {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
