
import qs from 'qs';
import { Component, Vue } from 'vue-property-decorator';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import PaginationMixin from '@/mixins/PaginationMixin';
import { Comment } from '@/types';
import ListPagination from '@/components/ListPagination.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';
import PostsEditMethodsMixin from './mixins/PostsEditMethodsMixin';
import Layout from './components/PostsEditLayout.vue';

@Component({
  components: {
    Layout,
    ListPagination,
    ListActionsBar,
  },
  mixins: [EditorMixin, PaginationMixin, PostsEditMethodsMixin],
})
export default class PostsEditComments extends Vue {
  sortDesc = false;
  isLoading = false;
  items: Comment[] = [];
  total = 0;
  currentPage = 1;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Περιεχόμενο', key: 'content', class: 'w-50' },
    { label: 'Πελάτης', key: 'name' },
    { label: 'Ημ/νια', key: 'date' },
    { label: 'Ενεργό', key: 'active' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Άρθρα',
      to: { name: 'posts' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'posts.edit.general' },
    },
    {
      text: 'Σχόλια',
      active: true,
    },
  ];
  selectedIds: string[] = [];
  model = {};

  async commentsProvider(ctx: {
    currentPage: number;
    perPage: number;
    sortDesc: string;
    filter: string;
  }) {
    try {
      this.isLoading = true;

      const { data } = await request.get(
        `/posts/${this.$route.params.id}/comments`,
        {
          params: {
            page: ctx.currentPage,
            size: ctx.perPage,
            sortDesc: ctx.sortDesc,
            filter: ctx.filter,
          },
        },
      );

      const { data: postData } = await request.get(
        `/posts/${this.$route.params.id}`,
      );

      this.model = postData.post;
      this.currentPage = data.comments.current_page;
      this.total = data.comments.total;
      this.items = data.comments.data;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα σχόλια',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleToggleActive(item: Comment) {
    try {
      await request.patch(
        `/posts/${this.$route.params.id}/comments/${item.id}active`,
      );

      this.$swal({
        title: 'Επιτυχία',
        text: `Το σχόλιο ${
          item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
        }!`,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.table.refresh();
      });
    } catch (error) {
      this.$swal({
        title: 'Σφάλμα',
        text: `Αδυναμία ${
          item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
        } του σχολίου`,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }

  handleMassActivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'το επιλεγμένο σχόλιο;'
          : `τα επιλεγμένα ${this.selectedIds.length} σχόλια;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            `/posts/${this.$route.params.id}/comments`,
            {},
            {
              params: {
                active: true,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το σχόλιο ενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} σχόλια ενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.table.refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το σχόλιο δεν ενεργοποιήθηκε'
                : 'Τα σχόλια δεν ενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassDeactivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να απενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'το επιλεγμένο σχόλιο;'
          : `τα επιλεγμένα ${this.selectedIds.length} σχόλισ;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            `/posts/${this.$route.params.id}/comments`,
            {},
            {
              params: {
                active: false,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το σχόλιο απενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} σχόλια απενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.table.refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το σχόλιο δεν απενεργοποιήθηκε'
                : 'Τα σχόλια δεν απενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }
}
