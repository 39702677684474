import { get } from 'lodash';
import { loadState } from '@/utils/localStorage';

const state = {
  user: {},
  token: '',
  ...get(loadState(), 'session', {}),
};

export default state;
