const OPERATORS = [
  {
    label: 'Μεγαλύτερο απο',
    value: '>',
    method: (a, b) => a > b,
  },
  {
    label: 'Μεγαλύτερο ή ίσο απο',
    value: '>=',
    method: (a, b) => a >= b,
  },
  {
    label: 'Μικρότερο απο',
    value: '<',
    method: (a, b) => a < b,
  },
  {
    label: 'Μικρότερο ή ίσο απο',
    value: '<=',
    method: (a, b) => a <= b,
  },
];

export default OPERATORS;
