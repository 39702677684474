import qs from 'qs';
import request from '@/utils/request';

export default {
  methods: {
    handleDelete(item) {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το προϊόν: ${item.title};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/products/${item.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Το προϊόν ${item.title} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Το προϊόν ${item.title} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε ${
          this.selectedIds.length === 1
            ? 'το επιλεγμένο προϊόν;'
            : `τα επιλεγμένα ${this.selectedIds.length} προϊόντα;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete('/products', {
              params: {
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            });

            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το προϊόν διαγράφηκε επιτυχώς'
                  : `${this.selectedIds.length} προϊόντα διαγράφηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το προϊόν δεν διεγράφη'
                  : 'Τα προϊόντα δεν διεγράφησαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleRestore(item) {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να επαναφέρετε το προϊόν: ${item.title};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.put(`/products/${item.id}/restore`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Το προϊόν ${item.title} επαναφέρθηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Το προϊόν ${item.title} δεν επαναφέρθηκε`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassRestore() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να επαναφέρετε ${
          this.selectedIds.length === 1
            ? 'το επιλεγμένο προϊόν;'
            : `τα επιλεγμένα ${this.selectedIds.length} προϊόντα;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.put('/products-restore', {
              ids: this.selectedIds,
            });

            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το προϊόν επαναφέρθηκε επιτυχώς'
                  : `${this.selectedIds.length} προϊόντα επαναφέρθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το προϊόν δεν επαναφέρθηκε'
                  : 'Τα προϊόντα δεν επαναφέρθηκαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassActivate() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να ενεργοποιήσετε ${
          this.selectedIds.length === 1
            ? 'το επιλεγμένο προϊόν;'
            : `τα επιλεγμένα ${this.selectedIds.length} προϊόντα;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.patch('/products', {
              ids: this.selectedIds,
              updates: {
                active: true,
              },
            }),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'Το προϊόν ενεργοποιήθηκε επιτυχώς'
                    : `${this.selectedIds.length} προϊόντα ενεργοποιήθηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
                showConfirmButton: false,
              });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το προϊόν δεν ενεργοποιήθηκε'
                  : 'Τα προϊόντα δεν ενεργοποιήθηκαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    handleMassDeactivate() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να απενεργοποιήσετε ${
          this.selectedIds.length === 1
            ? 'το επιλεγμένο προϊόν;'
            : `τα επιλεγμένα ${this.selectedIds.length} προϊόντα;`
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.patch('/products', {
              ids: this.selectedIds,
              updates: {
                active: false,
              },
            }),
              this.$swal({
                title: 'Επιτυχία',
                text: `${
                  this.selectedIds.length === 1
                    ? 'Το προϊόν απενεργοποιήθηκε επιτυχώς'
                    : `${this.selectedIds.length} προϊόντα απενεργοποιήθηκαν επιτυχώς`
                }`,
                icon: 'success',
                timer: 3000,
                showConfirmButton: false,
              });

            this.$emit('get-counters');

            this.$nextTick(() => {
              this.$refs.table.refresh();
            });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το προϊόν δεν απενεργοποιήθηκε'
                  : 'Τα προϊόντα δεν απενεργοποιήθηκαν'
              }`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },

    async handleToggleActive(item) {
      try {
        await request.patch(`/products/${item.id}/active`);

        this.$swal({
          title: 'Επιτυχία',
          text: `Το προϊόν ${
            item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
          }!`,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit('get-counters');
        this.$nextTick(() => {
          this.$refs.table.refresh();
        });
      } catch (error) {
        this.$swal({
          title: 'Σφάλμα',
          text: `Αδυναμία ${
            item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
          } προϊόντος`,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },

    handleOpenMassUpdates() {
      this.$bvModal.show('modal-mass-updates');
    },

    async handleMassUpdates() {
      try {
        await request.patch('/products', {
          ids: this.$parent.selectedIds,
          updates: this.updates,
        });

        this.$swal({
          title: 'Επιτυχία',
          text: `${
            this.$parent.selectedIds.length === 1
              ? 'Το προϊόν ενημερώθηκε επιτυχώς'
              : `${this.$parent.selectedIds.length} προϊόντα ενημερώθηκαν επιτυχώς`
          }`,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit('get-counters');

        this.$nextTick(() => {
          this.$parent.$refs.table.refresh();
        });
      } catch (error) {
        console.log('error', error);
        this.$swal({
          title: 'Σφάλμα',
          text: `${
            this.$parent.selectedIds.length === 1
              ? 'Το προϊόν δεν ενημερώθηκε'
              : 'Τα προϊόντα δεν ενημερώθηκαν'
          }`,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.$bvModal.hide('modal-mass-updates');
        this.handleReset();
      }
    },
  },
};
