import request from '@/utils/request';

/* eslint-disable */
const actions = {
  async loginUser({ commit }, { user }) {
    try {
      const { data } = await request.post('/login', user);

      await commit('loginUser', {
        token: data.token,
        user: data.user,
      });
    } catch (err) {
      throw err;
    }
  },

  async logoutUser({ commit }) {
    try {
      await request.post('/logout');

      await commit('logoutUser');
    } catch (err) {
      throw err;
    }
  },

  async getUser({ commit }) {
    try {
      const { data } = await request.get('/user');

      await commit('setUser', {
        user: data.user,
      });
    } catch (err) {
      throw err;
    }
  },
};

export default actions;
