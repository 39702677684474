<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <div>
          <BButton
            variant="primary"
            :to="{ name: 'filterGroup.filters.add' }"
            size="sm"
            class="mr-2"
          >
            <BIcon icon="plus-circle" class="mr-2" /> Προσθήκη Φίλτρου
          </BButton>
          <BDropdown
            size="sm"
            right
            data-test="brand-actions"
            variant="primary"
          >
            <template v-slot:button-content class="btn-primary">
              Ενέργειες
              <BIcon icon="chevron-down" class="font-size-sm ml-1" />
            </template>

            <BDropdownDivider />

            <BDropdownItemButton class="delete-btn" @click="handleDelete">
              Διαγραφή Ομάδας Φίλτρων
            </BDropdownItemButton>
          </BDropdown>
        </div>
      </template>

      <div class="d-flex align-items-start">
        <BCard no-body class="main-card no-border-top-left flex-grow-1 mr-4">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <BForm
              @submit.prevent="handleSubmit(handleForm)"
              novalidate
              data-test="form"
            >
              <BCardBody>
                <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <BFormGroup label="Ονομασία *">
                    <BFormInput
                      id="title"
                      v-model="form.title"
                      type="text"
                      placeholder="Εισάγετε ονομασία"
                      data-test="title"
                      autofocus
                    />

                    <BFormText
                      text-variant="danger"
                      data-test="title-error"
                      v-if="errors[0]"
                    >
                      Παρακαλώ εισάγετε ονομασία
                    </BFormText>
                  </BFormGroup>
                </ValidationProvider>

                <ValidationProvider name="active" rules="required">
                  <BFormGroup size="md" label="Ενεργό" class="mb-0">
                    <BFormCheckbox v-model="form.active" switch />
                  </BFormGroup>
                </ValidationProvider>
              </BCardBody>

              <BCardFooter>
                <BRow class="justify-content-end">
                  <BCol>
                    <BaseButtonSubmit
                      variant="primary"
                      icon="check-circle"
                      :isSaving="isSaving"
                      title="Αποθήκευση"
                    />

                    <BButton
                      class="ml-3"
                      @click="handleReset"
                      data-test="reset-button"
                    >
                      <BIcon icon="arrow-counterclockwise" />
                      Επαναφορά
                    </BButton>
                  </BCol>
                </BRow>
              </BCardFooter>
            </BForm>
          </ValidationObserver>
        </BCard>

        <div class="w-25">
          <ExistingFilters :items="items" />
        </div>
      </div>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import Layout from './components/FiltersEditLayout';
import FiltersEditMethodsMixin from './mixins/FiltersEditMethodsMixin';
import ExistingFilters from './components/ExistingFilters';

export default {
  components: {
    ExistingFilters,
    Layout,
  },

  mixins: [EditorMixin, FiltersEditMethodsMixin],

  data() {
    return {
      isSaving: false,
      isLoading: false,
      model: {},
      filterGroup: {},
      form: {},
      items: [],
      isLoadingFilters: false,
      breadcrumbItems: [
        {
          text: 'Ομάδες Φίλτρων',
          to: { name: 'filterGroups.all' },
        },
        {
          text: '',
          to: {
            name: 'filterGroups.edit',
            params: { id: this.$route.params.id },
          },
        },
        {
          text: 'Φίλτρα',
          to: {
            name: 'filterGroup.filters.all',
            params: { id: this.$route.params.id },
          },
        },
        {
          text: 'Επεξεργασία Φίλτρου',
          active: true,
        },
      ],
    };
  },

  watch: {
    $route() {
      window.location.reload(true);
    },
  },

  async created() {
    try {
      this.isLoading = true;

      // Use Promise.all to execute both requests concurrently
      const [
        filterGroupResponse,
        filterResponse,
        filtersResponse,
      ] = await Promise.all([
        request.get(`/filterGroups/${this.$route.params.id}`),
        request.get(`/filters/${this.$route.params.filter}`),
        request.get(`/filterGroups/${this.$route.params.id}/filters`, {
          params: { paginated: false },
        }),
      ]);

      // Destructure data from each response
      const { data: filterGroupData } = filterGroupResponse;
      const { data: filtersData } = filtersResponse;
      const { data: filterData } = filterResponse;

      // Assign data to respective properties
      this.filterGroup = filterGroupData.filterGroup;
      this.model = filterData.filter;
      this.items = filtersData.filters;

      this.breadcrumbItems[1].text = filterGroupData.filterGroup.title;
      this.breadcrumbItems[1].to.params.id = filterGroupData.filterGroup.id;
      this.breadcrumbItems[2].to.params.id = filterGroupData.filterGroup.id;

      this.initForm();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το φίλτρο',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    initForm() {
      this.form = {
        ...pick(this.model, ['title', 'active']),
      };
    },

    async handleForm() {
      try {
        this.isSaving = true;

        await request.put(`/filters/${this.$route.params.filter}`, {
          ...this.form,
          filter_group_id: this.filterGroup.id,
        });

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του φιλτρου ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit('get-counters');
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του φίλτρου δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.initForm();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
./mixins/FiltersEditMethodsMixin
