
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEmpty, debounce } from 'lodash';
import request from '@/utils/request';
import { CartItems, Product, Loading } from '@/types';

@Component
export default class DynamicSelectMenuProducts extends Vue {
  @Prop({ type: Object, required: false })
  readonly selectedProducts!: CartItems;
  @Prop({ type: Boolean, required: false, default: false })
  readonly clearable!: boolean;
  @Prop({ type: String, required: false, default: 'title' })
  readonly label!: string;
  @Prop({ type: String, required: false, default: 'Αναζήτηση...' })
  readonly placeholder!: string;
  @Prop({ type: Boolean, required: false, default: false })
  readonly required!: boolean;
  @Prop({ type: Boolean, required: false, default: true })
  readonly searchable!: boolean;

  selected: Product | null = null;
  options: Product[] = [];

  handleSearch = debounce(this.onSearch, 500);

  async onSearch(search: string, loading: Loading) {
    if (search) {
      try {
        loading(true);

        const { data } = await request.get('/products', {
          params: {
            paginated: false,
            filter: search,
            active: true,
          },
        });

        this.options = data.products;
      } catch (err) {
        // @ts-ignore
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεν μπόρεσαν να βρεθούν αποτελέσματα',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        loading(false);
      }
    }
  }

  handleInput(value: string) {
    this.selected = null;
    this.options = [];

    if (!isEmpty(value)) {
      this.$emit('select-product', value);
    }
  }

  handleReset() {
    this.selected = null;
    this.options = [];
  }
}
