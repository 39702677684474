
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Product } from '@/types';

@Component
export default class DynamicSelectMenuProducts extends Vue {
  @Prop({ type: Object, required: false })
  readonly item!: Product;

  handleClick() {
    this.$emit('tagDeleted', this.item.id);
  }
}
