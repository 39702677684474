import request from '@/utils/request';

export default {
  methods: {
    async handleToggleActive(item) {
      try {
        await request.patch(`/checkout-methods/${item.id}/active`);

        this.$swal({
          title: 'Επιτυχία',
          text: `Ο τρόπος πληρωμής ${
            item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
          }!`,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$nextTick(() => {
          this.$refs.table.refresh();
        });
      } catch (error) {
        this.$swal({
          title: 'Σφάλμα',
          text: `Αδυναμία ${
            item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
          } του τρόπου πληρωμής`,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
  },
};
