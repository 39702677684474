<template>
  <div>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex border-top">
      <div class="tab-header">
        Νέος Συγγραφέας
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
          <BCardBody>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup size="md" label="Όνομα *">
                <BFormInput
                  id="name"
                  v-model="form.name"
                  type="text"
                  placeholder="Εισάγετε όνομα"
                  data-test="name"
                  autofocus
                />

                <BFormText
                  text-variant="danger"
                  data-test="name-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε το όνομα του συγγραφέα
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider name="email" rules="email" v-slot="{ errors }">
              <BFormGroup size="md" label="Email" class="mb-0">
                <BFormInput
                  id="email"
                  v-model="form.email"
                  type="email"
                  placeholder="Εισάγετε email"
                  data-test="email"
                />

                <BFormText
                  text-variant="danger"
                  data-test="email-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε το email του συγγραφέα
                </BFormText>
              </BFormGroup>
            </ValidationProvider>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </div>
</template>

<script>
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
  },

  data: () => ({
    form: {
      email: '',
      name: '',
    },
    isSaving: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Συγγραφείς',
        to: { name: 'authors' },
      },
      {
        text: 'Προσθήκη',
        active: true,
      },
    ],
  }),

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/authors', this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Ο συγγραφέας προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'authors.list' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης συγγραφέα',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        email: '',
        name: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
