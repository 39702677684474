
import { Component, Vue } from 'vue-property-decorator';
import request from '@/utils/request';
import Draggable from 'vuedraggable';

import { Banner } from '@/types';
import BannerItem from './components/BannerItem/BannerItem.vue';

@Component({
  components: {
    Draggable,
    BannerItem,
  },
})
export default class Banners extends Vue {
  sortBy = 'title';
  sortDesc = false;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Banner', key: 'banner' },
    { label: 'Τίτλος', key: 'title', sortable: true },
    { label: 'Ενεργό', key: 'active' },
    { label: '', key: 'actions', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Βanners',
      active: true,
    },
  ];
  banners = [];
  isLoading = false;

  created() {
    this.bannersProvider();
  }

  async bannersProvider() {
    try {
      this.isLoading = true;

      const { data } = await request.get('/banners?paginated=false');

      this.banners = data.banners;

      return this.banners;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα banners',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleReorder() {
    try {
      const { data } = await request.put('/banners-reorder', {
        banners: this.banners.map((banner: Banner) => {
          return banner.id;
        }),
      });

      if (data.error) {
        throw Error('Server-side validation failed');
      }

      this.bannersProvider();

      this.$swal({
        icon: 'success',
        title: 'Επιτυχία',
        text: 'Τα banners αναδιατάχτηκαν επιτυχώς!!',
      });
    } catch (err) {
      this.$swal({
        icon: 'error',
        title: 'Αποτυχία',
        text: 'To αίτημα απέτυχε',
      });
    }
  }

  async handleChangeActive(selectedItem: Banner) {
    try {
      const isActive = !selectedItem.active;

      await request.patch(`/banners/${selectedItem.id}/active`);

      this.bannersProvider();

      this.$swal({
        icon: 'success',
        title: 'Επιτυχία',
        text: isActive
          ? 'Το banner δημοσιεύτηκε επιτυχώς!'
          : 'Το banner δεν προβάλλεται πλέον',
      });
    } catch (err) {
      console.log(err);
      this.$swal({
        icon: 'error',
        title: 'Αποτυχία',
        text: 'To αίτημα απέτυχε',
      });
    }
  }

  handleDelete(item: Banner) {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε το banner: ${item.title};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/banners/${item.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Το banner ${item.title} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
          });

          this.bannersProvider();
        } catch (error) {
          console.log(error);
          this.$swal({
            title: 'Σφάλμα',
            text: `Το banner ${item.title} δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }
}
