
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CartItem } from '@/types';
import ProductThumbnail from '@/components/ProductThumbnail.vue';
import OrdersProductsTableSpinButton from './OrdersProductsTableSpinButton.vue';
import { BvEvent } from 'bootstrap-vue';
import Vue2Filters from 'vue2-filters';

@Component({
  components: {
    ProductThumbnail,
    OrdersProductsTableSpinButton,
  },

  mixins: [Vue2Filters.mixin],
})
export default class OrdersProductsTable extends Vue {
  @Prop({ type: Array, required: false }) readonly products!: CartItem[];
  @Prop({ type: Function, required: true }) readonly onChangeCount!: (
    e: BvEvent,
    id: string,
  ) => void;
  @Prop({ type: Function, required: true }) readonly onDeleteProduct!: (
    id: string,
  ) => void;
  fields = [
    { label: '#', key: '#', class: 'text-center' },
    { label: 'Φωτό', key: 'photo', class: 'text-center' },
    { label: 'Προιόν', key: 'title' },
    { label: 'Stock', key: 'stock', class: 'text-center' },
    { label: 'Barcodes', key: 'barcodes', class: 'text-center' },
    { label: 'Π.Λ Τιμή (€)', key: 'sell_price', class: 'text-center' },
    { label: 'Έκπτωση (%)', key: 'discount', class: 'text-center' },
    { label: 'Τελική τιμή (€)', key: 'final_price', class: 'text-center' },
    { label: 'Φ.Π.Α. (%)', key: 'tax', class: 'text-center' },
    { label: 'Ποσότητα', key: 'quantity', class: 'text-center' },
    {
      label: 'Σύνολο (€)',
      key: 'total_price',
      class: 'text-center',
    },
    { label: 'Ενέργειες', key: 'actions', class: 'text-center' },
  ];
}
