<template>
  <BOverlay :show="isLoading" no-fade>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex border-top">
      <div class="tab-header">
        Νέος τρόπος αποστολής
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm
          @submit.prevent="handleSubmit(handleForm)"
          novalidate
          data-test="form"
        >
          <BCardBody>
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Τίτλος *">
                <BFormInput
                  id="title"
                  v-model="form.title"
                  type="text"
                  placeholder="Εισάγετε τον τίτλο του τρόπου αποστολής"
                  data-test="title"
                  autofocus
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε τον τίτλο του τρόπου αποστολής
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider title="active" rules="required">
              <BFormGroup label="Ενεργό *">
                <BFormCheckbox v-model="form.active" switch />
              </BFormGroup>
            </ValidationProvider>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </BOverlay>
</template>

<script>
import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';

export default {
  components: {
    BaseButtonSubmit,
  },

  data: () => ({
    isLoading: false,
    form: {
      title: '',
      active: false,
    },
    isSaving: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Τρόποι αποστολής',
        to: { name: 'settings.send-methods' },
      },
      {
        text: 'Προσθήκη',
        active: true,
      },
    ],
  }),

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/send-methods', this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Ο τρόπος αποστολής προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'settings.send-methods.list' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης τρόπου αποστολής',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        title: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
