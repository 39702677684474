
import { Component, Watch, Vue } from 'vue-property-decorator';

import slug from 'slug';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';

@Component({
  mixins: [EditorMixin],
})
export default class ProductsAdd extends Vue {
  eshopURL = process.env.VUE_APP_ESHOP_URL;
  isLoading = false;
  isSaving = false;
  brands = [];
  availabilities = [];
  form = {
    title: '',
    slug: '',
    brand_id: '',
    content: '',
    ingredients: '',
    instructions: '',
    weight: 0,
    volume: 0,
    dimensions: null,
    stock: 0,
    availability_id: null,
    always_available: false,
  };
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Προϊόντα',
      to: { name: 'products' },
    },
    {
      text: 'Προσθήκη',
      active: true,
    },
  ];

  @Watch('form.title')
  handleTitle(newVal: string) {
    this.form = {
      ...this.form,
      slug: slug(newVal),
    };
  }

  @Watch('isAlwaysAvailable')
  handleIsAlwaysAvailable(newVal: boolean) {
    if (newVal) {
      this.form.stock = 0;
    }
  }

  get isAlwaysAvailable() {
    return this.form.always_available;
  }

  async created() {
    this.eshopURL = process.env.VUE_APP_ESHOP_URL;
    try {
      this.isLoading = true;
      const response = await Promise.all([
        request.get('/brands', {
          params: {
            paginated: false,
          },
        }),
        request.get(`/availabilities/`),
      ]);

      this.brands = response[0].data.brands;
      this.availabilities = response[1].data.availabilities;
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;
      await request.post('/products', this.form);

      this.$swal({
        title: 'Επιτυχία',
        text: 'To προϊόν προστέθηκε',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$emit('get-counters');

      await this.$router.push({ name: 'products' });
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Αδυναμία Προσθήκης Προϊόντος',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleReset() {
    this.form = {
      title: '',
      slug: '',
      content: '',
      ingredients: '',
      instructions: '',
      brand_id: '',
      weight: 0,
      volume: 0,
      dimensions: null,
      stock: 0,
      availability_id: null,
      always_available: false,
    };
    this.$nextTick(() => {
      this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
    });
  }
}
