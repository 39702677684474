
import { isEmpty } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

export type Photo = {
  unique_id: string | null;
  path: string;
};

@Component
export default class PhotoPreviewModal extends Vue {
  @Prop({ type: Object, required: true }) readonly photo!: Photo;

  isImageBroken = false;

  handleBrokenImage() {
    this.isImageBroken = true;
  }

  get path() {
    let photo = undefined;

    if (!isEmpty(this.photo)) {
      photo = this.photo;
    }

    if (!photo || !photo.path) {
      this.handleBrokenImage();
      return '';
    }

    return `${process.env.VUE_APP_CDN_URL}?path=${encodeURIComponent(
      this.photo.path,
    )}`;
  }
}
