<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <BButton variant="primary" @click="handleDelete" size="sm">
          <BIcon icon="trash" class="mr-2" /> Διαγραφή
        </BButton>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm @submit.prevent="handleSubmit(handleForm)" novalidate>
            <BCardBody>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Όνομα *">
                  <BFormInput
                    id="name"
                    v-model="form.name"
                    type="text"
                    placeholder="Εισάγετε όνομα"
                    data-test="name"
                    autofocus
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="name-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το όνομα του συγγραφέα
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="email"
                rules="email"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Email" class="mb-0">
                  <BFormInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    placeholder="Εισάγετε email"
                    data-test="email"
                  />

                  <BFormText
                    text-variant="danger"
                    data-test="email-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε το email του συγγραφέα
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import Layout from './components/AuthorsEditLayout';

export default {
  components: {
    BaseButtonSubmit,
    Layout,
  },

  data: () => ({
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Συγγραφείς',
        to: { name: 'authors' },
      },
      {
        text: 'Επεξεργασία',
        active: true,
      },
    ],
  }),

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/authors/${this.$route.params.id}`);

      this.model = data.author;
      this.form = pick(this.model, ['name', 'email']);

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί ο συγγραφέας',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/authors/${this.$route.params.id}`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του συγγραφέα ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του συγγραφέα δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε το συγγραφέα: ${this.model.name};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/authors/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Ο συγγραφέας ${this.model.name} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
            });

            await this.$router.push({ name: 'authors.list' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Ο συγγραφέας ${this.model.name} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
            });
          }
        }
      });
    },

    handleReset() {
      this.form = pick(this.model, ['name', 'email']);
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
